import WebcastPlayer from './webcast_player'
import WebcastVotingTimer from './webcast_voting_timer'

var WebcastVoteResult;
if (typeof WebcastVoteResult === 'undefined') WebcastVoteResult = {};

const playerID = 'si-player'
const webcastPlayer = document.querySelector('#' + playerID)
const webcastResultContainer = document.querySelector('#webcast-result')
const questionButtons = document.querySelectorAll(`[id^="js-btn-question-id-"]`)
const resultContainers = document.querySelectorAll(`[id^="js-ew-voting-result-container-"]`)
const resultChartContainers = document.querySelectorAll(`[id^="js-ew-voting-result-chart-container-"]`)
const votingTimerContainer = document.querySelector('.js-ew-voting-timer-container')

WebcastVoteResult = {
  init: () =>{
    WebcastVoteResult.showCharts()
    WebcastVoteResult.setResolutionButtons()
  },

  showCharts: () => {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    })

    resultChartContainers.forEach(function(elem){
      let chartContainerID = elem.id
      let questionID = chartContainerID.replace('js-ew-voting-result-chart-container-','')
      Highcharts.chart(chartContainerID, {
        data: {
          table: 'js-ew-voting-result-table-result-'+questionID
        },
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          width: 776,
          height: '75%',
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
          }
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '<b>{point.y:,.0f} ({point.percentage:.2f} %)</b>'
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            depth: 25,
            cursor: 'pointer',
            colors: ['#5a95d7', '#f75f5f','#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
            dataLabels: {
              color: "#000000",
              enabled: true,
              distance: '8',
              format: '<b>{point.name}</b>:<br>{point.percentage:.2f} %',
              style: {
                fontSize: '35px'
              }
            }
          }
        },
        credits: {
          enabled: false
        }
      });
    })
  },

  setResolutionButtons: () =>{
    WebcastVoteResult.updateResolutionButtonsPosition()
    questionButtons.forEach(function(button) {
      button.addEventListener('click', function(e) {
        /* add after click style to buttons */
        e.currentTarget.classList.add('active','visited')
        Array.from(document.querySelectorAll('[id^="js-btn-question-id-"]:not(#'+e.currentTarget.id+')')).map(x => x.classList.remove('active'))
        /* show/hide result */
        let resultContainer = e.currentTarget.id.replace('js-btn-question-id-','#js-ew-voting-result-container-')
        $('.vote-result:not('+resultContainer+')').fadeOut('fast')
        $(resultContainer).delay(300).fadeIn()
        setTimeout( function(){
          WebcastVoteResult.updateResolutionButtonsPosition()
        }, 600)
      })
    })
  },

  updateResolutionButtonsPosition: () =>{
    if(window.innerHeight - document.querySelector(".webcast-result-container").clientHeight - document.querySelector(".navi-bottom").clientHeight < 0 ){
      document.querySelector(".navi-bottom").style.position = 'static'
    }else{
      document.querySelector(".navi-bottom").style.position = 'absolute'
    }
  },

  playerMetadataCallback: (webcastMetadata) => {
    if (!webcastMetadata) {
      return
    }
    WebcastVotingTimer.updateLiveEvoting(webcastMetadata)

    if (webcastMetadata.voting_result) {
      WebcastVotingResult.process(webcastMetadata.id, webcastMetadata.voting_result)
    }
  },

  votingStartedCallback: () => {
    document.querySelector('.timer_label').style.display = 'block'
  },

  votingEndedCallback: () => {
    document.querySelector('.timer_label').style.display = 'none'
  }
}

document.addEventListener("DOMContentLoaded", function(){
  if (webcastResultContainer) {
    WebcastVoteResult.init()
  }
  if (webcastResultContainer && webcastPlayer){
    WebcastPlayer.init(WebcastVoteResult.playerMetadataCallback)
  }
  if (webcastResultContainer && votingTimerContainer){
    let options = {
      votingStartedCallback: WebcastVoteResult.votingStartedCallback,
      votingEndedCallback: WebcastVoteResult.votingEndedCallback
    }
    WebcastVotingTimer.init(options)
  }
})