var WebcastVotingResult;
if (typeof WebcastVotingResult === 'undefined') WebcastVotingResult = {};

const votingResultContainer = document.querySelector('#js-ew-voting-result-container')
const videoContainer = document.querySelector('#video-inner-wrapper')

WebcastVotingResult = {
  init: () => {
    if (votingResultContainer) {
      WebcastVotingResult.votingQuestionID = votingResultContainer.dataset.votingquestionid
      WebcastVotingResult.shownResultMetadataID = ''
      WebcastVotingResult.hiddenResultMetadataID = ''

      if (votingResultContainer.style.display != 'none') {
        WebcastVotingResult.showChart()
      }
    }
  },

  process: (metadataID, result) => {
    if (result.end_show_result) {
      if (votingResultContainer.style.display != 'none' &&
        result.voting_question_id == WebcastVotingResult.votingQuestionID &&
        (WebcastVotingResult.hiddenResultMetadataID.length == 0 ||
          parseInt(metadataID) > parseInt(WebcastVotingResult.hiddenResultMetadataID))) {

        WebcastVotingResult.hiddenResultMetadataID = metadataID
        WebcastVotingResult.hideResultContainer()
      }
      return
    }

    if (!result.result) {
      return
    }

    if (votingResultContainer.style.display == 'none' &&
      (WebcastVotingResult.shownResultMetadataID.length == 0 ||
        parseInt(metadataID) > parseInt(WebcastVotingResult.shownResultMetadataID))) {

      WebcastVotingResult.shownResultMetadataID = metadataID
      WebcastVotingResult.votingQuestionID = result.voting_question_id
      WebcastVotingResult.showResult(result.result)
    }
  },

  hideResultContainer() {
    if (votingResultContainer.style.display == 'none') {
      return
    }
    votingResultContainer.style.display = 'none'
    videoContainer.style.display = 'block'
    videoContainer.closest('.video-wrapper').classList.remove('show-result')
  },

  showResult(data) {
    let title = data.resolution_number + '. ' +
      data.voting_topic_name
    let question = data.voting_question_question
    let resolutionResultContainer = votingResultContainer.querySelector('#js-ew-voting-result-resolution-result')

    votingResultContainer.style.display = 'block'
    videoContainer.style.display = 'none'
    videoContainer.closest('.video-wrapper').classList.add('show-result')
    votingResultContainer.querySelector('#js-ew-voting-result-title').innerHTML = title
    votingResultContainer.querySelector('#js-ew-voting-result-question').innerHTML = question
    resolutionResultContainer.innerHTML = data.resolution_result

    if (data.resolution_result == 'TIED') {
      resolutionResultContainer.classList.remove('d-none')
      resolutionResultContainer.classList.remove('badge-danger')
      resolutionResultContainer.classList.remove('badge-success')
      resolutionResultContainer.classList.add('badge-warning')
    } else if (data.resolution_result == 'PASSED') {
      resolutionResultContainer.classList.remove('d-none')
      resolutionResultContainer.classList.remove('badge-danger')
      resolutionResultContainer.classList.remove('badge-warning')
      resolutionResultContainer.classList.add('badge-success')
    } else if (data.resolution_result == 'NOT PASSED') {
      resolutionResultContainer.classList.remove('d-none')
      resolutionResultContainer.classList.remove('badge-warning')
      resolutionResultContainer.classList.remove('badge-success')
      resolutionResultContainer.classList.add('badge-danger')
    } else {
      resolutionResultContainer.classList.remove('badge-warning')
      resolutionResultContainer.classList.remove('badge-success')
      resolutionResultContainer.classList.remove('badge-danger')
      resolutionResultContainer.classList.add('d-none')
    }

    //update result in hidden table for chart data
    votingResultContainer.querySelector('#js-ew-voting-result-for-value').innerHTML = data.result_breakdown_by_shares.for[0].replace(/,/g, '')
    votingResultContainer.querySelector('#js-ew-voting-result-against-value').innerHTML = data.result_breakdown_by_shares.against[0].replace(/,/g, '')

    //update result in view table
    votingResultContainer.querySelector('#js-ew-view-voting-result-for-value').innerHTML = data.result_breakdown_by_shares.for[0]
    votingResultContainer.querySelector('#js-ew-view-voting-result-against-value').innerHTML = data.result_breakdown_by_shares.against[0]
    votingResultContainer.querySelector('#js-ew-view-voting-result-abstain-value').innerHTML = data.result_breakdown_by_shares.abstain[0]
    WebcastVotingResult.showChart()
  },

  showChart: () => {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    })
    Highcharts.chart('js-ew-voting-result-chart-container', {
      data: {
        table: 'js-ew-voting-result-table-result'
      },
      chart: {
        backgroundColor: null,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: '75%',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
        }
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.0f} ({point.percentage:.2f} %)</b>'
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          depth: 35,
          cursor: 'pointer',
          colors: ['#32bf03', '#f13636','#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
          dataLabels: {
            color: "#000000",
            enabled: true,
            distance: '8',
            format: '<b>{point.name}</b>:<br>{point.percentage:.2f} %',
          }
        }
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 300
          },
          chartOptions: {
            chart:{
              backgroundColor: null,
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie',
              options3d: {
                enabled: false
              }
            },
            plotOptions: {
              pie: {
                size: '100%',
                dataLabels: {
                  distance: '3',
                  style: {
                    textOverflow: 'clip',
                    fontWeight: 'normal',
                    fontSize: '.45rem'
                  }
                }
              }
            },
          }
        }]
      },
      credits: {
        enabled: false
      }
    });

    const resizeObserver = new ResizeObserver(function(){
      WebcastVotingResult.reflowChart()
    })
    resizeObserver.observe(votingResultContainer)
  },

  reflowChart: () => {
    let chart = Highcharts.charts.findLast(c => c.renderTo.id == 'js-ew-voting-result-chart-container')
    if (chart){
      chart.reflow()
    }
  }
}

export default WebcastVotingResult;