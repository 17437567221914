document.addEventListener("turbolinks:load", () => {
  if($('#forceAbstainModal')) {
    $('#forceAbstainModal').on('show.bs.modal', function (e) {
      if (e.relatedTarget) {
        let votingAnswerID = e.relatedTarget.id.replace(/js-vs-force-abstain-resolution-/g, '')

        if (votingAnswerID) {
          document.querySelector('#js-vs-force-abstain-modal-resolution-label').innerHTML = e.relatedTarget.dataset.resolution
          document.querySelector('#force_abstain_voter_from_resolution_voting_answer_id').value = votingAnswerID
        }
      }
    })
  }
})
