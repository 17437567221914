require("packs/jquery.magnific-popup")
require("packs/smk-accordion")
// require("packs/pigeonhole")
// require("packs/webcast_QA_01")
// require("packs/webcast_QA_02")

import WebcastPlayer from './webcast_player'
import WebcastVotingTimer from './webcast_voting_timer'
import WebcastVotingResult from './webcast_voting_result'

var WebcastLayout;
if (typeof WebcastLayout === 'undefined') WebcastLayout = {};


const playerID = 'si-player'
const bodyContainer = document.querySelector('body.webcast')
const webcastPlayer = document.querySelector('#' + playerID)
const votingTimerContainer = document.querySelector('.js-ew-voting-timer-container')
const votingResultContainer = document.querySelector('#js-ew-voting-result-container')
const noVideoMode = document.querySelector('.webcast-no-video')
const cannotAskQuestion = document.querySelector('.cannot_ask_question')

WebcastLayout = {
  // place other functions here
  init: () => {
    WebcastLayout.votingFormButtons = document.querySelectorAll('.show-votingform')


    WebcastLayout.accordionSetup()
    WebcastLayout.iframeSetup()
    WebcastLayout.consentFormSetup()

    WebcastLayout.adjustFooterPosition()
    $(window).on("load resize orientationchange", function(){
      WebcastLayout.adjustFooterPosition()
      WebcastLayout.adjustProxyFormFluid()
    })
    WebcastLayout.pollSetup()
    if(bodyContainer.classList.contains('has_pigeonhole')){
      WebcastLayout.showQnA()
    }
    WebcastLayout.setupVotingForm()
    if(bodyContainer.querySelector('#js-ew-voting-form-hide-button')) {
      bodyContainer.querySelector('#js-ew-voting-form-hide-button').addEventListener('click', function(e) {
        e.preventDefault();
        WebcastLayout.hideVotingForm()
      })
    }
  },

  accordionSetup: () => {
    if($("body.webcast").length > 0 || $("body.preview_webcast").length > 0) {
      $('.open-popup-link').magnificPopup({
        type:'inline',
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
      });

      $(".accordion_bod").smk_Accordion({
        closeAble: true, //boolean
      });
    }
  },

  iframeSetup: () => {
    if ($("iframe").length > 0) {
      $("iframe").on('load', function() {
        if ($('iframe').attr('src') && $('iframe').attr('src').includes("/rails/mailers")) {
          $(this).height( $(this).contents().find("body").find('iframe').contents().find("body").height() + 150 );
        }
      });
    }
  },

  consentFormSetup: () => {
    if (window.location.pathname.indexOf("/webcast") >= 0) {

      if ($("#event-attr").data('request_consent') == true && $("#event-attr").data('consent') != true ){
        setTimeout(function () {
          show_consent_form()
        }, 5000);
      }
      function show_consent_form() {
        var event_slug = window.location.pathname.split('/')[1];
        var consent_form_value = event_slug + "=" + "shown_consent_form"
        if ( document.cookie.indexOf(event_slug) == -1 && document.cookie.indexOf(consent_form_value) == -1 ){
          document.getElementById("consent").addEventListener("click", consent_form);
          $("#consentModal").modal('show');
          createCookie(event_slug, "shown_consent_form", 1);
        }
      }
      function consent_form(){
        var event_slug = window.location.pathname.split('/')[1];
        $.ajax({
          url: '/downloads/consent_request',
          type: "POST",
          data: { event_slug, status: true },
          success: function () { $("#consentModal").modal('hide'); }
        });
      }

      function createCookie(name, value, days) {
        var date, expires;
        if (days) {
          date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          date.setHours(0);
          date.setMinutes(0);
          expires = "; expires=" + date.toUTCString();
        } else {
          expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/";
      }

    }
  },

  adjustFooterPosition: () => {
    let fullContentHeight = $("#user-top-header").innerHeight() + $("#js-webcast-container").innerHeight() + $("#webcast-footer").innerHeight();
    if( fullContentHeight >= window.innerHeight ){
      $("#webcast-footer").css({"position" : "static", "margin-top" : "20px" });
    }else{
      $("#webcast-footer").css({"position" : "fixed", "margin-top" : "0" });
    }
  },

  setupVotingForm: () => {
    if(!bodyContainer.querySelector('#js-ew-voting-form-button') && !bodyContainer.querySelector('#js-ew-voting-form-button-mobile-landscape')) {
      return
    }

    bodyContainer.querySelector('#js-ew-voting-form-button').addEventListener('click', function(e) {
      e.preventDefault();
      WebcastLayout.showVotingForm()
      WebcastLayout.adjustProxyFormFluid()
    })

    bodyContainer.querySelector('#js-ew-voting-form-button-mobile-landscape').addEventListener('click', function(e) {
      e.preventDefault();
      WebcastLayout.showVotingForm()
      WebcastLayout.adjustProxyFormFluid()
    })

    const queryString = window.location.search
    if(!!bodyContainer.querySelector('#js-ew-voting-form-button') && !bodyContainer.querySelector('#js-ew-voting-form-button').classList.contains('disabled') && queryString.match(/voter_id=/)) {
      bodyContainer.querySelector('#js-ew-voting-form-button').click()
      bodyContainer.querySelector('#js-ew-voting-form-button-mobile-landscape').click()
    }
  },

  hideVotingForm: () => {
    bodyContainer.classList.remove('show_voting_form')
    bodyContainer.classList.remove('show_pigeonhole')
    bodyContainer.querySelector('.video-wrapper-outer').classList.remove('col-sm-5')
    bodyContainer.querySelector('.video-wrapper-outer').classList.remove('col-lg-5')
    bodyContainer.querySelector('.video-wrapper-outer').classList.remove('col-xl-3')
    bodyContainer.querySelector('.video-wrapper-outer').classList.remove('col-xl-8')
    bodyContainer.querySelector('.video-wrapper-outer').classList.add('col-lg-10')
    bodyContainer.querySelector('.video-wrapper-outer').classList.add('offset-lg-1')
    bodyContainer.querySelector(".video-wrapper-outer").classList.add('col-xl-12')
    if( $(".voting-container").parent().is(".voting-container-wrap")){
      $(".voting-container").unwrap();
    }
    if(bodyContainer.querySelector('.pigeonhole-wrapper')){
      bodyContainer.querySelector(".pigeonhole-wrapper").classList.add('d-none')
    }

    //hide voting form container
    $("#votingForm_body").innerHeight(0)
    setTimeout(function(){
      WebcastLayout.adjustFooterPosition()
    }, 350)

  },

  finalizeVotingForm:() =>{
    // TODO: optimize this code to set the voting form to 'completed' step without reloading the page
    // Previous issue encountered: When voting timer ended and voter didn't complete in time, the form is not updated to "Completed" step. Instead, we need manually refresh page
    $("#votingForm_body").addClass('no-click-all')
    $("#votingForm_body").after("<div class='mt-3 text-center col-12 order-last'><span class='d-inline-block mr-3 '><i class='fa-lg fas fa-spinner fa-spin'></i></span><span class='d-inline-block'>Loading...</span></div>")

    let msBeforeRefresh = Math.floor(Math.random() * (12000 - 10000 + 200) + 10000)
    setTimeout(function () {
      location.reload()
    }, msBeforeRefresh)
  },

  activateVotingForm:() =>{
    // TODO: optimize this code by resume the voting form from step 'completed' to their 'current' step without reloading the page.
    // Previous issue encountered: If voter didn't complete their vote. And when voting timer start/restart, the form remain still in 'Completed' step and cannot vote. Instead, we need manually refresh page

    // Check if completed, do not refresh
    if (document.querySelector('#live_voting_form_status').innerHTML == 'VERIFIED') {
      return
    }
    let msBeforeRefresh = Math.floor(Math.random() * (2000 - 1000 + 100) + 1000)
    setTimeout(function () {
      location.reload()
    }, msBeforeRefresh)
  },

  showVotingForm: () => {
    bodyContainer.classList.add('show_voting_form')
    bodyContainer.classList.remove('show_pigeonhole')
    if(bodyContainer.querySelector('.pigeonhole-wrapper')){
      bodyContainer.querySelector('.pigeonhole-wrapper').classList.add('d-none')
    }
    bodyContainer.querySelector('.video-wrapper-outer').classList.remove('col-xl-12')
    bodyContainer.querySelector('.video-wrapper-outer').classList.remove('col-xl-8')
    bodyContainer.querySelector('.video-wrapper-outer').classList.remove('col-lg-10')
    bodyContainer.querySelector('.video-wrapper-outer').classList.remove('offset-lg-1')
    bodyContainer.querySelector('.video-wrapper-outer').classList.add('col-sm-5')
    bodyContainer.querySelector('.video-wrapper-outer').classList.add('col-lg-5')
    bodyContainer.querySelector('.video-wrapper-outer').classList.add('col-xl-3')
    if( $(".voting-container").parent().is(".voting-container-wrap")){
      $(".voting-container").unwrap();
    }

  },

  playerMetadataCallback: (webcastMetadata) => {
    if (!webcastMetadata) {
      return
    }

    WebcastVotingTimer.updateLiveEvoting(webcastMetadata)

    if (webcastMetadata.voting_result) {
      WebcastVotingResult.process(webcastMetadata.id, webcastMetadata.voting_result)
    }
  },

  votingStartedCallback: () => {
    if (!WebcastLayout.votingFormButtons) {
      return
    }

    let votingForm = document.querySelector('#voting-form')
    if (votingForm){
      if (!votingForm.querySelector(".form_step3").classList.contains('hidden_left') && !votingForm.querySelector(".form_step3").classList.contains('hidden_right') ) {
        WebcastLayout.activateVotingForm()
      }
    }

    WebcastLayout.votingFormButtons.forEach(function(btn){
      if (btn.classList.contains('disabled')) {
        btn.classList.remove('disabled')
      }

      if (btn.querySelector('.icon-button-arrow')){
        btn.querySelector('.icon-button-arrow').classList.remove('d-none')
      }

      let vfBtnMsg = btn.querySelector('.js-ew-voting-form-button-msg')

      if (btn.classList.contains('verified')) {
        vfBtnMsg.innerHTML = 'Your votes have already been submitted.'
      } else {
        if (btn.classList.contains('btn-warning')) {
          btn.classList.add('btn-danger')
          btn.classList.remove('btn-warning')
        }
        vfBtnMsg.innerHTML = "You have not submitted your votes yet.<br/>Voting will be ending soon!"

        let miniTimer = btn.querySelector('.js-ew-voting-timer-container-min')
        if (miniTimer){
          miniTimer.closest('.js-timer-min-container-outer').classList.remove('d-none')
          miniTimer.closest('.js-timer-min-container-outer').nextElementSibling.classList.add('d-none')
        }
      }
    })
  },

  votingEndedCallback: () => {
    if (!WebcastLayout.votingFormButtons) {
      return
    }

    document.querySelector('#alertModalLabel').innerHTML = "Voting is now closed."
    $("#alertModal").modal('show')
    WebcastLayout.hideVotingForm()

    if(noVideoMode){
      WebcastLayout.finalizeVotingForm()
    }

    WebcastLayout.votingFormButtons.forEach(function(btn){
      if (!btn.classList.contains('disabled')) {
        btn.classList.add('disabled')
      }
      if (btn.querySelector('.icon-button-arrow')){
        btn.querySelector('.icon-button-arrow').classList.add('d-none')
      }
      let vfBtnMsg = btn.querySelector('.js-ew-voting-form-button-msg')

      if (btn.classList.contains('verified')) {
        vfBtnMsg.innerHTML = `Your votes have already been submitted.<br/>Voting is now closed.`
      } else {
        if (btn.classList.contains('btn-warning')) {
          btn.classList.add('btn-danger')
          btn.classList.remove('btn-warning')
        }
        vfBtnMsg.innerHTML = `Your votes were not submitted on time.<br/>Voting is now closed.`
      }
    })
  },

  showQnA: () => {
    bodyContainer.querySelectorAll('.btn-qna').forEach(button =>
      button.addEventListener('click', function(){
        if(cannotAskQuestion){
          bodyContainer.querySelector('#qandaModal .modal-dialog').classList.remove("modal-lg")
          bodyContainer.querySelector('#qandaModal .modal-dialog').classList.add("modal-sm", "modal-dialog-centered")
          $('#qandaModal').modal('show');
        }else{
          if(window.innerWidth < 1200){
            $('#qandaModal').modal('show');
          }else{
            bodyContainer.classList.remove('show_voting_form')
            if(bodyContainer.classList.contains('show_pigeonhole')){
              bodyContainer.querySelector(".pigeonhole-wrapper").classList.add('d-none')
              bodyContainer.classList.remove('show_pigeonhole')
              bodyContainer.querySelector(".video-wrapper-outer").classList.add('col-xl-12')
              bodyContainer.querySelector(".video-wrapper-outer").classList.remove('col-xl-8')
            }else{
              setTimeout(function(){
                bodyContainer.classList.add('show_pigeonhole')
              }, 350)
              bodyContainer.querySelector(".pigeonhole-wrapper").classList.remove('d-none')
              bodyContainer.querySelector(".video-wrapper-outer").classList.remove('col-xl-12')
              bodyContainer.querySelector(".video-wrapper-outer").classList.add('col-xl-8')
            }
            setTimeout(function(){
              WebcastLayout.adjustFooterPosition()
            }, 350)
          }
        }
      })
    )
  },

  pollSetup: () => {
    if($("#poll-form").length > 0){
      var load = 0;

      $("#poll-form").on("load", function() {
        /*Execute on every reload on iFrame*/
        load++;
        if(load > 1){
          /*Second reload is a submit*/
          $("#pollModal .modal-dialog").addClass("dialog-centered");
          $("#pollModal .modal-body").html("<div class='text-center poll-submit'></div>")
          $("#pollModal div.poll-submit").append("<img src='/company_custom/images/logo.png' class='img-fluid poll-co-logo'>")
          $("#pollModal div.poll-submit").append("<p class='poll-submit-message'>Thank you for submitting your poll.</p>")
          $("#pollModal div.poll-submit").append("<a href='#' class='btn btn-secondary mt-3' data-dismiss='modal'>Close</a>")
        }
      })
    }
  },

  adjustProxyFormFluid: () => {
    if (!$(".show_voting_form").length && !noVideoMode){
      $("#votingForm_body").innerHeight(0)
      return
    }
    setTimeout(function(){
      let viewportTopBtmSpacing = 32 /* margin-top and padding-bottom of screen after exclude user-nav height */
      let height = window.innerHeight - $("#user-top-header").innerHeight() - viewportTopBtmSpacing
      if (window.matchMedia("(orientation: landscape) and (min-device-width: 320px) and (max-device-width: 926px) and (-webkit-min-device-pixel-ratio: 2)").matches){
        //for mobile landscape only
        if (!$(".voting-container-wrap").length){
          $(".voting-container").wrapAll('<div class="voting-container-wrap"></div>')
        }
        height = height - $("#votingForm_header").innerHeight()
        $("#votingForm_body").innerHeight(height)
        WebcastLayout.updateVotingFormQuestionHeight()
      }else{
        //for all view other than mobile portrait
        if ($('.voting-container-wrap').length){
          $(".voting-container").unwrap()
          $("#votingForm_body").removeAttr('style')
        }
        setTimeout(function(){
          //adjust voting form container height to allow inner scroll
          if(window.matchMedia("(orientation: portrait) and (max-width: 575px)").matches){
            height = height - $(".video-wrapper-outer").innerHeight() - $("#votingForm_header").innerHeight()
          }else{
            if( $(".video-wrapper-outer").innerHeight() >= $("#votingForm_header").innerHeight() ){
              height = height - $(".video-wrapper-outer").innerHeight()
            }else{
              height = height - $("#votingForm_header").innerHeight()
            }
          }
          height = (height <= 0 ? 200 : height)
          $("#votingForm_body").innerHeight(height)
          WebcastLayout.adjustFooterPosition()
          WebcastLayout.updateVotingFormQuestionHeight()
        }, 350)
      }
    }, 100)
  },

  updateVotingFormQuestionHeight: () =>{
    let liveVotingFormBodySpacing = 20
    let height = $("#votingForm_body").innerHeight() - $("#js-vf-header-mobile").outerHeight(true) - liveVotingFormBodySpacing
    let liveResolutionWrappers = $('.vf-resolution-wrapper')
    liveResolutionWrappers.each(function(){
      if (window.matchMedia("(orientation: landscape) and (min-device-width: 320px) and (max-device-width: 926px) and (-webkit-min-device-pixel-ratio: 2)").matches){
        $(this).find(".vf-resolution-question-wrapper").css({'max-height': height + 'px'})
      }else if (window.matchMedia("(orientation: portrait) and (max-width: 575px)").matches){
        let buttons = $(this).find(".vf-resolution-buttons-wrapper")
        $(this).find(".vf-resolution-question-wrapper").css({'max-height': (height - buttons.outerHeight(true)) + 'px' })
      }else {
        $(this).find(".vf-resolution-question-wrapper").css({'max-height': 'auto'})
      }
    })
  },
}

document.addEventListener("turbolinks:load", () => {
  if(bodyContainer) {
    WebcastLayout.init()
  }

  if(bodyContainer && webcastPlayer) {
    WebcastPlayer.init(WebcastLayout.playerMetadataCallback)
  }

  if(bodyContainer && votingTimerContainer){
    let options = {
      votingStartedCallback: WebcastLayout.votingStartedCallback,
      votingEndedCallback: WebcastLayout.votingEndedCallback
    }
    WebcastVotingTimer.init(options)
  }

  if(bodyContainer && votingResultContainer){
    WebcastVotingResult.init()
  }
})