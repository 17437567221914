function startQuizTimer(duration, display, form) {
  var timer = duration, minutes, seconds;
  var timerInterval = setInterval(function () {
    minutes = parseInt(timer / 60, 10)
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    display.text(minutes + ":" + seconds);

    if (--timer < 0) {
      $('#quiz_modal').modal('show');
      $('#quiz_modal').on('hidden.bs.modal', function (e) {
        form.submit();
      })
      clearInterval(timerInterval);
    }
  }, 1000);

}

document.addEventListener("turbolinks:load", () => {
  $(document).ready(function(){
    var timer = $("#time_expired").text();
    display = $('#time_left');
    form = $("#quiz-form");
    if (timer){
      startQuizTimer(timer, display, form);
    }  
  }); 
})