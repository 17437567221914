document.addEventListener("turbolinks:load", () => {
  //get all selected checkbox registrants id
  function sumRegistrants() {
    var registrants = [];
    $('[name="registrant_id"]').each(function () {
      if ($(this).is(":checked")) {
        registrants.push($(this).val());
      }
    });
    return registrants;
  }

  //disable/active batch button
  function batchBtnState() {
    var $checked = $('input[name="registrant_id"]:checked');
    var $countChecked = $('input[name="registrant_id"]:checked').length;
    if ($countChecked > 0) {
      var webcastBtn = true;
      $checked.each(function (e) {
        var status = $(this).data('status');
        if (status != 'rejected' && status != 'approved') {
          $("#batch_send_webcast").addClass("disabled");
          $("#batch_send_webcast").attr("disabled", "disabled");
          $("#batch_send_qr_code_ticket").addClass("disabled");
          $("#batch_send_qr_code_ticket").attr("disabled", "disabled");
          $("#batch_send_qr_code_for_login").addClass("disabled");
          $("#batch_send_qr_code_for_login").attr("disabled", "disabled");
          webcastBtn = false;
          return false;
        }
      });
      if (webcastBtn == true) {
        $("#batch_send_webcast").removeClass("disabled");
        $('#batch_send_webcast').removeAttr("disabled");
        $("#batch_send_qr_code_ticket").removeClass("disabled");
        $('#batch_send_qr_code_ticket').removeAttr("disabled");
        $("#batch_send_qr_code_for_login").removeClass("disabled");
        $('#batch_send_qr_code_for_login').removeAttr("disabled");
      }
      $("#batch_approve").removeClass("disabled");
      $("#batch_reject").removeClass("disabled");
    } else {
      $(".batch_btn").addClass("disabled");
    }
  }

  // batch button - approve
  $("#batch_approve").click(function (e) {
    var event_id = $(this).data('event-id');
    var registrants = sumRegistrants();
    if (registrants.length > 0) {
      if (confirm("Are you sure you want to approve the selected " + registrants.length + " registrant(s)? ")) {
        $.ajax({
          url: '/events/' + event_id + '/registrations/approve_registrations',
          type: "POST",
          data: {registrants},
          success: function (msg) {}
        });
      }
    }
  });

  // batch button - reject
  $("#batch_reject").click(function (e) {
    var event_id = $(this).data('event-id');
    var registrants = sumRegistrants();
    if (registrants.length > 0) {
      if (confirm("Are you sure you want to reject the selected " + registrants.length + " registrant(s)? ")) {
        $.ajax({
          url: '/events/' + event_id + '/registrations/reject_registrations',
          type: "POST",
          data: {registrants},
          success: function (msg) {}
        });
      }
    }
  });

  // batch button - send webcast link
  $("#batch_send_webcast").click(function (e) {
    var event_id = $(this).data('event-id');
    var registration_ids = sumRegistrants();
    if (registration_ids.length > 0) {
      if (confirm("If you proceed, this will send the webcast link to the selected " + registration_ids.length + " registrant(s) by email. Typically this is sent 24 hours before the start of the event. Please confirm if you wish to proceed.")) {
        $.ajax({
          url: '/events/' + event_id + '/send_webcast_link',
          type: "POST",
          data: {registration_ids},
          success: function (msg) {}
        });
      }
    }
  });

  // batch button - send QR Code
  $("#batch_send_qr_code_ticket").on('click', function (e) {
    var event_id = $(this).data('event-id');
    var registration_ids = sumRegistrants();
    if (registration_ids.length > 0) {
      if (confirm("If you proceed, will send the QR code to the selected " + registration_ids.length + " registrant(s) by email. Please confirm if you wish to proceed.")) {
        $.ajax({
          url: '/events/' + event_id + '/registrations/email_qr_code_as_ticket',
          type: "POST",
          data: {registration_ids},
          success: function (msg) {}
        });
      }
    }
  });
  // batch button - send QR Code
  $("#batch_send_qr_code_for_login").on('click', function (e) {
    var event_id = $(this).data('event-id');
    var registration_ids = sumRegistrants();
    if (registration_ids.length > 0) {
      if (confirm("If you proceed, will send the QR code to the selected " + registration_ids.length + " registrant(s) by email. Please confirm if you wish to proceed.")) {
        $.ajax({
          url: '/events/' + event_id + '/registrations/email_qr_code_for_login',
          type: "POST",
          data: {registration_ids},
          success: function (msg) {}
        });
      }
    }
  });

  //select all checkboxes
  function checkBoxListner() {
    var $inputAll = $('input[name="registrant_id"]').length;
    if ($(this).attr("name") == "select_all") {
      if ($(this).is(":checked")) {
        $('input[name="registrant_id"]').each(function () {
          this.checked = true;
        });
      } else {
        $('input[name="registrant_id"]').each(function () {
          this.checked = false;
        });
      }
    } else if ($(this).attr("name") == "registrant_id") {
      var $countCheck = $('input[name="registrant_id"]:checked').length;
      if ($countCheck == $inputAll) {
        $('input[name="select_all"]').each(function () {
          this.checked = true;
        });
        $('input[name="select_all"]').prop("checked", true);
      } else {
        $('input[name="select_all"]').each(function () {
          this.checked = false;
        });
        $('input[name="select_all"]').prop("checked", false);
      }
    }
    batchBtnState();
  };

  if ($('#registration_list_table').length > 0){
    var registration_list_table = $('#registration_list_table').DataTable({
      "responsive": true,
      "autoWidth": false,
      "processing": true,
      "serverSide": true,
      "ajax": {
        "url": $('#registration_list_table').data('source')
      },
      "pagingType": "full_numbers",
      "columns": [
        {"data": "check_box", orderable: false },
        {"data": "no" },
        {"data": "ref_no", visible: document.getElementById("registration_list_table").querySelector('th.js-ref-no').style.display != 'none' },
        {"data": "full_name"},
        {"data": "identification_number"},
        {"data": "email"},
        {"data": "mobile"},
        {"data": "registration_type"},
        {"data": "status"},
        {"data": "attendance" },
        {"data": "email_group"},
        {"data": "qr_code_for_login", visible: document.getElementById("registration_list_table").querySelector('th.js-qr-for-login').style.display != 'none' },
        {"data": "qr_code_as_ticket", visible: document.getElementById("registration_list_table").querySelector('th.js-qr-as-ticket').style.display != 'none' },
        {"data": "registration_id", visible: false},
      ],
      "columnDefs": [ {
        "searchable": false,
        "orderable": false,
        "targets": 1
      } ],
      order: [[ 2, 'asc' ]],
    });

    // add listner on table body
    registration_list_table.on('draw', function () {
      this.addEventListener("click", checkBoxListner);
    });

    // add listner to table head
    $("#registration_list_table thead input").on("click", checkBoxListner )

    // after table draw do ...
    registration_list_table.on( 'draw.dt', function () {
      // table index
      var info = registration_list_table.page.info();
      registration_list_table.column(1, { search: 'applied', order: 'applied', page: 'applied' }).nodes().each(function (cell, i) {
        cell.children[0].innerHTML = i + 1 + info.start;
      });
      // get reg_ids for export and build the link
      var registration_ids = $.map( registration_list_table.data(), function( val ) { return val.registration_id })
      $("#export_filter").attr("href", $("#export_filter").data('origin') + "?reg_ids=" + registration_ids)
    } )
  }
})