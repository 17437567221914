document.addEventListener("turbolinks:load", () => {
  if (document.querySelector('body.scan_qr_code_ticket')){
    const search = document.querySelector('#address_bar');
    search.focus();
    search.addEventListener('keypress', (e) => {
      if (e.code === 'Enter' && search.value && search.value.length > 0) {
        window.location = search.value;
      }
    });
  }
});