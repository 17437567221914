import SignaturePad from 'signature_pad'

var SignatureInput
if (typeof SignatureInput === 'undefined') SignatureInput = {}

const form = document.querySelector('#voting-form')
const canvas = document.querySelector('canvas')
const signatureInput = document.querySelector('.signature-pad-input')
const signatureTime = document.querySelector('.signature-pad-time')
const signatureUserAgent = document.querySelector('.signature-pad-user-agent')
const clearSignature = document.querySelector('.signature-pad-clear')
const signatureInputBlankMsg = document.querySelector('.signature-pad-blank-msg')

SignatureInput = {
  init: () => {
    let signature_pad = SignatureInput.initSignaturePad()
    SignatureInput.clear(signature_pad)

    window.onfocus = () => {
      SignatureInput.showSubmitButton()
    }

    return signature_pad
  },

  initSignaturePad: () => {
    if(!canvas) return

    canvas.height = canvas.offsetHeight
    canvas.width = canvas.offsetWidth
    window.onresize = SignatureInput.resizeCanvas(canvas)
    SignatureInput.resizeCanvas(canvas)
    let signature_pad = new SignaturePad(canvas)
    return signature_pad
  },

  resizeCanvas: (canvas) => {
    let ratio =  Math.max(document.querySelector('.signature-pad').devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext("2d").scale(ratio, ratio)
  },

  validate: (signature_pad) => {
    if (signature_pad.isEmpty()){
      if (signatureInputBlankMsg && signatureInputBlankMsg.textContent.length > 0) {
        document.querySelector('#alertModalLabel').innerHTML = signatureInputBlankMsg.textContent
        $("#alertModal").modal('show')
      } else {
        document.querySelector('#alertModalLabel').innerHTML = 'Please draw your signature in the box to signify your acceptance of the Terms and Conditions'
        $("#alertModal").modal('show')
      }
      return false
    } else {
      signatureInput.value = signature_pad.toDataURL("image/svg+xml")
      signatureTime.value = new Date
      signatureUserAgent.value = window.navigator.userAgent
      return true
    }
  },

  clear: (signature_pad) => {
    if(!clearSignature) return

    clearSignature.addEventListener("click", (e) => {
      signature_pad.clear()
    })
  },

  showSubmitButton: () => {
    if (!form || !inputs) return
    let inputs = form.elements
    inputs['button'].disabled = false
  }

}
export {canvas}
export default SignatureInput;