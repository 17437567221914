import WebcastVotingTimer from '../packs/webcast_voting_timer'
import WebcastVotingResult from '../packs/webcast_voting_result'

var noti_block = $("#notification_block");

if (noti_block.length){
  const wsUrl      = getWebSocketURL($('div#ws-host').data('host'));
  var event_id     = $('div#event-attr').data('id');
  var sharedWorker = require('./shared.worker.js');
  var worker;

  $('.toast').toast('show');

  try {
    worker = new sharedWorker();
    worker.port.start();
    worker.port.postMessage({
      event_id: event_id,
      wsUrl: wsUrl,
      action: 'subscribe'
    });

    // Set up the broadcast channel to listen to web socket events.
    // This is also similar to above handler. But the handler here is
    // for events being broadcasted to all the tabs.
    const broadcastChannel = new BroadcastChannel("WebSocketChannel");
    broadcastChannel.addEventListener("message", handleBroadcast);

    window.onbeforeunload = function() {
      worker.port.postMessage({
        event_id: event_id,
        wsUrl: wsUrl,
        action: 'unsubscribe'
      });
    };
  }
  catch(err){
    var webWorker = require('worker-loader!./web-worker.js');
    worker = new webWorker();

    worker.postMessage([event_id, wsUrl]);
    worker.onmessage = handleBroadcast;
  }
}

function getWebSocketURL(websocket_host) {
  if (websocket_host.length > 0) {
    return "wss://" + websocket_host+ "/cable";
  } else {
    return "wss://" + window.location.host + "/cable";
  }
}

function handleBroadcast(event) {
  switch (event.data.type) {
    case  "WSState":
      // console.log(event.data);
      break;
    case  "message":
      // console.log(event.data.data);
      processData(event.data.data);
      break;
  }
}

// Listen to broadcasts from server
function processData(data) {
  if (!data || data.event_id != event_id) {
    return;
  }

  // refresh vimeo iframe
  if (data.event_id == event_id && data.content == "refresh_webcast"){
    let msBeforeRefresh = Math.floor(Math.random() * (5000 - 1000 + 500) + 1000)

    setTimeout(function () {
      if (document.querySelector('body.dashboard') || document.querySelector('body.webcast')) {
        location.reload();
      }
    }, msBeforeRefresh)

    return;
  }

  // process aws ivs metadata
  if (data.event_id == event_id && data.content == "process_aws_ivs_metadata"){

    if (!data.metadata || data.metadata.length == 0) {
      return;
    }

    console.log(data.metadata)

    WebcastVotingTimer.updateLiveEvoting(data.metadata)

    if (data.metadata.voting_result) {
      WebcastVotingResult.process(data.metadata.id, data.metadata.voting_result)
    }

    return;
  }

  // Called when there's incoming data on the websocket for this channel
  var noti_message = $("#notification_message_" + data.event_id); //notification block in webcast and notification index
  var content = $.parseHTML(data.content);

  //show notification block if hidden
  if (noti_message.length){
    if (noti_block.css('display') == "none" && data.content && data.content != "clear_notification_message") {
      noti_message.empty();
      noti_message.append(content);
      noti_block.find(".created-at").html(data.created_at);
      noti_block.fadeIn("slow");
      $('.toast').toast('show');
    } else if (noti_block.css('display') != "none" && data.content && data.content != "clear_notification_message")  {
      noti_block.hide();
      noti_message.empty();
      noti_message.append(content);
      noti_block.find(".created-at").html(data.created_at);
      noti_block.fadeIn("slow");
      $('.toast').toast('show');
    } else if (noti_message && data.content && data.content == "clear_notification_message") {
      noti_block.fadeOut("slow");
      noti_message.html("");
      noti_block.find(".created-at").html("");
    }
  }
}