document.addEventListener("turbolinks:load", () => {
  if ($('div#event-attachments-attr').length > 0) {
    var load = 0;

    $("#poll-form").on("load", function() {
      /*Execute on every reload on iFrame*/
      load++;
      if(load > 1){
        /*Second reload is a submit*/
        $("#pollModal .modal-body").html("<div class='text-center poll-submit'></div>");
        $("#pollModal div.poll-submit").append("<img src='/company_custom/images/logo.png' class='img-fluid wc-co-logo'>");
        $("#pollModal div.poll-submit").append("<p class='poll-submit-message'>Thank you for submitting your poll.</p>");
        // $("#pollModal div.poll-submit").append("<a class='btn btn-secondary download-report'>Download Report</a>");
        $("#pollModal div.poll-submit").append("<a href='#' class='d-block mt-3' data-dismiss='modal'>Close</a>");

        // var event_slug = window.location.pathname.split('/')[1];
        // $.ajax({
        //   url: '/downloads/event_downloads',
        //   type: 'POST',
        //   data: {
        //     event_slug: event_slug,
        //     data_type: 'company_key_statistics'
        //   },
        //   success: function (response) {
        //     var link = "/downloads/download_data?data_type=company_key_statistics&event_slug=" + event_slug + "&data_id=" + response.data_id + "&data_format=" + response.data_format;
        //     $("a.download-report").attr("href", link);
        //     $("a.download-report").attr("download", response.data_id + "." + response.data_format);
        //   }
        // });
        // $("a.download-report").on('click',function(){
        //     if (window.navigator.userAgent.indexOf("Edge") > -1) {
        //       window.location.href = $("a.download-report").attr("href");
        //     }
        //     $(this).remove();
        // });
      }
    });
  }
})

