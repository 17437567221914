var WebcastVotingTimer;
if (typeof WebcastVotingTimer === 'undefined') WebcastVotingTimer = {};

WebcastVotingTimer = {
  votingStarted: false,
  timerID: '',
  init: (options) => {
    WebcastVotingTimer.currentTimeContainer = document.querySelector('#js-ew-current-datetime')
    WebcastVotingTimer.votingTimerIDContainer = document.querySelector('#js-ew-timerid')
    WebcastVotingTimer.votingTimerContainers = document.querySelectorAll('.js-ew-voting-timer-container')
    WebcastVotingTimer.votingTimers = document.querySelectorAll('.js-ew-voting-timer')
    WebcastVotingTimer.votingTimerIcons = document.querySelectorAll('.js-ew-voting-timer-icon')
    WebcastVotingTimer.votingDuration = document.querySelector('#js-ew-voting-duration-timer')
    WebcastVotingTimer.changeColorAtSeconds = document.querySelector('#timer-change-color-at-seconds')

    WebcastVotingTimer.votingTimerContainerMinis = document.querySelectorAll('.js-ew-voting-timer-container-min')
    WebcastVotingTimer.votingTimerMinis = document.querySelectorAll('.js-ew-voting-timer-min')
    WebcastVotingTimer.votingTimerIconMinis = document.querySelectorAll('.js-ew-voting-timer-icon-min')

    if (WebcastVotingTimer.votingTimerIDContainer) {
      WebcastVotingTimer.timerID = WebcastVotingTimer.votingTimerIDContainer.dataset.timerId
      WebcastVotingTimer.startTimerMetadataID = ''
      WebcastVotingTimer.endTimerMetadataID = ''
    }

    if (options && options.votingStartedCallback) {
      WebcastVotingTimer.votingStartedCallback = options.votingStartedCallback
    }

    if (options && options.votingEndedCallback) {
      WebcastVotingTimer.votingEndedCallback = options.votingEndedCallback
    }

    if (options && options.votingTimerUpdatedCallback) {
      WebcastVotingTimer.votingTimerUpdatedCallback = options.votingTimerUpdatedCallback
    }

    WebcastVotingTimer.setupTimer()
  },

  setupTimer: () => {
    let worker = require('worker-loader!./timer-worker.js')
    let timerWorker = new worker()

    if (WebcastVotingTimer.votingTimers) {
      // check every second
      timerWorker.postMessage([0, 1])
      timerWorker.onmessage = function(e) {
        let currentTimeText = WebcastVotingTimer.currentTimeContainer.dataset.value
        if (currentTimeText.length > 0) {
          let currentTimeInSeconds = parseInt(currentTimeText)
          WebcastVotingTimer.currentTimeContainer.dataset.value = currentTimeInSeconds + 1
          // var seconds = new Date().getTime() / 1000;
          // console.log(currentTimeText + ' vs ' + seconds)
          WebcastVotingTimer.timerCallback()
        }
      }
    }
  },

  timerCallback: () => {
    WebcastVotingTimer.updateLiveEvotingCountdownTimer()
    WebcastVotingTimer.updateVotingFormButton()
    if (WebcastVotingTimer.votingTimerUpdatedCallback) {
      WebcastVotingTimer.votingTimerUpdatedCallback()
    }
  },

  updateLiveEvotingCountdownTimer: () => {
    if (!WebcastVotingTimer.votingDuration || WebcastVotingTimer.votingDuration.dataset.value.length <= 0) {
      return
    }
    WebcastVotingTimer.votingTimerContainers.forEach(function(votingTimerContainer){
      if (votingTimerContainer.style.display == 'none'){
        votingTimerContainer.style.display = 'block'
        votingTimerContainer.querySelector(".js-ew-voting-timer-icon").style.display = 'inline-block'
      }
    })

    let durationInSeconds = parseInt(WebcastVotingTimer.votingDuration.dataset.value)
    let seconds = durationInSeconds - 1
    let subStart = 14

    if (durationInSeconds >= 3600) {
      subStart = 11
    }

    if (seconds >= 0) {
      let timerText = new Date(seconds * 1000).toISOString().substring(subStart, 19)
      WebcastVotingTimer.votingTimers.forEach(function(votingTimer){
        votingTimer.innerHTML = timerText
      })
      if (WebcastVotingTimer.votingTimerContainerMinis.length > 0) {
        WebcastVotingTimer.votingTimerMinis.forEach(function(timerMini){
          timerMini.innerHTML = timerText
        })

      }
    }

    let changeColorAtSeconds = parseInt( WebcastVotingTimer.changeColorAtSeconds.dataset.value)
    if (seconds <= changeColorAtSeconds) {
      WebcastVotingTimer.votingTimerContainers.forEach(function(votingTimerContainer){
        votingTimerContainer.classList.add('ending')
      })
      if (WebcastVotingTimer.votingTimerContainerMinis.length > 0) {
        WebcastVotingTimer.votingTimerContainerMinis.forEach(function(timerMini){
          timerMini.classList.add('ending')
        })
      }
    }

    if (durationInSeconds <= 0) {
      WebcastVotingTimer.votingDuration.dataset.value = ''
      WebcastVotingTimer.votingTimerContainers.forEach(function(votingTimerContainer){
        votingTimerContainer.classList.remove('ending')
        votingTimerContainer.querySelector(".js-ew-voting-timer-icon").style.display = 'none'
        votingTimerContainer.querySelector(".js-ew-voting-timer").innerHTML = "Voting is now closed."
      })

      if (WebcastVotingTimer.votingTimerContainerMinis.length > 0) {
        WebcastVotingTimer.votingTimerContainerMinis.forEach(function(timerMini){
          timerMini.classList.remove('ending')
        })
      }

      if (WebcastVotingTimer.votingTimerContainerMinis.length > 0) {
        WebcastVotingTimer.votingTimerContainerMinis.forEach(function(timerMini){
          timerMini.closest('.js-timer-min-container-outer').classList.add('d-none')
          timerMini.closest('.js-timer-min-container-outer').nextElementSibling.classList.remove('d-none')
        })

      }

      if (WebcastVotingTimer.votingEndedCallback) {
        WebcastVotingTimer.votingEndedCallback()
      }
    } else {
      if (WebcastVotingTimer.votingStarted) {
        if (WebcastVotingTimer.votingStartedCallback) {
          WebcastVotingTimer.votingStartedCallback()
        }
        WebcastVotingTimer.votingStarted = false
      }
      WebcastVotingTimer.votingTimerIcons.forEach(function(votingTimerIcon){
        if (votingTimerIcon.style.display == 'none') {
          votingTimerIcon.style.display = 'inline-block'
        }
      })

      WebcastVotingTimer.votingDuration.dataset.value = seconds
    }
  },


  updateLiveEvoting: (webcastMetadata) => {
    if (!webcastMetadata.live_evoting) {
      return
    }

    let metadataID = webcastMetadata.id
    let liveEvotingMetadata = webcastMetadata.live_evoting
    if (liveEvotingMetadata.start_voting &&
      parseInt(WebcastVotingTimer.timerID) != parseInt(liveEvotingMetadata.id) &&
      (WebcastVotingTimer.startTimerMetadataID.length == 0 ||
        parseInt(metadataID) > parseInt(WebcastVotingTimer.startTimerMetadataID))) {

      let durationText = parseInt(liveEvotingMetadata.end_datetime_seconds) - parseInt(webcastMetadata.current_datetime_seconds)
      if (durationText) {
        WebcastVotingTimer.votingDuration.dataset.value = durationText
      }
      WebcastVotingTimer.startTimerMetadataID = metadataID
      WebcastVotingTimer.votingStarted = true
      WebcastVotingTimer.timerID = liveEvotingMetadata.id
    } else if (liveEvotingMetadata.end_voting &&
      parseInt(WebcastVotingTimer.timerID) == parseInt(liveEvotingMetadata.id) &&
      WebcastVotingTimer.votingDuration.dataset.value != "0" &&
      (WebcastVotingTimer.endTimerMetadataID.length == 0 ||
        parseInt(metadataID) > parseInt(WebcastVotingTimer.endTimerMetadataID))) {

      WebcastVotingTimer.endTimerMetadataID = metadataID
      WebcastVotingTimer.votingDuration.dataset.value = 0
      WebcastVotingTimer.updateLiveEvotingCountdownTimer()
    }
  },

  updateVotingFormButton: () => {
    let currentTimeText = WebcastVotingTimer.currentTimeContainer.dataset.value
    let eventVotingStartTimeText = document.querySelector('#event-attr').getAttribute('data-live-evote-start-datetime')

    if (!(currentTimeText.length > 0 && eventVotingStartTimeText && eventVotingStartTimeText.length > 0)) {
      return
    }

    let currentTimeInSeconds = parseInt(currentTimeText)
    let eventVotingStartTime = parseInt(eventVotingStartTimeText)

    if (currentTimeInSeconds < eventVotingStartTime) {
      return
    }

    document.querySelectorAll('.js-voting-form-button-banner').forEach(function(elem){
      elem.style.display = 'none'
    })

    document.querySelectorAll('.js-voting-form-button-container').forEach(function(elem){
      elem.style.display = 'block'
    })
  }
}

export default WebcastVotingTimer;
