import axios from 'axios'
import SignatureInput from './signature_input'

var csrfToken;
if (document.querySelector("meta[name=csrf-token]")) {
  csrfToken = document.querySelector("meta[name=csrf-token]").content
}
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

var VotingForm;
if (typeof VotingForm === 'undefined') VotingForm = {};

const form = document.querySelector('#voting-form')
const progressBar = document.querySelector('#progressbar')
const voterSelector = document.querySelector('#voter-attr')
const errorsContainer = document.querySelector('#error_explanation')
const maxSteps = 3
const liveVotingFormBody = document.querySelector("#votingForm_body")
const liveVRSlides = document.querySelectorAll('.vr-slide')
const liveTotalVRNumber = document.querySelector("#js-total-resolutions-number")
const liveVotingResolutionInfo = document.querySelector("#js-resolution-info")
//liveVotingFormNaviMobile is used on (live voting form - mobile) only.
const liveVotingFormNaviMobile = document.querySelector("#lvf-navi-mobile")
//votingFormMainNavi is used on all voting form navigations including online proxy form, discretionary form, (live voting form - desktop only).
const votingFormMainNavi = document.querySelector("#vf-main-navi")

VotingForm = {
  formState: '',
  prevStep: 1,
  currentStep: 1,
  animating: false,
  signature_pad: SignatureInput.init(),
  isMobile: false,

  init: () => {
    VotingForm.currentStep = document.querySelectorAll('#progressbar li.active').length
    //add animation class in init to prevent css animation bug from animating before form fully loaded
    form.classList.add('animate')
    window.onload = function() {
      window.onbeforeunload = function(e) {
        if (VotingForm.currentStep != 3) {
          e.returnValue = '';
        }
      };
    };

    if(!!form.querySelector('#voter_company_name')) {
      form.querySelector('#voter_company_name').addEventListener('input',
        VotingForm.voter_company_name_on_change)
      VotingForm.voter_company_name_on_change()
    }

    VotingForm.change_attachment_event_listener()
    VotingForm.voter_number_of_shares_on_change()
    VotingForm.live()
    VotingForm.updateAnswer()
    VotingForm.toggleSplitVoteFields()
    VotingForm.next()
    VotingForm.back()
    VotingForm.loadVRSlider()

    if (form.querySelector('button#edit')) {
      form.querySelector('button#edit').addEventListener("click", (e) => {
        e.preventDefault()
        VotingForm.edit()
      })
    }
    if (form.querySelector('button#resubmitVotes')) {
      form.querySelector('button#resubmitVotes').addEventListener("click", (e) => {
        e.preventDefault()
        VotingForm.edit()
        form.querySelector('#proxyFormResetted').style.display = 'none'
      })
    }
  },

  updateProgressBar: () => {
    // activate
    for (let i = 1; i <= VotingForm.currentStep; i++) {
      let step = progressBar.querySelector('li.progressbar_step' + i.toString())
      if (!step.classList.contains('active')) {
        step.classList.add('active')
      }
    }

    // deactivate
    for (let i = VotingForm.currentStep+1; i <= maxSteps; i++) {
      let step = progressBar.querySelector('li.progressbar_step' + i.toString())
      if (step.classList.contains('active')) {
        step.classList.remove('active')
      }
    }
  },

  toggleFormStep: () => {
    let current = form.querySelector('div.form_step' + VotingForm.currentStep.toString())
    let previous = form.querySelector('div.form_step' + VotingForm.prevStep.toString())

    if (VotingForm.prevStep > VotingForm.currentStep) {
      previous.classList.remove('slide-in')
      previous.classList.remove('hidden_left')
      previous.classList.add('hidden_right')
      current.classList.add('slide-in')
    } else if (VotingForm.prevStep < VotingForm.currentStep) {
      previous.classList.remove('slide-in')
      previous.classList.remove('hidden_right')
      previous.classList.add('hidden_left')
      current.classList.add('slide-in')
    }
  },

  toggleBackButton: () => {
    if(VotingForm.currentStep == 1 && VotingForm.currentVRSliderStep && VotingForm.isMobile){
      if (VotingForm.currentVRSliderStep == 1){
        votingFormMainNavi.querySelector('button.step_back').style.display = 'none'
        votingFormMainNavi.querySelector('button.step_back').disabled = true
        votingFormMainNavi.querySelector('#nextSpinner').style.display = 'inline-block'
        // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
        // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
        if (liveVotingFormNaviMobile){
          liveVotingFormNaviMobile.querySelector('button.step_back').style.display = 'none'
          liveVotingFormNaviMobile.querySelector('button.step_back').disabled = true
          liveVotingFormNaviMobile.querySelector('#nextSpinner').style.display = 'inline-block'
        }
      }else{
        votingFormMainNavi.querySelector('button.step_back').style.display = 'inline-block'
        votingFormMainNavi.querySelector('button.step_back').disabled = false
        votingFormMainNavi.querySelector('#backSpinner').style.display = 'none'
        // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
        // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
        if (liveVotingFormNaviMobile){
          liveVotingFormNaviMobile.querySelector('button.step_back').style.display = 'inline-block'
          liveVotingFormNaviMobile.querySelector('button.step_back').disabled = false
          liveVotingFormNaviMobile.querySelector('#backSpinner').style.display = 'none'
        }
      }
    } else if (VotingForm.currentStep == 2) {
      votingFormMainNavi.querySelector('button.step_back').style.display = 'block'
      votingFormMainNavi.querySelector('button.step_back').disabled = false
      votingFormMainNavi.querySelector('#backSpinner').style.display = 'none'
      // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
      // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
      if(liveVotingFormNaviMobile){
        liveVotingFormNaviMobile.querySelector('button.step_back').style.display = 'block'
        liveVotingFormNaviMobile.querySelector('button.step_back').disabled = false
        liveVotingFormNaviMobile.querySelector('#backSpinner').style.display = 'none'
      }
    } else {
      votingFormMainNavi.querySelector('button.step_back').style.display = 'none'
      votingFormMainNavi.querySelector('button.step_back').disabled = true
      votingFormMainNavi.querySelector('#backSpinner').style.display = 'inline-block'
      // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
      // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
      if(liveVotingFormNaviMobile){
        liveVotingFormNaviMobile.querySelector('button.step_back').style.display = 'none'
        liveVotingFormNaviMobile.querySelector('button.step_back').disabled = true
        liveVotingFormNaviMobile.querySelector('#backSpinner').style.display = 'inline-block'
      }
    }
  },

  toggleNextButton: () => {
    if (VotingForm.currentStep == 3) {
      votingFormMainNavi.querySelector('button.submit').style.display = 'none'
      votingFormMainNavi.querySelector('button.submit').disabled = true
      votingFormMainNavi.querySelector('#nextSpinner').style.display = 'inline-block'
      // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
      // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
      if(liveVotingFormNaviMobile){
        liveVotingFormNaviMobile.querySelector('button.submit').style.display = 'none'
        liveVotingFormNaviMobile.querySelector('button.submit').disabled = true
        liveVotingFormNaviMobile.querySelector('#nextSpinner').style.display = 'inline-block'
      }
    } else {
      votingFormMainNavi.querySelector('button.submit').style.display = 'inline-block'
      votingFormMainNavi.querySelector('button.submit').disabled = false
      votingFormMainNavi.querySelector('#nextSpinner').style.display = 'none'
      // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
      // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
      if(liveVotingFormNaviMobile){
        liveVotingFormNaviMobile.querySelector('button.submit').style.display = 'inline-block'
        liveVotingFormNaviMobile.querySelector('button.submit').disabled = false
        liveVotingFormNaviMobile.querySelector('#nextSpinner').style.display = 'none'
      }
    }
  },

  showResolutionsForm: () => {
    VotingForm.prevStep = VotingForm.currentStep
    VotingForm.currentStep = 1
    VotingForm.updateProgressBar()
    VotingForm.toggleFormStep()
    VotingForm.toggleBackButton()
    VotingForm.toggleNextButton()
    progressBar.scrollIntoView(true)
    if (liveVotingFormBody){
      liveVotingFormBody.scrollTop = 0
    }
    votingFormMainNavi.querySelector('span.submit-text').innerHTML = 'NEXT'
    // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
    // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
    if(liveVotingFormNaviMobile){
      liveVotingFormNaviMobile.querySelector('span.submit-text').innerHTML = 'NEXT'
    }
  },

  validateFormData: () => {
    if (!!VotingForm.signature_pad && !SignatureInput.validate(VotingForm.signature_pad)) {
      votingFormMainNavi.querySelector('button.submit').disabled = false
      votingFormMainNavi.querySelector('#nextSpinner').style.display = 'none'
      // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
      // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
      if(liveVotingFormNaviMobile){
        liveVotingFormNaviMobile.querySelector('button.submit').disabled = false
        liveVotingFormNaviMobile.querySelector('#nextSpinner').style.display = 'none'
      }
      return
    }

    errorsContainer.classList.remove('show')

    let eventID = voterSelector.getAttribute("data-event-id")
    let voterID = voterSelector.getAttribute("data-voter-id")
    let formData = new FormData(form)

    formData.delete('_method')
    formData.delete('authenticity_token')
    formData.append('voter[id]', voterID)

    let dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/voters/validate_input',
      data: formData,
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
      },
    }

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data.status == 'ok') {
          // success
          Object.keys(response.data.voting_form_answers).forEach(function(questionID) {
            if(form.querySelector('table.review-resolution').querySelector('b.review-question-id-' + questionID)) {
              let result = '<div class="row ml-1 mr-1 mt-1">'
              let answers = Object.keys(response.data.voting_form_answers[questionID])

              if (answers && answers.length > 0) {
                answers.forEach(function(option) {
                  result += VotingForm.formatVotingAnswer(option, response.data.voting_form_answers[questionID][option])
                })
              } else {
                result += VotingForm.formatVotingAnswer('Voting instructions not specified.', '')
              }

              result += '</div>'
              form.querySelector('table.review-resolution').querySelector('b.review-question-id-' + questionID).innerHTML = result
            }
          });
          if (!!document.querySelector('.js-physical-info-container')) { // if elements exist
            document.querySelectorAll('.js-physical-info-container').forEach(function(elem) {
              elem.querySelector('.js-voter-id').innerHTML = response.data.voter_identification_number
              elem.querySelector('.js-voter-name').innerHTML = response.data.voter_full_name || response.data.voter_company_name
              elem.querySelector('.js-voter-address').innerHTML = response.data.voter_address
            })

            document.querySelector('#form2_voter_number_of_shares').innerHTML = response.data.voter_number_of_shares
            document.querySelector('#form2_voting_form_signed_at').innerHTML = response.data.voting_form_signed_at

            if (response.data.voter_company_name && response.data.voter_company_name.length > 0) {
              document.querySelector('#form2_voting_form_legal_documents').closest('tr').style.display = 'table-row'
              if (response.data.voting_form_legal_documents && response.data.voting_form_legal_documents.length > 0) {
                document.querySelector('#form2_voting_form_legal_documents').innerHTML = response.data.voting_form_legal_documents
                document.querySelector('#form2_voting_form_legal_documents').classList.remove('text-danger')
              } else {
                document.querySelector('#form2_voting_form_legal_documents').innerHTML = 'No file uploaded'
                document.querySelector('#form2_voting_form_legal_documents').classList.add('text-danger')
              }
            } else {
              document.querySelector('#form2_voting_form_legal_documents').closest('tr').style.display = 'none'
            }

            if (response.data.voting_form_physical_copy && response.data.voting_form_physical_copy.length > 0) {
              document.querySelector('#form2_voting_form_physical_copy').innerHTML = response.data.voting_form_physical_copy
              document.querySelector('#form2_voting_form_physical_copy').classList.remove('text-danger')
            } else {
              document.querySelector('#form2_voting_form_physical_copy').innerHTML = 'No file uploaded'
              document.querySelector('#form2_voting_form_physical_copy').classList.add('text-danger')
            }
          }

          document.querySelectorAll('.js-proxy-a-container').forEach(function(elem) {
            if (elem.querySelector('.js-proxy-a-name')) elem.querySelector('.js-proxy-a-name').innerHTML = response.data.proxy_a_name
            if (elem.querySelector('.js-proxy-a-email')) elem.querySelector('.js-proxy-a-email').innerHTML = response.data.proxy_a_email
            if (elem.querySelector('.js-proxy-a-id')) elem.querySelector('.js-proxy-a-id').innerHTML = response.data.proxy_a_id
            if (elem.querySelector('.js-proxy-a-shares')) elem.querySelector('.js-proxy-a-shares').innerHTML = response.data.proxy_a_shares
            if (elem.querySelector('.js-proxy-a-address')) elem.querySelector('.js-proxy-a-address').innerHTML = response.data.proxy_a_address
          })
          document.querySelectorAll('.js-proxy-b-container').forEach(function(elem) {
            if (elem.querySelector('.js-proxy-b-name')) elem.querySelector('.js-proxy-b-name').innerHTML = response.data.proxy_b_name
            if (elem.querySelector('.js-proxy-b-email')) elem.querySelector('.js-proxy-b-email').innerHTML = response.data.proxy_b_email
            if (elem.querySelector('.js-proxy-b-id')) elem.querySelector('.js-proxy-b-id').innerHTML = response.data.proxy_b_id
            if (elem.querySelector('.js-proxy-b-shares')) elem.querySelector('.js-proxy-b-shares').innerHTML = response.data.proxy_b_shares
            if (elem.querySelector('.js-proxy-b-address')) elem.querySelector('.js-proxy-b-address').innerHTML = response.data.proxy_b_address
          })

          VotingForm.showReview()
        }else if(response.data && response.data.errors){
          // fail
          VotingForm.showResolutionsFormErrors(response.data.errors)
          votingFormMainNavi.querySelector('button.submit').disabled = false
          votingFormMainNavi.querySelector('#nextSpinner').style.display = 'none'
          // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
          // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
          if(liveVotingFormNaviMobile){
            liveVotingFormNaviMobile.querySelector('button.submit').disabled = false
            liveVotingFormNaviMobile.querySelector('#nextSpinner').style.display = 'none'
          }
        }else if(response.status == 204) {
          location.reload();
        }
      })
      .catch(error => {
        let errMsg = 'Your voting form cannot be validated due to error: ' + error.message
        if (error.message == 'Network Error') {
          errMsg += '. Please check your internet connection.'
        }
        document.querySelector('#alertModalLabel').innerHTML = errMsg
        $("#alertModal").modal('show')
        console.error('catch', error)
        votingFormMainNavi.querySelector('button.submit').disabled = false
        votingFormMainNavi.querySelector('#nextSpinner').style.display = 'none'
        // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
        // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
        if (liveVotingFormNaviMobile){
          liveVotingFormNaviMobile.querySelector('button.submit').disabled = false
          liveVotingFormNaviMobile.querySelector('#nextSpinner').style.display = 'none'
        }
      })
  },

  updateAnswerOnLiveVotingForm: () => {
    errorsContainer.classList.remove('show')

    let eventID = voterSelector.getAttribute("data-event-id")
    let voterID = voterSelector.getAttribute("data-voter-id")
    let formData = new FormData(form)

    formData.delete('_method')
    formData.append('voter[id]', voterID)

    let dataObj = {
      method: 'PATCH',
      url: '/events/' + eventID + '/voters/' + voterID + '/update_answer_in_live_voting_form',
      data: formData
    }

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data.status == 'ok') {
          // success
          // console.info('Live Voting Form Answer update successful!')
        }else if(response.status == 200 && response.data && response.data.errors) {
          // fail
          console.error('Your last answer on this voting form was not recorded due to error: ' + response.data.errors)
          document.querySelector('#alertModalLabel').innerHTML =
            'Your last answer on this voting form was not recorded due to error: ' + response.data.errors
          $("#alertModal").modal('show')
        }
      })
      .catch(error => {
        let errMsg = 'Your last answer on this voting form was not recorded due to error: ' + error.message
        if (error.message == 'Network Error') {
          errMsg += '. Please check your internet connection.'
        }
        document.querySelector('#alertModalLabel').innerHTML = errMsg
        $("#alertModal").modal('show')
        console.error('catch', error)
      })
  },

  updateSharesInPhysicalProxyFormVotingAnswers: () => {
    if (!!document.querySelector('#physical-proxy-form-voter-number-of-shares')) { // if element exists
      let answer_shares = document.querySelector('#js-vf-total-number-of-shares')
      answer_shares.dataset.value = document.querySelector('#physical-proxy-form-voter-number-of-shares').value

      let checkedBoxes = document.querySelectorAll('input.js-vf-answer-tmp-radio:checked')
      checkedBoxes.forEach(function(e) {
        VotingForm.updateAnswerPerOption(e)
      })
    }
  },

  updateAnswerPerOption: (checkbox) => {
    let option = checkbox.value
    let questionID = checkbox.getAttribute('data-question-id')
    let answer_shares;

    if (document.querySelector('#js-vf-number-of-discretionary-shares-'+ questionID)){
      answer_shares = document.querySelector('#js-vf-number-of-discretionary-shares-'+ questionID).getAttribute('data-value')
    } else {
      answer_shares = document.querySelector('#js-vf-total-number-of-shares').getAttribute('data-value')
    }

    document.querySelectorAll('.js-vf-answer-number-of-shares[data-question-id="'+ questionID +'"]').forEach(function(e) {
      if (option == e.getAttribute('data-question-answer')) {
        e.value = parseInt(answer_shares)
      } else {
        e.value = 0
      }
    })

    if(document.querySelectorAll(`[id^="live_voting_form_question_answer_"]`).length > 0){
      VotingForm.updateAnswerOnLiveVotingForm()
    }
  },

  clearRadioButtonsForSplitVoteAnswers: (textBox) => {
    let questionID = textBox.getAttribute('data-question-id')
    document.querySelectorAll('.js-vf-answer-tmp-radio[data-question-id="'+ questionID +'"]').forEach(function(e) {
      e.checked = false
    })
  },

  showResolutionsFormErrors: (errors) => {
    if (errors.length <= 0) {
      return
    }

    let list = ''
    for (var err of errors) {
      list += '<li>' + err + '</li>'
    }

    VotingForm.showResolutionsForm()
    errorsContainer.querySelector('div.col').querySelector('ul.error-list').innerHTML = list
    errorsContainer.querySelector('div.col').querySelector('.error-count').innerHTML = errors.length
    errorsContainer.classList.add('show')
    errorsContainer.scrollIntoView(true)
  },

  showReview: () => {
    VotingForm.prevStep = VotingForm.currentStep
    VotingForm.currentStep = 2
    VotingForm.updateProgressBar()
    VotingForm.toggleFormStep()
    VotingForm.toggleBackButton()
    VotingForm.toggleNextButton()
    progressBar.scrollIntoView(true)
    if (liveVotingFormBody){
      liveVotingFormBody.scrollTop = 0
    }
    votingFormMainNavi.querySelector('span.submit-text').innerHTML = 'SUBMIT'
    // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
    // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
    if (liveVotingFormNaviMobile){
      liveVotingFormNaviMobile.querySelector('span.back-text').innerHTML = 'BACK'
      liveVotingFormNaviMobile.querySelector('span.submit-text').innerHTML = 'SUBMIT'
    }
  },

  showCompletion: (data) => {
    // load answers
    Object.keys(data.voting_form_answers).forEach(function(questionID) {
      if(form.querySelector('table.review-resolution').querySelector('b.review-question-id-' + questionID)) {
        let result = '<div class="row ml-1 mr-1 mt-1">'
        let answers = Object.keys(data.voting_form_answers[questionID])

        if (answers && answers.length > 0) {
          answers.forEach(function(option) {
            result += VotingForm.formatVotingAnswer(option, data.voting_form_answers[questionID][option])
          })
        } else {
          result += VotingForm.formatVotingAnswer('Voting instructions not specified.', '')
        }

        result += '</div>'
        form.querySelector('table.complete-resolution').querySelector('b.review-question-id-' + questionID).innerHTML = result
      }
    });

    // load signature image, if exists
    if (!!data.voting_form_signature_id) {
      var image = document.querySelector('div.current-signature img')
      var downloadingImage = new Image()

      downloadingImage.onload = function(){
        image.src = this.src;
      }
      downloadingImage.src = '/events/' +
        data.event_id + '/voters/' + data.voter_id +
        '/show_signature?signature_id=' + data.voting_form_signature_id
    }

    if (!!form.querySelector('span.voting-form-date') && data.voting_form_updated_at) {
      form.querySelector('span.voting-form-date').innerHTML = data.voting_form_updated_at
    }

    VotingForm.prevStep = VotingForm.currentStep
    VotingForm.currentStep = 3
    VotingForm.updateProgressBar()
    VotingForm.toggleFormStep()
    VotingForm.toggleBackButton()
    VotingForm.toggleNextButton()
    progressBar.scrollIntoView(true)
    if (liveVotingFormBody){
      liveVotingFormBody.scrollTop = 0
    }
    form.querySelector('b#txn-number').innerHTML = data.transaction_number
    if (data.voting_form_is_proxy === true) {
      if (data.voting_form_status === 'VERIFIED') {
        form.querySelector('div#proxy_form_status_banner').classList.add('alert-success')
        form.querySelector('div#proxy_form_status_banner').classList.remove('alert-warning')
      } else if (data.voting_form_status === 'SUBMITTED') {
        form.querySelector('div#proxy_form_status_banner').classList.add('alert-warning')
        form.querySelector('div#proxy_form_status_banner').classList.remove('alert-danger')
      }
      form.querySelector('b#proxy_form_status').innerHTML = data.voting_form_status

      if (!!document.querySelector('.js-physical-info-container')) { // if elements exist
        document.querySelectorAll('.js-physical-info-container').forEach(function(elem) {
          elem.querySelector('.js-voter-id').innerHTML = data.voter_identification_number
          elem.querySelector('.js-voter-name').innerHTML = data.voter_full_name || data.voter_company_name
          elem.querySelector('.js-voter-address').innerHTML = data.voter_address
        })

        document.querySelector('#form3_voter_number_of_shares').innerHTML = data.voter_number_of_shares
        document.querySelector('#form3_voting_form_signed_at').innerHTML = data.voting_form_signed_at

        if (data.voter_company_name && data.voter_company_name.length > 0) {
          document.querySelector('#form3_voting_form_legal_documents').closest('tr').style.display = 'table-row'
          if (data.voting_form_legal_documents && data.voting_form_legal_documents.length > 0) {
            document.querySelector('#form3_voting_form_legal_documents').innerHTML = data.voting_form_legal_documents
            document.querySelector('#form3_voting_form_legal_documents').classList.remove('text-danger')
          } else {
            document.querySelector('#form3_voting_form_legal_documents').innerHTML = 'No file uploaded'
            document.querySelector('#form3_voting_form_legal_documents').classList.add('text-danger')
          }
        } else {
          document.querySelector('#form3_voting_form_legal_documents').closest('tr').style.display = 'none'
        }

        if (data.voting_form_physical_copy && data.voting_form_physical_copy.length > 0) {
          document.querySelector('#form3_voting_form_physical_copy').innerHTML = data.voting_form_physical_copy
          document.querySelector('#form3_voting_form_physical_copy').classList.remove('text-danger')
        } else {
          document.querySelector('#form3_voting_form_physical_copy').innerHTML = 'No file uploaded'
          document.querySelector('#form3_voting_form_physical_copy').classList.add('text-danger')
        }
      }

      document.querySelectorAll('.js-proxy-a-container').forEach(function(elem) {
        if (elem.querySelector('.js-proxy-a-name')) elem.querySelector('.js-proxy-a-name').innerHTML = data.proxy_a_name
        if (elem.querySelector('.js-proxy-a-email')) elem.querySelector('.js-proxy-a-email').innerHTML = data.proxy_a_email
        if (elem.querySelector('.js-proxy-a-id')) elem.querySelector('.js-proxy-a-id').innerHTML = data.proxy_a_id
        if (elem.querySelector('.js-proxy-a-shares')) elem.querySelector('.js-proxy-a-shares').innerHTML = data.proxy_a_shares
        if (elem.querySelector('.js-proxy-a-address')) elem.querySelector('.js-proxy-a-address').innerHTML = data.proxy_a_address
      })
      document.querySelectorAll('.js-proxy-b-container').forEach(function(elem) {
        if (elem.querySelector('.js-proxy-b-name')) elem.querySelector('.js-proxy-b-name').innerHTML = data.proxy_b_name
        if (elem.querySelector('.js-proxy-b-email')) elem.querySelector('.js-proxy-b-email').innerHTML = data.proxy_b_email
        if (elem.querySelector('.js-proxy-b-id')) elem.querySelector('.js-proxy-b-id').innerHTML = data.proxy_b_id
        if (elem.querySelector('.js-proxy-b-shares')) elem.querySelector('.js-proxy-b-shares').innerHTML = data.proxy_b_shares
        if (elem.querySelector('.js-proxy-b-address')) elem.querySelector('.js-proxy-b-address').innerHTML = data.proxy_b_address
      })
    } else {
      if (data.voting_form_status === 'VERIFIED') {
        form.querySelector('div#live_voting_form_status_banner').classList.add('alert-success')
        form.querySelector('div#live_voting_form_status_banner').classList.remove('alert-warning')
        let votingFormBtns = document.querySelectorAll('.show-votingform')
        let voterBtnGroup = document.querySelector('#js-voters-btn-grp')

        if (votingFormBtns.length > 0 && (!voterBtnGroup ||
            (!!voterBtnGroup && !!voterBtnGroup.querySelector('a') && !!voterBtnGroup.querySelector('a.js-verified') &&
            voterBtnGroup.querySelector('a').length == voterBtnGroup.querySelector('a.js-verified').length))) { // if elements exist
          votingFormBtns.forEach(function(btn){
            btn.classList.add('btn-success')
            btn.classList.add('verified')
            btn.classList.remove('btn-warning')
            btn.classList.remove('btn-danger')
            btn.classList.remove('draft')
            btn.querySelector('.js-ew-voting-form-button-msg').innerHTML = 'Your votes have already been submitted'
            let miniTimer = btn.querySelector('.js-ew-voting-timer-container-min')
            if (miniTimer){
              miniTimer.closest('.js-timer-min-container-outer').classList.add('d-none')
              miniTimer.closest('.js-timer-min-container-outer').nextElementSibling.classList.remove('d-none')
            }
          })
        }

        if (!!voterBtnGroup) {
          let currentBtn = voterBtnGroup.querySelector('a.active')
          currentBtn.classList.remove('btn-danger')
          currentBtn.classList.add('btn-success')
          currentBtn.classList.add('js-verified')
          currentBtn.querySelector('.js-icon .unverified').style.display = 'none'
          currentBtn.querySelector('.js-icon .verified').style.display = 'inline-block'
        }
        form.querySelector('.vf-flag').style.display = 'none'
      }
      form.querySelector('b#live_voting_form_status').innerHTML = data.voting_form_status
    }
      votingFormMainNavi.querySelector('span.submit-text').innerHTML = 'NEXT'
      // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
      // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
      if (liveVotingFormNaviMobile){
        liveVotingFormNaviMobile.querySelector('span.back-text').innerHTML = 'BACK'
        liveVotingFormNaviMobile.querySelector('span.submit-text').innerHTML = 'NEXT'
      }
  },

  submit: () => {
    let formData = new FormData(form)

    let dataObj = {
      method: form.method,
      url: form.action,
      data: formData,
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    }

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data.status == 'ok') {
          // success
          VotingForm.showCompletion(response.data)
        }else if(response.data && response.data.errors){
          // fail
          VotingForm.showResolutionsFormErrors(response.data.errors)
          votingFormMainNavi.querySelector('button.submit').disabled = false
          votingFormMainNavi.querySelector('#nextSpinner').style.display = 'none'
          // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
          // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
          if (liveVotingFormNaviMobile){
            liveVotingFormNaviMobile.querySelector('button.submit').disabled = false
            liveVotingFormNaviMobile.querySelector('#nextSpinner').style.display = 'none'
          }
        }else if(response.status == 204) {
          location.reload();
        }
      })
      .catch(error => {
        let errMsg = 'Your form may not have been submitted successfully due to error encountered: ' + error.message
        if (error.message == 'Network Error') {
          errMsg += '. Please check your internet connection.'
        }
        document.querySelector('#alertModalLabel').innerHTML = errMsg
        $("#alertModal").modal('show')
        console.error('catch', error)
        votingFormMainNavi.querySelector('button.submit').disabled = false
        votingFormMainNavi.querySelector('#nextSpinner').style.display = 'none'
        // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
        // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
        if (liveVotingFormNaviMobile){
          liveVotingFormNaviMobile.querySelector('button.submit').disabled = false
          liveVotingFormNaviMobile.querySelector('#nextSpinner').style.display = 'none'
        }
      })
  },

  edit: () => {
    if (!!VotingForm.signature_pad) {
      VotingForm.signature_pad.clear()
      VotingForm.showResolutionsForm()
      SignatureInput.initSignaturePad()
    } else {
      VotingForm.showResolutionsForm()
    }
  },

  voter_number_of_shares_on_change: () => {
    if (!!form.querySelector('#physical-proxy-form-voter-number-of-shares')) { // if element exists
      form.querySelector('#physical-proxy-form-voter-number-of-shares').addEventListener("change", (e) => {
        e.preventDefault()

        VotingForm.updateSharesInPhysicalProxyFormVotingAnswers()
      });
    }
  },

  live: () => {
    let answer_fields = document.querySelectorAll(`[id^="live_voting_form_question_answer_"]`);
    //remove "Changes that you made may not be saved." alert for live form
    if (answer_fields.length > 0){
      window.onload = function() {
        window.onbeforeunload = null;
      }
    }
    answer_fields.forEach(function(elem) {
      elem.addEventListener("change", (e) => {
        e.preventDefault()

        VotingForm.updateAnswerOnLiveVotingForm()
      });
    });
  },

  updateAnswer: () => {
    let answer_radios = document.querySelectorAll('input.js-vf-answer-tmp-radio');
    answer_radios.forEach(function(elem) {
      elem.addEventListener("change", (e) => {
        e.preventDefault()

        VotingForm.updateAnswerPerOption(e.currentTarget)
      });
    });


    let answer_fields = document.querySelectorAll('input.js-vf-answer-number-of-shares');
    answer_fields.forEach(function(elem) {
      elem.addEventListener("change", (e) => {
        e.preventDefault()

        VotingForm.clearRadioButtonsForSplitVoteAnswers(e.currentTarget)
      });
    });
  },

  formatVotingAnswer: (option, value) => {
    let labelColor = 'input-group-label-secondary'
    if(option.toLowerCase() == 'for'){
      labelColor = 'input-group-label-success'
    } else if(option.toLowerCase() == 'against') {
      labelColor = 'input-group-label-danger'
    } else if(option.toLowerCase() == 'abstain') {
      labelColor = 'input-group-label-secondary'
    } else {
      labelColor = 'input-group-label-light'
    }

    if (value && value.length > 0) {
      return '<div class="border p-1 col-3 offset-md-3 text-left '+ labelColor +'">'+ option +'</div><div class="border p-1 col-9 col-md-3 text-right bg-light">'+ value +'</div>'
    } else {
      return '<div class="border p-1 col-12 col-md-6 offset-md-3 text-center '+ labelColor +'">'+ option +'</div>'
    }
  },

  toggleSplitVoteFields: () => {
    document.querySelectorAll('.vf-voting-input-container').forEach(function(elem) {
      let questionID = elem.dataset.questionId
      let isSplitVote = elem.dataset.isSplitVote
      if (questionID && questionID.length > 0){
        VotingForm.toggleSplitVoteField(questionID, isSplitVote)
      }
    })

    document.querySelectorAll('.vf-split-vote-link').forEach(function(elem) {
      elem.addEventListener("click", (e) => {
        e.preventDefault()

        let questionID = elem.dataset.questionId
        elem.dataset.isSplitVote = 1

        document.querySelectorAll('#vf-split-vote-container-' + questionID + ' input.js-vf-answer-number-of-shares').forEach(function(input) {
          input.value = 0
        })

        document.querySelectorAll('#vf-split-vote-container-' + questionID + ' input.js-vf-answer-is-split-vote').forEach(function(input) {
          input.value = true
        })

        document.querySelectorAll('#vf-full-vote-container-' + questionID + ' input.js-vf-answer-tmp-radio').forEach(function(input) {
          input.checked = false
        })

        if (questionID && questionID.length > 0){
          VotingForm.toggleSplitVoteField(questionID, 1)
        }
      })
    })

    document.querySelectorAll('.vf-full-vote-link').forEach(function(elem) {
      elem.addEventListener("click", (e) => {
        e.preventDefault()

        let questionID = elem.dataset.questionId
        elem.dataset.isSplitVote = 0

        document.querySelectorAll('#vf-split-vote-container-' + questionID + ' input.js-vf-answer-number-of-shares').forEach(function(input) {
          input.value = 0
        })

        document.querySelectorAll('#vf-split-vote-container-' + questionID + ' input.js-vf-answer-is-split-vote').forEach(function(input) {
          input.value = false
        })

        document.querySelectorAll('#vf-full-vote-container-' + questionID + ' input.js-vf-answer-tmp-radio').forEach(function(input) {
          input.checked = false
        })

        if (questionID && questionID.length > 0){
          VotingForm.toggleSplitVoteField(questionID, 0)
        }
      })
    })
  },

  toggleSplitVoteField: (questionID, isSplitVote) => {
    let fullVoteContainer = document.querySelector('#vf-full-vote-container-' + questionID)
    let splitVoteContainer = document.querySelector('#vf-split-vote-container-' + questionID)

    if (isSplitVote == 0) {
      if(fullVoteContainer) fullVoteContainer.style.display = 'block'
      if(splitVoteContainer) splitVoteContainer.style.display = 'none'
    } else {
      if(fullVoteContainer) fullVoteContainer.style.display = 'none'
      if(splitVoteContainer) splitVoteContainer.style.display = 'block'
    }
  },

  next: () => {
    form.addEventListener("submit", (e) => {
      e.preventDefault()
      e.currentTarget.querySelector('button.submit').disabled = true
      votingFormMainNavi.querySelector('#nextSpinner').style.display = 'inline-block'
      // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
      // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
      if(liveVotingFormNaviMobile){
        liveVotingFormNaviMobile.querySelector('#nextSpinner').style.display = 'inline-block'
      }
      if (VotingForm.currentStep == 1) {
        // for reso-by-reso navigation, slide to next slide
        if ((VotingForm.currentVRSliderStep && VotingForm.maxVRSliderSteps) &&
            (VotingForm.currentVRSliderStep < VotingForm.maxVRSliderSteps) &&
            VotingForm.isMobile){
          VotingForm.nextVRSlide()
        } else{
          VotingForm.validateFormData()
        }
      } else if (VotingForm.currentStep == 2) {
        VotingForm.submit()
      }
    })
  },

  back: () => {
    if (!form.querySelector('button.step_back')) {
      return
    }

    form.querySelectorAll('button.step_back').forEach(function(backBtn){
      backBtn.addEventListener("click", (e) => {
        e.preventDefault()
        e.currentTarget.disabled = true
        votingFormMainNavi.querySelector('#backSpinner').style.display = 'inline-block'
        // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
        // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
        if (liveVotingFormNaviMobile){
          liveVotingFormNaviMobile.querySelector('#backSpinner').style.display = 'inline-block'
        }
        // for reso-by-reso navigation, slide to next slide
        if (VotingForm.currentStep == 1 &&
            (VotingForm.currentVRSliderStep && VotingForm.currentVRSliderStep > 1) &&
            VotingForm.isMobile){
          VotingForm.prevVRSlide()
        }else if (VotingForm.currentStep == 2) {
          VotingForm.showResolutionsForm()
        }
      })
    })
  },

  voter_company_name_on_change:  () => {
    if (form.querySelector('#voter_company_name').value.length > 0) {
      form.querySelector('#js-legal-documents').style.display = 'block'
    } else {
      form.querySelector('#js-legal-documents').style.display = 'none'
      form.querySelector('#js-legal-documents input').value = ''
    }
  },

  change_attachment_event_listener:  () => {
    document.querySelectorAll('.js-change-attachment').forEach(function(btn) {
      btn.addEventListener("click", (e) => {
        e.preventDefault()

        btn.closest('.js-attachment-current-value').style.display = 'none'
        btn.closest('.form-group').querySelector('.js-attachment-input').style.display = 'block'
        btn.closest('.form-group').querySelector('input').disabled = false
      })
    })
  },

  loadVRSlider: () => {
    if(liveVRSlides && liveTotalVRNumber && form.classList.contains('live-voting-form')){
      liveVRSlides.forEach(function(slide, i){
        slide.dataset.vrStep = i + 1
      })
      //init Voting Resolution variables
      VotingForm.prevVRSliderStep = 1
      VotingForm.nextVRSliderStep = 2
      VotingForm.currentVRSliderStep = 1
      VotingForm.maxVRSliderSteps = liveVRSlides.length
      VotingForm.total_voting_resolutions = parseInt((liveTotalVRNumber.innerHTML))
      VotingForm.toggleVRSliderInfo()
      VotingForm.checkMobile()
    }
  },

  toggleVRSliderInfo: () => {
    if (liveVotingResolutionInfo){
      if (VotingForm.currentStep == 1 && (VotingForm.currentVRSliderStep <= VotingForm.total_voting_resolutions)) {
        liveVotingResolutionInfo.classList.remove('invisible')
        liveVotingResolutionInfo.innerHTML = VotingForm.currentVRSliderStep + " / " + VotingForm.total_voting_resolutions
        //at resolutions step, move navigation from bottom to top
        liveVotingFormNaviMobile.style.display = 'block'
        votingFormMainNavi.style.display = 'none'
      }else{
        //at signature step, move navigation from top to bottom
        liveVotingResolutionInfo.classList.add('invisible')
        liveVotingFormNaviMobile.style.display = 'none'
        votingFormMainNavi.style.display = 'block'
      }
    }
  },

  nextVRSlide: () => {
    if (VotingForm.currentVRSliderStep < VotingForm.maxVRSliderSteps){
      VotingForm.prevVRSliderStep = VotingForm.currentVRSliderStep
      VotingForm.currentVRSliderStep = VotingForm.nextVRSliderStep
      VotingForm.nextVRSliderStep += 1
    }
    VotingForm.toggleVRSliderStep('next')
    VotingForm.toggleBackButton()
    VotingForm.toggleNextButton()
    VotingForm.toggleVRSliderInfo()
  },

  prevVRSlide: () => {
    if (VotingForm.currentVRSliderStep > 1){
      VotingForm.nextVRSliderStep = VotingForm.currentVRSliderStep
      VotingForm.currentVRSliderStep = VotingForm.prevVRSliderStep
      if (VotingForm.prevVRSliderStep > 1){
        VotingForm.prevVRSliderStep -= 1
      }
    }
    VotingForm.toggleVRSliderStep('prev')
    VotingForm.toggleBackButton()
    VotingForm.toggleNextButton()
    VotingForm.toggleVRSliderInfo()
  },

  toggleVRSliderStep: (action) => {
    // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
    // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
    if(liveVotingFormNaviMobile){
      liveVotingFormNaviMobile.querySelector('span.back-text').innerHTML = 'PREVIOUS'
    }
    let current = form.querySelector('.vr-slide[data-vr-step="' + VotingForm.currentVRSliderStep + '"]')
    let previous = form.querySelector('.vr-slide[data-vr-step="' + VotingForm.prevVRSliderStep + '"]')
    let next = form.querySelector('.vr-slide[data-vr-step="' + VotingForm.nextVRSliderStep + '"]')
    if (action == 'next') {
      previous.classList.remove('slide-in')
      previous.classList.remove('hidden_right')
      previous.classList.add('hidden_left')
    }else if (action == 'prev'){
      if (VotingForm.prevVRSliderStep != VotingForm.currentVRSliderStep){
        next.classList.remove('slide-in')
        next.classList.remove('hidden_left')
        next.classList.add('hidden_right')
      }else{
        //if prev and current is same, roll back to first slide and hide all other slides
        let inactiveSlides = form.querySelectorAll('.vr-slide:not([data-vr-step="' + VotingForm.currentVRSliderStep + '"])')
        inactiveSlides.forEach(function(slide){
          slide.classList.remove('slide-in')
          slide.classList.remove('hidden_left')
          slide.classList.add('hidden_right')
        })
      }
    }
    current.classList.add('slide-in')
    if (liveVotingFormBody){
      liveVotingFormBody.scrollTop = 0
    }
  },

  checkMobile: () => {
    ['resize','load'].forEach( evt =>
      window.addEventListener(evt, function(){
        if (window.matchMedia("(orientation: portrait) and (max-width: 575px)").matches || window.matchMedia("(orientation: landscape) and (min-device-width: 320px) and (max-device-width: 926px) and (-webkit-min-device-pixel-ratio: 2)").matches){
          VotingForm.isMobile = true
          //make sure liveVotingFormNaviMobile and votingFormMainNavi is hidden in mobile in VotingForm Step 1
          if (VotingForm.currentStep == 1){
            if (VotingForm.currentVRSliderStep > 1){
              votingFormMainNavi.querySelector('button.step_back').style.display = 'inline-block'
              votingFormMainNavi.querySelector('#backSpinner').style.display = 'none'
              votingFormMainNavi.querySelector('button.step_back').disabled = false
            }
            // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
            // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
            if(liveVotingFormNaviMobile){
              if (VotingForm.currentVRSliderStep <= VotingForm.total_voting_resolutions){
                //move form navigation to top at step 1 resolutions - requested by DH
                //instead of moving the navigation up and down, just show/hide the mobile(top) and desktop(bottom) navigation
                liveVotingFormNaviMobile.style.display = 'block'
                votingFormMainNavi.style.display = 'none'
              }else{
                //move form navigation to bottom at step 1 signature and step 2 reviews - requested by DH
                //instead of moving the navigation up and down, just show/hide the mobile(top) and desktop(bottom) navigation
                liveVotingFormNaviMobile.style.display = 'none'
                votingFormMainNavi.style.display = 'block'
              }
              liveVotingFormNaviMobile.querySelector('button.step_back').style.display = 'inline-block'
              liveVotingFormNaviMobile.querySelector('#backSpinner').style.display = 'none'
              liveVotingFormNaviMobile.querySelector('button.step_back').disabled = false
              liveVotingFormNaviMobile.querySelector('span.back-text').innerHTML = 'PREVIOUS'
              liveVotingFormNaviMobile.querySelector('span.submit-text').innerHTML = 'NEXT'
            }
          }else{
            //move form navigation to bottom at step 1 signature and step 2 review - requested by DH
            //instead of moving the navigation up and down, just show/hide the mobile(top) and desktop(bottom) navigation
            liveVotingFormNaviMobile.style.display = 'none'
            votingFormMainNavi.style.display = 'block'
          }
        }else{
          if (liveVotingFormNaviMobile){
            //Dekstop screen: hide mobile nav and show desktop nav
            liveVotingFormNaviMobile.style.display = 'none'
            votingFormMainNavi.style.display = 'block'
          }
          VotingForm.isMobile = false
          //make sure liveVotingFormNaviMobile and liveVotingFormResolutionNaviMobile is hidden in desktop in VotingForm Step 1
          if (VotingForm.currentStep == 1){
            votingFormMainNavi.querySelector('button.step_back').style.display = 'none'
            votingFormMainNavi.querySelector('button.step_back').disabled = true
            // _liveVotingFormNaviMobile_ is only dedicated for 'live voting form' since it has multiple render of navigation buttons due to complex UI structures for 'portrait', 'landscape', 'desktop'.
            // This condition checking prevent changes reflected or errors happen in 'online proxy' form and 'discretionary form'.
            if(liveVotingFormNaviMobile){
              liveVotingFormNaviMobile.querySelector('button.step_back').style.display = 'none'
              liveVotingFormNaviMobile.querySelector('button.step_back').disabled = true
              liveVotingFormNaviMobile.querySelector('span.back-text').innerHTML = 'PREVIOUS'
              liveVotingFormNaviMobile.querySelector('span.submit-text').innerHTML = 'NEXT'
            }
          }
        }
      })
    )
  },
}

document.addEventListener("turbolinks:load", () => {
  if(form) {
    VotingForm.init()
  }
})
