var PageRegistrationForm;
if (typeof PageRegistrationForm === 'undefined') PageRegistrationForm = {};

const registration_form = document.querySelector('#registration-form')
const registration_success = document.querySelector('#registration_success')
PageRegistrationForm = {
  current_registration_type: '',
  init: () => {

    if (!!document.querySelector('input[name="registration[registration_type]"]')) {
      let reg_type_selector = document.querySelector('input[name="registration[registration_type]"]:checked')
      if (reg_type_selector) {
        PageRegistrationForm.current_registration_type = reg_type_selector.value
      }
      PageRegistrationForm.registrationTypeEventListeners()
    }

    PageRegistrationForm.attendanceFieldsSetup()
    PageRegistrationForm.numberOfSharesSetup()
    PageRegistrationForm.change_attachment_event_listener()
  },

  registrationTypeEventListeners: () => {
    if (PageRegistrationForm.current_registration_type) {
      PageRegistrationForm.toggleDisplayRegFormVoterFields()
    }

    document.querySelectorAll('input[name="registration[registration_type]"]').forEach(function(elem) {
      elem.addEventListener("change", (e) => {
        e.preventDefault()

        let old_reg_type_value = PageRegistrationForm.current_registration_type
        let new_reg_type_value = e.currentTarget.value
        if ((old_reg_type_value == 'individual_shareholder' || old_reg_type_value == 'corporate_shareholder') &&
          new_reg_type_value != 'individual_shareholder' &&
          new_reg_type_value != 'corporate_shareholder') {

          if (!!document.querySelector("#regTypeUpdateConfirmModal")) {
            $("#regTypeUpdateConfirmModal").modal('show')
          } else {
            PageRegistrationForm.current_registration_type = new_reg_type_value
            PageRegistrationForm.toggleDisplayRegFormVoterFields()
            PageRegistrationForm.resetValuesOnRegistrationTypeChange()
          }
        } else {
          PageRegistrationForm.current_registration_type = new_reg_type_value
          PageRegistrationForm.toggleDisplayRegFormVoterFields()
          PageRegistrationForm.resetValuesOnRegistrationTypeChange()
        }
      })
    })

    if(!!document.querySelector("#regTypeUpdateConfirmModal")) {
      document.querySelector('#regTypeUpdateConfirmBtn').addEventListener("click", (e) => {
        e.preventDefault()

        PageRegistrationForm.current_registration_type = document.querySelector('input[name="registration[registration_type]"]:checked').value;
        PageRegistrationForm.toggleDisplayRegFormVoterFields()
        PageRegistrationForm.resetValuesOnRegistrationTypeChange()
      });

      document.querySelector('#regTypeUpdateCancelBtn').addEventListener("click", (e) => {
        e.preventDefault()

        document.querySelectorAll('input[name="registration[registration_type]"]').forEach(function(elem) {
          elem.checked = false;
        })

        document.querySelector('#registration-form-field-registration-type-'+PageRegistrationForm.current_registration_type).checked = true
        PageRegistrationForm.toggleDisplayRegFormVoterFields()
      });
    }
  },

  attendanceFieldsSetup: () => {
    let proxy_form_txn_number = "N/A"
    if (!!document.querySelector('#remarks-confirm-withdraw-proxy-form')) { // if element exists
      proxy_form_txn_number = document.querySelector('#remarks-confirm-withdraw-proxy-form').innerHTML;
      document.querySelector('#remarks-confirm-withdraw-proxy-form').style.display = "none";
    }

    let confirmWithdrawProxyFormModalExists = !!document.querySelector('#confirmWithdrawProxyFormModal');
    if (confirmWithdrawProxyFormModalExists) {
      if (!!document.querySelector('#btnConfirmWithdrawProxyForm')) { // if element exists
        document.querySelector('#btnConfirmWithdrawProxyForm').addEventListener("click", (e) => {
          e.preventDefault()

          if (!!document.querySelector('#registration-form-field-confirm-withdraw-proxy-form')) { // if element exists
            document.querySelector('#registration-form-field-confirm-withdraw-proxy-form').value = 'YES';
          }

          if (!!document.querySelector('#remarks-confirm-withdraw-proxy-form')) { // if element exists
            document.querySelector('#remarks-confirm-withdraw-proxy-form').innerHTML = " - Proxy Form " + proxy_form_txn_number + " will be withdrawn.";
            document.querySelector('#remarks-confirm-withdraw-proxy-form').style.display = "block";
          }
        });
      }

      if (!!document.querySelector('#btnDoNotWithdrawProxyForm')) { // if element exists
        document.querySelector('#btnDoNotWithdrawProxyForm').addEventListener("click", (e) => {
          e.preventDefault()

          if (!!document.querySelector('#registration-form-field-confirm-withdraw-proxy-form')) { // if element exists
            document.querySelector('#registration-form-field-confirm-withdraw-proxy-form').value = 'NO';
          }

          if (!!document.querySelector('#remarks-confirm-withdraw-proxy-form')) { // if element exists
            document.querySelector('#remarks-confirm-withdraw-proxy-form').innerHTML = " - Proxy Form " + proxy_form_txn_number + " will not be withdrawn.";
            document.querySelector('#remarks-confirm-withdraw-proxy-form').style.display = "block";
          }
        });
      }
    }

    let attendance_fields = document.querySelectorAll(`[id^="registration-form-field-is-attending-"]`);
    attendance_fields.forEach(function(elem) {
      elem.addEventListener("change", (e) => {
        e.preventDefault()

        let isAttendingYesChecked = document.querySelector('input#registration_is_attending_true.field_isattending.form-check-input').checked;
        if (!!document.querySelector('#registration-form-field-confirm-withdraw-proxy-form')) { // if element exists
          if (isAttendingYesChecked) {
            document.querySelector('#registration-form-field-confirm-withdraw-proxy-form').value = 'YES';
          } else {
            document.querySelector('#registration-form-field-confirm-withdraw-proxy-form').value = 'NO';
          }
        }

        if (!!document.querySelector('#remarks-confirm-withdraw-proxy-form')) { // if element exists
          // reset remarks
          document.querySelector('#remarks-confirm-withdraw-proxy-form').innerHTML = "N/A";
          document.querySelector('#remarks-confirm-withdraw-proxy-form').style.display = "none";
        }

        if (isAttendingYesChecked && confirmWithdrawProxyFormModalExists) { // if element exists
          $("#confirmWithdrawProxyFormModal").modal('show');
        }
      });
    });
  },

  numberOfSharesSetup: () => {
    if (document.querySelector('body.edit #registration-form-field-number-of-shares') && document.querySelector('#numberOfSharesModalTrigger')){
      let numberOfSharesSelector= document.querySelector('body.edit #registration-form-field-number-of-shares')
      let numberOfShares = numberOfSharesSelector.value

      document.querySelector('#numberOfSharesModalTrigger').addEventListener("click", function(e) {
        if(parseInt(numberOfShares) != parseInt(numberOfSharesSelector.value) &&
          (PageRegistrationForm.current_registration_type == 'individual_shareholder' ||
          PageRegistrationForm.current_registration_type == 'corporate_shareholder')) {
          e.preventDefault();

          let msg = 'If you submitted a Proxy Form, changing your "Total Number of Shares Held" will reset your Proxy Form. Kindly resubmit your Proxy Form.'
          document.querySelector('#numberOfSharesModal .modal-body').innerHTML = msg
          $('#numberOfSharesModal').modal('show')
        } else {
          document.getElementById('btn-registerSubmit').click()
        }
      });
    }
  },

  toggleDisplayRegFormVoterFields: () => {
    if(!!document.querySelector('.js-shareholder-info')) {
      document.querySelector('.js-shareholder-info').style.display = 'block'
    }
    if(!!document.querySelector('.js-additional-questions-and-submit-button')) {
      document.querySelector('.js-additional-questions-and-submit-button').style.display = 'block'
    }

    if (PageRegistrationForm.current_registration_type == 'individual_shareholder') {
      document.querySelector('#js-personal-info-title-prefix').innerHTML = 'Shareholder'
      document.querySelector('#registration_address').setAttribute("required", "required")
      document.querySelector('label[for="registration_address').classList.add('required')
      document.querySelector('.js-company-name').style.display = 'none'
      document.querySelector('#registration_company_name').removeAttribute("required")
      document.querySelector('label[for="registration_company_name').classList.remove('required')
      document.querySelector('#company_attachment_div').style.display = 'none'
      document.querySelector('#reg-form-company-attachment').removeAttribute("required")
      document.querySelector('label[for="registration_company_attachment').classList.remove('required')
      document.querySelector('#registration-form-field-shareholding-type').disabled = false
      document.querySelector('#registration-form-field-number-of-shares').disabled = false
      document.querySelector('#registration-form-voter-fields-group').style.display = "block";
    } else if (PageRegistrationForm.current_registration_type == 'corporate_shareholder') {
      document.querySelector('#js-personal-info-title-prefix').innerHTML = 'Shareholder'
      document.querySelector('#registration_address').setAttribute("required", "required")
      document.querySelector('label[for="registration_address').classList.add('required')
      document.querySelector('.js-company-name').style.display = 'block'
      document.querySelector('#registration_company_name').setAttribute("required", "required")
      document.querySelector('label[for="registration_company_name').classList.add('required')
      document.querySelector('#company_attachment_div').style.display = 'block'
      document.querySelector('#reg-form-company-attachment').setAttribute("required", "required")
      document.querySelector('label[for="registration_company_attachment').classList.add('required')
      document.querySelector('#registration-form-field-shareholding-type').disabled = false
      document.querySelector('#registration-form-field-number-of-shares').disabled = false
      document.querySelector('#registration-form-voter-fields-group').style.display = "block";
    } else {
      document.querySelector('#js-personal-info-title-prefix').innerHTML = 'Personal'
      document.querySelector('#registration_address').removeAttribute("required");
      document.querySelector('label[for="registration_address').classList.remove('required')
      document.querySelector('.js-company-name').style.display = 'none'
      document.querySelector('#registration_company_name').removeAttribute("required")
      document.querySelector('label[for="registration_company_name').classList.remove('required')
      document.querySelector('#company_attachment_div').style.display = 'none'
      document.querySelector('#reg-form-company-attachment').removeAttribute("required")
      document.querySelector('label[for="registration_company_attachment').classList.remove('required')
      document.querySelector('#registration-form-field-shareholding-type').disabled = true
      document.querySelector('#registration-form-field-number-of-shares').disabled = true
      document.querySelector('#registration-form-voter-fields-group').style.display = "none";
    }
  },

  change_attachment_event_listener:  () => {
    document.querySelectorAll('.js-change-attachment').forEach(function(btn) {
      btn.addEventListener("click", (e) => {
        e.preventDefault()

        btn.closest('.js-attachment-current-value').style.display = 'none'
        btn.closest('.form-group').querySelector('.js-attachment-input').style.display = 'block'
        btn.closest('.form-group').querySelector('input').disabled = false
      })
    })
  },

  resetValuesOnRegistrationTypeChange: () => {
    document.querySelector('#registration_company_name').value = ''
    document.querySelector('#registration-form-field-shareholding-type').value = ''
    document.querySelector('#registration-form-field-number-of-shares').value = ''
    document.querySelector('#registration_is_attending_false').checked = false
    document.querySelector('#registration_is_attending_true').checked = true
  }
}

document.addEventListener("turbolinks:load", () => {
  if (registration_form){
    PageRegistrationForm.init()
  }
  //scroll to bottom of the page at registration success
  if (registration_success){
    setTimeout(function(){
      window.scrollTo(0, document.body.scrollHeight)
    },500)
  }
});