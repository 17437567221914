const bodyContainer = document.querySelector('body.live_voting_forms')

document.addEventListener("turbolinks:load", () => {
  if(bodyContainer) {
    // TODO remove once datatable is fixed and autorefreshing
    if(document.querySelector("#js-live-voting-forms-table")) {
      setTimeout(function(){
        window.location.reload(1);
      }, 5000);
    }
  }
})
