var NewEventFormLayout;
if (typeof NewEventFormLayout === 'undefined') NewEventFormLayout = {};

const event_form_new = document.querySelector('#event-form')
const event_type_container = document.querySelector('#event_new_event_event_type')
const gm_type_container = document.querySelector('#event_new_event_gm_type')
const voting_type_container = document.querySelector('#event_new_event_voting_type')
const event_default_container = document.querySelector('#event_new_event_default')

var event_type, gm_type, voting_type, voting_computation_method, voting_method
var currentStep

const btn_back = document.querySelector("#js-event-new-back")
const btn_next = document.querySelector("#js-event-new-next")
const parent_id_select = document.querySelector("select#event_parent_id")

NewEventFormLayout = {
  init: () => {
    currentStep = event_type_container
    btn_back.classList.add('d-none')
    btn_next.classList.add('disabled')
    NewEventFormLayout.setupForm()
    NewEventFormLayout.checkValidity(currentStep)
    NewEventFormLayout.updateNav()
    //add animation class in init to prevent css animation from animating before form fully loaded
    event_form_new.classList.add('animate')
  },

  setupForm: () =>{
    btn_next.addEventListener('click', function(e){
      e.preventDefault()
      if (btn_next.classList.contains('disabled')){
        return false
      }
      switch (currentStep.dataset.step){
        case "1":
          if (event_type && (event_type.value != 'others' && event_type.value != 'ar_registration')){
            NewEventFormLayout.slideTo(gm_type_container)
          }else{
            NewEventFormLayout.slideTo(event_default_container)
          }
          break;
        case "2":
          if (event_type && (event_type.value == 'gm_registration_proxy_evote' || event_type.value == 'gm_registration_proxy')){
            NewEventFormLayout.slideTo(voting_type_container)
          }else{
            NewEventFormLayout.slideTo(event_default_container)
          }
          break;
        default:
          NewEventFormLayout.slideTo(event_default_container)
          break;
      }
    })

    btn_back.addEventListener('click', function(e){
      e.preventDefault()
      switch (currentStep.dataset.step){
        case "2":
          NewEventFormLayout.slideTo(event_type_container)
          break;
        case "3":
          NewEventFormLayout.slideTo(gm_type_container)
          break;
        case "4":
          if (event_type.value != 'others' && event_type.value != 'ar_registration' ){
            if (event_type.value == 'gm_registration_proxy_evote' || event_type.value == 'gm_registration_proxy' ){
              NewEventFormLayout.slideTo(voting_type_container)
            }else{
              NewEventFormLayout.slideTo(gm_type_container)
            }
          }else{
            NewEventFormLayout.slideTo(event_type_container)
          }
          break;
      }
    })

    //add listener to all radios to check validity
    event_type_container.querySelectorAll("input[type='radio']").forEach((element) => {
      element.addEventListener('change', function(e){
        NewEventFormLayout.checkValidity(event_type_container)
      })
    })
    gm_type_container.querySelectorAll("input[type='radio']").forEach((element) => {
      element.addEventListener('change', function(e){
        NewEventFormLayout.checkValidity(gm_type_container)
      })
    })
    voting_type_container.querySelectorAll("input[type='radio']").forEach((element) => {
      element.addEventListener('change', function(e){
        NewEventFormLayout.checkValidity(voting_type_container)
      })
    })

    if(parent_id_select.length > 0) {
      NewEventFormLayout.parentIdHideInputs(parent_id_select)
      parent_id_select.addEventListener('change', function(e) {
        NewEventFormLayout.parentIdHideInputs(this)
      })
    }
  },

  updateNav: () => {
    currentStep = document.querySelector(".event_form_steps.slide-in")
    if (currentStep){
      if(currentStep.dataset.step == "1") {
        btn_back.classList.add("d-none")
      }else{
        btn_back.classList.remove("d-none")
      }
      if(currentStep.dataset.step == "4"){
        btn_next.classList.add("d-none")
      }else{
        btn_next.classList.remove("d-none")
      }
    }else{
      currentStep =  event_type_container
      btn_back.classList.add("d-none")
      btn_next.classList.remove("d-none")
    }
  },

  //check :checked and reset unused question
  checkValidity: (currentStep) =>{
    event_type = document.querySelector("input[name='event[event_type]']:checked")
    gm_type = document.querySelector("input[name='event[gm_type]']:checked")
    voting_type = document.querySelector("input[name='event[voting_type]']:checked")
    voting_computation_method = document.querySelector("input[name='event[voting_computation_method]']:checked")
    voting_method = document.querySelector("input[name='event[voting_method]']:checked")

    switch (currentStep.dataset.step){
      case "1":
        if (event_type){
          if (event_type.value == 'ar_registration' || event_type.value == 'others'){
            if (gm_type) gm_type.checked = false
          }
          if (event_type.value != 'gm_registration_proxy_evote' && event_type.value != 'gm_registration_proxy' ){
            if (voting_type) voting_type.checked = false
            if (voting_computation_method) voting_computation_method.checked = false
            if (voting_method) voting_method.checked = false
          }
          btn_next.classList.remove('disabled')
        }else{
          btn_next.classList.add('disabled')
        }
        break;
      case "2":
        if (gm_type){
          btn_next.classList.remove('disabled')
        }else{
          btn_next.classList.add('disabled')
        }
        break;
      case "3":
        if (event_type.value == 'gm_registration_proxy_evote'){
          if (voting_type && voting_computation_method && voting_method){
            btn_next.classList.remove('disabled')
          }else{
            btn_next.classList.add('disabled')
          }
        }else if (event_type.value == 'gm_registration_proxy'){
          if (voting_type && voting_computation_method){
            btn_next.classList.remove('disabled')
          }else{
            btn_next.classList.add('disabled')
          }
        }else{
          if (gm_type) gm_type.checked = false
          if (voting_type) voting_type.checked = false
          if (voting_computation_method) voting_computation_method.checked = false
          if (voting_method) voting_method.checked = false
          btn_next.classList.add('disabled')
        }
        break;
    }
    if (event_type) NewEventFormLayout.updateFormType(event_type.value)
  },

  //hide unrelated fields according to event_type
  updateFormType: (type = 'ar_registration') => {
    switch (type) {
      case "gm_registration_webcast":
        document.querySelector("#event_new_form_parent").classList.add("d-none")
        document.querySelector("#event_new_form_quiz").classList.add("d-none")
        document.querySelector("#event_new_form_row_speaker").classList.remove("d-none")
        document.querySelector("#event_new_form_row_trial_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_row_proxy_datetime").classList.add("d-none")
        document.querySelector("#event_new_form_col_weblink_email_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_col_contact_card").classList.remove("d-none")
        document.querySelector("#event_new_form_row_login_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_row_reminder").classList.remove("d-none")
        document.querySelector("#event_new_form_section_webcast").classList.remove("d-none")
        document.querySelector("#event_new_last_step").innerHTML = "3.";
        break;
      case "gm_registration_proxy":
        document.querySelector("#event_voting_type").classList.remove("d-none")
        document.querySelector("#event_voting_method").classList.add("d-none")
        document.querySelector("#event_new_form_parent").classList.add("d-none")
        document.querySelector("#event_new_form_quiz").classList.add("d-none")
        document.querySelector("#event_new_form_row_speaker").classList.remove("d-none")
        document.querySelector("#event_new_form_row_trial_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_row_proxy_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_col_weblink_email_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_col_contact_card").classList.remove("d-none")
        document.querySelector("#event_new_form_row_login_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_row_reminder").classList.remove("d-none")
        document.querySelector("#event_new_form_section_webcast").classList.remove("d-none")
        document.querySelector("#event_new_last_step").innerHTML = "4.";
        break;
      case "gm_registration_proxy_evote":
        document.querySelector("#event_voting_type").classList.remove("d-none")
        document.querySelector("#event_voting_method").classList.remove("d-none")
        document.querySelector("#event_new_form_parent").classList.add("d-none")
        document.querySelector("#event_new_form_quiz").classList.add("d-none")
        document.querySelector("#event_new_form_row_speaker").classList.remove("d-none")
        document.querySelector("#event_new_form_row_trial_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_row_proxy_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_col_weblink_email_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_col_contact_card").classList.remove("d-none")
        document.querySelector("#event_new_form_row_login_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_row_reminder").classList.remove("d-none")
        document.querySelector("#event_new_form_section_webcast").classList.remove("d-none")
        document.querySelector("#event_new_last_step").innerHTML = "4.";
        break;
      case "others":
        document.querySelector("#event_new_form_parent").classList.add("d-none")
        document.querySelector("#event_new_form_quiz").classList.remove("d-none")
        document.querySelector("#event_new_form_row_speaker").classList.remove("d-none")
        document.querySelector("#event_new_form_row_trial_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_row_proxy_datetime").classList.add("d-none")
        document.querySelector("#event_new_form_col_weblink_email_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_col_contact_card").classList.remove("d-none")
        document.querySelector("#event_new_form_row_login_datetime").classList.remove("d-none")
        document.querySelector("#event_new_form_row_reminder").classList.remove("d-none")
        document.querySelector("#event_new_form_section_webcast").classList.remove("d-none")
        document.querySelector("#event_new_last_step").innerHTML = "2.";
        break;
      case "gm_registration":
        document.querySelector("#event_new_form_parent").classList.add("d-none")
        document.querySelector("#event_new_form_quiz").classList.add("d-none")
        document.querySelector("#event_new_form_row_speaker").classList.add("d-none")
        document.querySelector("#event_new_form_row_trial_datetime").classList.add("d-none")
        document.querySelector("#event_new_form_row_proxy_datetime").classList.add("d-none")
        document.querySelector("#event_new_form_col_weblink_email_datetime").classList.add("d-none")
        document.querySelector("#event_new_form_col_contact_card").classList.add("d-none")
        document.querySelector("#event_new_form_row_login_datetime").classList.add("d-none")
        document.querySelector("#event_new_form_row_reminder").classList.add("d-none")
        document.querySelector("#event_new_form_section_webcast").classList.add("d-none")
        document.querySelector("#event_new_last_step").innerHTML = "3.";
        break;
      case "ar_registration":
      default:
        document.querySelector("#event_new_form_parent").classList.add("d-none")
        document.querySelector("#event_new_form_quiz").classList.add("d-none")
        document.querySelector("#event_new_form_row_speaker").classList.add("d-none")
        document.querySelector("#event_new_form_row_trial_datetime").classList.add("d-none")
        document.querySelector("#event_new_form_row_proxy_datetime").classList.add("d-none")
        document.querySelector("#event_new_form_col_weblink_email_datetime").classList.add("d-none")
        document.querySelector("#event_new_form_col_contact_card").classList.add("d-none")
        document.querySelector("#event_new_form_row_login_datetime").classList.add("d-none")
        document.querySelector("#event_new_form_row_reminder").classList.add("d-none")
        document.querySelector("#event_new_form_section_webcast").classList.add("d-none")
        document.querySelector("#event_new_last_step").innerHTML = "2.";
        break;
    }
  },

  //hide unrelated fields if parent_id present
  parentIdHideInputs: (parent_id) => {
    if(parent_id.value.length > 0){
      // hide
      document.querySelector('#event_new_form_quiz').classList.add("d-none")
      document.querySelector('#event_new_form_venue').classList.add("d-none")
      document.querySelector('#event_new_form_admin_info').classList.add("d-none")
      document.querySelector('#event_new_form_row_trial_datetime').classList.add("d-none")
      document.querySelector('#event_new_form_row_registration_datetime').classList.add("d-none")
      document.querySelector('#event_new_form_row_proxy_datetime').classList.add("d-none")
      document.querySelector('#event_new_form_row_login_datetime').classList.add("d-none")
      document.querySelector('#event_new_form_col_weblink_email_datetime').classList.add("d-none")
      document.querySelector('#event_new_form_col_contact_card').classList.add("d-none")
      document.querySelector('#event_new_form_row_reminder').classList.add("d-none")
      document.querySelector('#event_new_form_add_question').classList.add("d-none")
    }else {
      // show
      document.querySelector('#event_new_form_quiz').classList.remove("d-none")
      document.querySelector('#event_new_form_venue').classList.remove("d-none")
      document.querySelector('#event_new_form_admin_info').classList.remove("d-none")
      document.querySelector('#event_new_form_row_trial_datetime').classList.remove("d-none")
      document.querySelector('#event_new_form_row_registration_datetime').classList.remove("d-none")
      document.querySelector('#event_new_form_row_proxy_datetime').classList.remove("d-none")
      document.querySelector('#event_new_form_row_login_datetime').classList.remove("d-none")
      document.querySelector('#event_new_form_col_weblink_email_datetime').classList.remove("d-none")
      document.querySelector('#event_new_form_col_contact_card').classList.remove("d-none")
      document.querySelector('#event_new_form_row_reminder').classList.remove("d-none")
      document.querySelector('#event_new_form_add_question').classList.remove("d-none")
    }
  },

  slideTo: (targetSlide) => {
    if (currentStep.dataset.step > targetSlide.dataset.step){
      currentStep.classList.add('hidden_right')
    }else{
      currentStep.classList.add('hidden_left')
    }
    currentStep.classList.remove('slide-in')
    targetSlide.classList.add('slide-in')
    targetSlide.classList.remove('hidden_right')
    targetSlide.classList.remove('hidden_left')
    currentStep = targetSlide
    NewEventFormLayout.checkValidity(currentStep)
    NewEventFormLayout.updateNav()
  }
}
document.addEventListener("turbolinks:load", () => {
  if (event_form_new){
    NewEventFormLayout.init()
  }
});