const shareholderAccountsForm = document.querySelector('#js-shareholder-account-form')

document.addEventListener("turbolinks:load", () => {
	if(shareholderAccountsForm){
    let parentIDDom = document.querySelector('#shareholder_account_parent_id')

    if (document.querySelector('#shareholder_account_source').value == 'custodian') {
      $('#shareholder_account_parent_id').select2()
    }

    document.querySelector('#shareholder_account_source').addEventListener('change', function(e) {
      if(e.currentTarget.value == 'custodian') {
        parentIDDom.value = ''
        parentIDDom.closest('div.row').style.display = 'block'
        $('#shareholder_account_parent_id').select2()
      } else {
        parentIDDom.closest('div.row').style.display = 'none'
        parentIDDom.value = ''
      }
    })
	}
})

