document.addEventListener("turbolinks:load", () => {
  flatpickr(".flatpickr", {
    dateFormat: "d/m/Y"
  })

  flatpickr(".flatpickr-datetime", {
    enableTime: true,
    dateFormat: "d/m/Y h:i K"
  })

  flatpickr(".flatpickr-timeonly", {
    noCalendar: true,
    enableTime: true,
    dateFormat: 'h:i K'
  })

  $("[data-form-prepend]").click(function(e) {
    var obj = $($(this).attr("data-form-prepend"));
    obj.find("input, select, textarea").each(function() {
      $(this).attr("name", function() {
        return $(this)
          .attr("name")
          .replace("new_record", new Date().getTime());
      });
    });
    obj.insertBefore(this);
    return false;
  });

  if ($(".delete-question")) {
    $('body').on('click', '.delete-question', function(e) {
      $(this).closest('div.form-group').find('[type=checkbox]').prop( "checked", true );
      $(this).closest('div.event-question-form').hide();
      return false;
    });
  }
})
