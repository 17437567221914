import axios from 'axios'

var PageVotersVotingFormTracking;
if (typeof PageVotersVotingFormTracking === 'undefined') PageVotersVotingFormTracking = {};

const bodyContainer = document.querySelector('body.voting_forms_tracking')
const lastUpdatedContainer = document.querySelector('#js-vft-last-updated')

var totalSharesInput, totalSharesholdersInput, votersTotalSharesSelector, votersTotalSelector, btnTotalSharesInput, btnTotalSharesholdersInput, btnSetNumberOfShares, totalSharesLabel, totalSharesholdersLabel

PageVotersVotingFormTracking = {
  init: () => {
    let secondsTimerText = lastUpdatedContainer.getAttribute('data-auto-refresh-seconds')
    let currentTimeInSecondsText = lastUpdatedContainer.getAttribute('data-current-time')

    if (currentTimeInSecondsText && secondsTimerText) {
      let worker = require('worker-loader!./timer-worker.js')
      let timerWorker = new worker()
      let currentTimeInSeconds = parseInt(currentTimeInSecondsText)
      let secondsTimer = parseInt(secondsTimerText)

      PageVotersVotingFormTracking.requestData(currentTimeInSeconds)
      timerWorker.postMessage([currentTimeInSeconds, secondsTimer])
      timerWorker.onmessage = function(e) {
        PageVotersVotingFormTracking.requestData(e.data)
      }
    }

    PageVotersVotingFormTracking.setTotalSharesInput()
    PageVotersVotingFormTracking.setTotalSharesholdersInput()
  },

  requestData: (currentTimeInSeconds) => {
    // console.log('last updated: ' + currentTimeInSeconds)

    let eventID = lastUpdatedContainer.getAttribute("data-event-id")
    let dataObj = {
      method: 'GET',
      responseType: 'json',
      headers: {
        'Accept': 'application/json'
      },
      url: '/events/' + eventID + '/voters/voting_forms_tracking'
    }

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data) {
          // success
          PageVotersVotingFormTracking.showData(response.data)
        }
      })
      .catch(error => console.error('catch',error))
  },

  showData: (data) => {
    PageVotersVotingFormTracking.updateCountTable(data)
    PageVotersVotingFormTracking.updateResultsTable(data)
    PageVotersVotingFormTracking.updateVotersTable(data)
    lastUpdatedContainer.innerHTML = 'Last updated at ' + data['last_updated']
  },

  updateCountTable: (data) => {
    let loggedInMembersCount = bodyContainer.querySelector('#js-vft-logged-in-members-count')
    let loggedInProxiesCount = bodyContainer.querySelector('#js-vft-logged-in-proxies-count')
    let loggedInTotalCount = bodyContainer.querySelector('#js-vft-logged-in-total-count')
    let votingFormMembersCount = bodyContainer.querySelector('#js-vft-voting-form-members-count')
    let votingFormProxiesCount = bodyContainer.querySelector('#js-vft-voting-form-proxies-count')
    let votingFormTotalCount = bodyContainer.querySelector('#js-vft-voting-form-total-count')
    let voterMembersCount = bodyContainer.querySelector('#js-vft-voter-members-count')
    let voterProxiesCount = bodyContainer.querySelector('#js-vft-voter-proxies-count')
    let voterTotalCount = bodyContainer.querySelector('#js-vft-voter-total-count')
    let saMembersCount = bodyContainer.querySelector('#js-vft-sa-members-count')
    let saProxiesCount = bodyContainer.querySelector('#js-vft-sa-proxies-count')
    let saTotalCount = bodyContainer.querySelector('#js-vft-sa-total-count')
    let loggedInMembersShares = bodyContainer.querySelector('#js-vft-logged-in-members-shares')
    let loggedInProxiesShares = bodyContainer.querySelector('#js-vft-logged-in-proxies-shares')
    let loggedInTotalShares = bodyContainer.querySelector('#js-vft-logged-in-total-shares')
    let votingFormMembersShares = bodyContainer.querySelector('#js-vft-voting-form-members-shares')
    let votingFormProxiesShares = bodyContainer.querySelector('#js-vft-voting-form-proxies-shares')
    let votingFormTotalShares = bodyContainer.querySelector('#js-vft-voting-form-total-shares')
    let voterMembersShares = bodyContainer.querySelector('#js-vft-voter-members-shares')
    let voterProxiesShares = bodyContainer.querySelector('#js-vft-voter-proxies-shares')
    let voterTotalShares = bodyContainer.querySelector('#js-vft-voter-total-shares')
    let saMembersShares = bodyContainer.querySelector('#js-vft-sa-members-shares')
    let saProxiesShares = bodyContainer.querySelector('#js-vft-sa-proxies-shares')
    let saTotalShares = bodyContainer.querySelector('#js-vft-sa-total-shares')

    PageVotersVotingFormTracking.showCount(data['logged_in_members_count'], loggedInMembersCount)
    PageVotersVotingFormTracking.showCount(data['logged_in_proxies_count'], loggedInProxiesCount)
    PageVotersVotingFormTracking.showCount(data['logged_in_total_count'], loggedInTotalCount)
    PageVotersVotingFormTracking.showCount(data['voting_form_members_count'], votingFormMembersCount)
    PageVotersVotingFormTracking.showCount(data['voting_form_proxies_count'], votingFormProxiesCount)
    PageVotersVotingFormTracking.showCount(data['voting_form_total_count'], votingFormTotalCount)
    PageVotersVotingFormTracking.showCount(data['voter_members_count'], voterMembersCount)
    PageVotersVotingFormTracking.showCount(data['voter_proxies_count'], voterProxiesCount)
    PageVotersVotingFormTracking.showCount(data['voter_total_count'], voterTotalCount)
    PageVotersVotingFormTracking.showCount(data['shareholder_account_members_count'], saMembersCount)
    PageVotersVotingFormTracking.showCount(data['shareholder_account_proxies_count'], saProxiesCount)
    PageVotersVotingFormTracking.showCount(data['shareholder_account_total_count'], saTotalCount)
    PageVotersVotingFormTracking.showCount(data['logged_in_members_shares'], loggedInMembersShares)
    PageVotersVotingFormTracking.showCount(data['logged_in_proxies_shares'], loggedInProxiesShares)
    PageVotersVotingFormTracking.showCount(data['logged_in_total_shares'], loggedInTotalShares)
    PageVotersVotingFormTracking.showCount(data['voting_form_members_shares'], votingFormMembersShares)
    PageVotersVotingFormTracking.showCount(data['voting_form_proxies_shares'], votingFormProxiesShares)
    PageVotersVotingFormTracking.showCount(data['voting_form_total_shares'], votingFormTotalShares)
    PageVotersVotingFormTracking.showCount(data['voter_members_shares'], voterMembersShares)
    PageVotersVotingFormTracking.showCount(data['voter_proxies_shares'], voterProxiesShares)
    PageVotersVotingFormTracking.showCount(data['voter_total_shares'], voterTotalShares)
    PageVotersVotingFormTracking.showCount(data['shareholder_account_members_shares'], saMembersShares)
    PageVotersVotingFormTracking.showCount(data['shareholder_account_proxies_shares'], saProxiesShares)
    PageVotersVotingFormTracking.showCount(data['shareholder_account_total_shares'], saTotalShares)
  },

  showCount: (data, selector) => {
    if (selector) {
      // console.log(selector + ': ' + data)
      selector.innerHTML = data
    }
  },

  updateResultsTable: (data) => {
    PageVotersVotingFormTracking.showResult(
      data['live_evote_results_online'], '#js-vft-online-live-evote-result')
    PageVotersVotingFormTracking.showResult(
      data['live_evote_results_offline'], '#js-vft-offline-live-evote-result')
    PageVotersVotingFormTracking.showResult(
      data['chairman_proxy_results_online'], '#js-vft-online-chairman-proxy-result')
    PageVotersVotingFormTracking.showResult(
      data['chairman_proxy_results_offline'], '#js-vft-offline-chairman-proxy-result')
    PageVotersVotingFormTracking.showResult(
      data['final_results'], '#js-vft-final-result')
    PageVotersVotingFormTracking.showResult(
      data['final_results'], '#js-vft-final-result')
  },

  showResult: (data, selector) => {
    if (!data) {
      return
    }

    for (let questionId in data) {
      // console.log(selector + '-for-' + questionId)
      if (data[questionId]) {
        bodyContainer.querySelector(selector + '-for-' + questionId).innerHTML =
          PageVotersVotingFormTracking.formatResult(data[questionId]['For'])
        bodyContainer.querySelector(selector + '-against-' + questionId).innerHTML =
          PageVotersVotingFormTracking.formatResult(data[questionId]['Against'])
        bodyContainer.querySelector(selector + '-abstain-' + questionId).innerHTML =
          PageVotersVotingFormTracking.formatResult(data[questionId]['Abstain'])

        if(selector == '#js-vft-final-result') {
          bodyContainer.querySelector(selector + '-full-abstain-' + questionId).innerHTML =
            PageVotersVotingFormTracking.formatResult(data[questionId]['full_abstain'])
        }
      }
    }
  },

  formatResult: (result) => {
    if(result && typeof result[0] !== 'undefined' && typeof result[1] === 'undefined') {
      return '<b>' + result[0] + '</b>'
    } else if(result && typeof result[0] !== 'undefined' && typeof result[1] !== 'undefined') {
      return '<b>' + result[0] + '</b><br><small><i>' + result[1] + '</i></small>'
    } else {
      return '-'
    }
  },

  updateVotersTable: (data) => {
    // TODO: implement datatable
  },

  setTotalSharesInput: () =>{
    let currentValue = totalSharesInput.value
    btnTotalSharesInput.addEventListener('click', function(e) {
      let state = btnTotalSharesInput.dataset.state
      if(state == "Edit"){
        btnTotalSharesInput.innerHTML = "OK"
        btnTotalSharesInput.dataset.state = "OK"
        totalSharesInput.readOnly = false
        totalSharesInput.style.display = 'block'
        totalSharesLabel.style.display = 'none'
        totalSharesInput.focus()
      }else{
        totalSharesLabel.innerHTML = parseInt(totalSharesInput.value).toLocaleString()

        if(confirm("Confirm to save?")){
          PageVotersVotingFormTracking.setTotal()
        }else{
          totalSharesInput.value = currentValue
          totalSharesLabel.innerHTML = parseInt(currentValue).toLocaleString()
        }

        btnTotalSharesInput.innerHTML = "Edit"
        btnTotalSharesInput.dataset.state = "Edit"
        totalSharesInput.readOnly = true
        totalSharesInput.style.display = 'none'
        totalSharesLabel.style.display = 'block'
      }
    })
  },

  setTotalSharesholdersInput: () =>{
    let currentValue = totalSharesholdersInput.value
    btnTotalSharesholdersInput.addEventListener('click', function(e) {
      let state = btnTotalSharesholdersInput.dataset.state
      if(state == "Edit"){
        btnTotalSharesholdersInput.innerHTML = "OK"
        btnTotalSharesholdersInput.dataset.state = "OK"
        totalSharesholdersInput.readOnly = false
        totalSharesholdersInput.style.display = 'block'
        totalSharesholdersLabel.style.display = 'none'
        totalSharesholdersInput.focus()
      }else{
        totalSharesholdersLabel.innerHTML = parseInt(totalSharesholdersInput.value).toLocaleString()

        if(confirm("Confirm to save?")){
          PageVotersVotingFormTracking.setTotal()
        }else{
          totalSharesholdersInput.value = currentValue
          totalSharesholdersLabel.innerHTML = parseInt(currentValue).toLocaleString()
        }

        btnTotalSharesholdersInput.innerHTML = "Edit"
        btnTotalSharesholdersInput.dataset.state = "Edit"
        totalSharesholdersInput.readOnly = true
        totalSharesholdersInput.style.display = 'none'
        totalSharesholdersLabel.style.display = 'block'
      }
    })
  },

  setTotal: () => {
    let eventID = totalSharesholdersInput.getAttribute("data-event-id")
    let dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/voters/set_shareholders_and_shares_count',
      data: {
        event_number_of_shares: PageVotersVotingFormTracking.formatInput(totalSharesInput.value),
        event_number_of_shareholders: PageVotersVotingFormTracking.formatInput(totalSharesholdersInput.value)
      }
    }

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && !response.data.errors) {
          totalSharesInput.value = response.data.company_total_shares
          totalSharesholdersInput.value = response.data.company_total_shareholders
          totalSharesLabel.innerHTML = parseInt(totalSharesInput.value).toLocaleString()
          totalSharesholdersLabel.innerHTML = parseInt(totalSharesholdersInput.value).toLocaleString()
          PageVotersVotingFormTracking.validateTotal()
        } else if(response.data.errors) {
          alert(response.data.errors)
        } else {
          alert("Sorry we encountered an error while saving your changes please try again later.")
        }
      })
      .catch(error => console.error('catch',error))
  },

  formatInput: (input) => {
    if (input) {
      return parseInt(input)
    } else {
      return 0
    }
  },

  validateTotal: () => {
    let eventTotalShares = 0
    let votersTotalShares = 0
    let eventTotalShareholders = 0
    let totalVoters = 0
    let errorSelector = document.querySelector('#voting-count-error')

    if (totalSharesInput.value) {
      eventTotalShares = parseInt(totalSharesInput.value)
    }

    if (votersTotalSharesSelector.innerHTML) {
      votersTotalShares = parseInt(votersTotalSharesSelector.innerHTML.replace(/,/g, ''))
    }

    if (totalSharesholdersInput.value) {
      eventTotalShareholders = parseInt(totalSharesholdersInput.value)
    }

    if (votersTotalSelector.innerHTML) {
      totalVoters = parseInt(votersTotalSelector.innerHTML.replace(/,/g, ''))
    }

    if (votersTotalShares > eventTotalShares || totalVoters > eventTotalShareholders) {
      errorSelector.style.display = 'block'
    } else {
      errorSelector.style.display = 'none'
    }

    if (votersTotalShares > eventTotalShares) {
      errorSelector.querySelector('.company-shares-error-message').style.display = 'list-item'
    } else {
      errorSelector.querySelector('.company-shares-error-message').style.display = 'none'
    }

    if (totalVoters > eventTotalShareholders) {
      errorSelector.querySelector('.company-shareholders-error-message').style.display = 'list-item'
    } else {
      errorSelector.querySelector('.company-shareholders-error-message').style.display = 'none'
    }
  },
}

document.addEventListener("turbolinks:load", () => {
  totalSharesInput = document.querySelector('#event-number_of_shareholder_shares')
  totalSharesLabel = document.querySelector('#event-number_of_shareholder_shares-label')
  totalSharesholdersInput = document.querySelector('#event-number_of_shareholders')
  totalSharesholdersLabel = document.querySelector('#event-number_of_shareholders-label')
  btnTotalSharesInput = document.querySelector('#voter-edit_number_of_shareholder_shares')
  btnTotalSharesholdersInput = document.querySelector('#voter-edit_number_of_shareholders')
  votersTotalSelector = document.querySelector('#js-vft-sa-members-count')
  votersTotalSharesSelector = document.querySelector('#js-vft-sa-members-shares')

  if(bodyContainer) {
    PageVotersVotingFormTracking.init()
  }
})
