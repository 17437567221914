import axios from 'axios'
import WebcastPlayer from './webcast_player'
import WebcastVotingTimer from './webcast_voting_timer'
import WebcastVotingResult from './webcast_voting_result'

var csrfToken;
if (document.querySelector("meta[name=csrf-token]")) {
  csrfToken = document.querySelector("meta[name=csrf-token]").content
}
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

var EVoteResults;
if (typeof EVoteResults === 'undefined') EVoteResults = {};

var LiveEvoting;
if (typeof LiveEvoting === 'undefined') LiveEvoting = {};

const bodyContainer = document.querySelector('body.dashboard')
const resultsContainer = document.querySelector('#e-vote-results')
const liveEvotingContainer = document.querySelector('#js-vtd-live-evoting')
const votingTimerContainer = document.querySelector('.js-ew-voting-timer-container')
const webcastPlayer = document.querySelector('#si-player')

EVoteResults = {
  resultData: {},
  chartSeries: {},
  chartCategories: [],
  evoteEnded: false,

  init: () => {
    EVoteResults.votingResultHeaderContainer = resultsContainer.querySelector('#js-vtd-voting-result-header-container')
    EVoteResults.votingResultHeader = resultsContainer.querySelector('#js-vtd-voting-result-header')

    EVoteResults.getData()
    EVoteResults.toggleChart()

    resultsContainer.querySelector('#show-shareholders-results').addEventListener('click', function(e) {
      resultsContainer.querySelector('#show-shareholders-results').checked = true;
      resultsContainer.querySelector('#show-shares-results').checked = false;
      EVoteResults.getData()
    })

    resultsContainer.querySelector('#show-shares-results').addEventListener('click', function(e) {
      resultsContainer.querySelector('#show-shares-results').checked = true;
      resultsContainer.querySelector('#show-shareholders-results').checked = false;
      EVoteResults.getData()
    })

    resultsContainer.querySelector('#js-vtd-voting-result-toggle-chart-link').addEventListener('click', function(e) {
      e.preventDefault()
      EVoteResults.toggleChart()
    })

    resultsContainer.querySelector('#js-vtd-voting-result-refresh-button').addEventListener('click', function(e) {
      e.preventDefault()
      EVoteResults.getData()
    })
  },

  getData: () => {
    let eventID = resultsContainer.getAttribute("data-event-id")
    let dataObj = {
      method: 'GET',
      url: '/events/' + eventID + '/voting_topics/voting_results',
      responseType: 'json',
      headers: {
        'Accept': 'application/json'
      }
    }

    axios(dataObj)
      .then(response => {
        resultsContainer.querySelector('div.loader-container').style.display = 'none'
        resultsContainer.querySelector('div.alert').style.display = 'none'

        if(response.status == 200 && response.data.status == 'ok' && response.data.results) {
          EVoteResults.resultData = response.data.results
          EVoteResults.showTable()
          EVoteResults.showChart()
          EVoteResults.updateResultsHeader()
          EVoteResults.evoteEnded = EVoteResults.resultData.evote_ended
          if (EVoteResults.resultData.evote_ended) {
            LiveEvoting.votingEndedCallback()
          }
        }else{
          EVoteResults.showError()
        }
      })
      .catch(error => console.log('catch',error))
  },

  showError: () => {
    resultsContainer.querySelector('div.alert').style.display = 'block'
  },

  showTable: () => {
    let responseOptions = EVoteResults.resultData.question_response_options
    let tableHeaders = '<th scope="col" class="font-weight-bold g-per-width-50" >Resolutions relating to:</th>'
    tableHeaders += '<th colspan="2" scope="col" class="font-weight-bold g-per-width-50" >Result</th>'
    let tableBody = ''

    EVoteResults.chartSeries = {}

    // show table headers
    responseOptions.forEach((option) => {
      if (option != 'Abstain') {
        EVoteResults.chartSeries[option] = []
      }
    });
    resultsContainer.querySelector('#js-vtd-voting-results').querySelector('thead').innerHTML = tableHeaders

    // show table body
    for (let topic in EVoteResults.resultData.result) {
      tableBody += '<tr class="thead-dark"><th colspan="3">' + topic + '</th></tr>'

      for (let resolution_number in EVoteResults.resultData.result[topic]) {
        EVoteResults.chartCategories.push(resolution_number)
        tableBody += '<tr data-row_id="' + resolution_number + '">'
        tableBody += '<td rowspan="' + (responseOptions.length+2) + '">' + resolution_number + '. ' + EVoteResults.resultData.result[topic][resolution_number]["resolution"] + '</td>'

        responseOptions.forEach((option, index) => {
          let answers = EVoteResults.resultData.result[topic][resolution_number]["answers_by_shares"]

          if(resultsContainer.querySelector('#show-shareholders-results').checked) {
            answers = EVoteResults.resultData.result[topic][resolution_number]["answers_by_shareholders"]
          }
          if (index != 0){
            tableBody += '<tr data-row_id="' + resolution_number + '">'
          }
          if (answers && answers[option]) {
            let value = answers[option][0]
            let percent = answers[option][1]
            if (option != 'Abstain') {
              EVoteResults.chartSeries[option].push(parseInt(value.replace(/,/g, '')))
              value = '<b>' + value + '</b>';
            }
            tableBody += '<th class="table-secondary g-per-width-25 text-left font-weight-bold">' + option + '</th>'
            if (value == '-') {
              tableBody += '<td class="result-value g-per-width-25 text-right">-</td>'
            } else {
              let percentText = ''
              if (percent) {
                percentText = '<br><small><i>' + percent + '</i></small>'
              }
              tableBody += '<td class="result-value text-right">' + value + percentText + '</td>'
            }
          } else {
            tableBody += '<td class="result-value text-right">-</td>'
            EVoteResults.chartSeries[option].push(0)
          }
          tableBody += '</tr>'
        });
        let resultColor
        switch(EVoteResults.resultData.result[topic][resolution_number]["result"]) {
          case 'PASSED':
            resultColor = 'bg-success'
            break;
          case 'NOT PASSED':
            resultColor = 'bg-danger'
            break;
          case 'TIED':
            resultColor = 'bg-warning'
          default:
            resultColor = ''
        }
        if(resultsContainer.querySelector('#show-shareholders-results').checked) {
          tableBody += '<tr data-row_id="' + resolution_number + '">'
          tableBody += '<th class="table-secondary text-left font-weight-bold" data-toggle="tooltip" title="Total number of shareholders represented by votes For and Against the relevant resolution">Total number of shareholders <sup class="icon_tooltip no-print"><i class="fas fa-info-circle"></i></sup></th>'
          tableBody += '<td class="result-value text-right">' +
            EVoteResults.resultData.result[topic][resolution_number]["total_number_shareholders"]
            + '</td>'
          tableBody += '</tr>'
          tableBody += '<tr data-row_id="' + resolution_number + '">'
          tableBody += '<td colspan="2" class="result-value text-center '+ resultColor +'"><b>' +
            EVoteResults.resultData.result[topic][resolution_number]["result_by_shareholders"]
              + '</b></td>'
          tableBody += '</tr>'
        } else {
          tableBody += '<tr data-row_id="' + resolution_number + '">'
          tableBody += '<th class="table-secondary text-left font-weight-bold" data-toggle="tooltip" title="Total number of shares represented by votes For and Against the relevant resolution">Total number of shares <sup class="icon_tooltip no-print"><i class="fas fa-info-circle"></i></sup></th>'
          tableBody += '<td class="result-value text-right">' +
            EVoteResults.resultData.result[topic][resolution_number]["total_number_shares"]
            + '</td>'
          tableBody += '</tr>'
          tableBody += '<tr data-row_id="' + resolution_number + '">'
          tableBody += '<td colspan="2" class="result-value '+ resultColor +'"><div class="row no-gutters align-items-center"><div class="col-12 col-sm-6 mb-2 mb-sm-0 text-center print-full-width "><b data-toggle="tooltip" title="Passing rate: ' + EVoteResults.resultData.result[topic][resolution_number]["passing_rate"] + '">' +
            EVoteResults.resultData.result[topic][resolution_number]["result"]
            + '<sup class="g-ml-3 icon_tooltip no-print"><i class="fas fa-info-circle"></i></sup></b><span class="d-none d-print-block">Passing rate: ' + EVoteResults.resultData.result[topic][resolution_number]["passing_rate"] + '</span></div>'

          // show result button
          let shownResultClass = ''
          let showResultButtonStyle = ''
          let showResultButtonDisabled= ''
          let hideResultButtonStyle = ' style="display:none;" '
          let questionID = EVoteResults.resultData.result[topic][resolution_number]["question_id"]

          if (EVoteResults.resultData.result[topic][resolution_number]["is_showing_result"]) {
            showResultButtonStyle = ' style="display:none;" '
            hideResultButtonStyle = ''
          }

          if (EVoteResults.resultData.result[topic][resolution_number]["result_shown"]) {
            shownResultClass = ' btn-secondary '
          } else {
            shownResultClass = ' btn-primary '
          }

          if (resultsContainer.getAttribute("data-event-is-showing-result") == 'true' ||
            !EVoteResults.resultData.evote_ended) {

            showResultButtonDisabled = ' disabled '
          }
          tableBody += '<div class="col-12 col-sm-6 text-center no-print">'
          tableBody += '<div class="text-center" style="display:none;" id="js-vtd-result-loader-container-' + questionID + '">' +
            '<span class="d-inline-block mr-3"><i class="fas fa-spinner fa-spin"></i></span>' +
            '<span class="d-inline-block">Loading...</span></div>' +

            '<button class="no-print btn mx-auto '+ shownResultClass + ' js-vtd-live-evoting-show-result"' +
            'id="js-vtd-live-evoting-show-result-' + questionID + '"' +
            'data-question-resolution="' + resolution_number + '. ' + topic + ': ' +
            EVoteResults.resultData.result[topic][resolution_number]["resolution"] + '"' +
            showResultButtonStyle + showResultButtonDisabled +
            'data-question-id="' + questionID + '"><i class="fas fa-search"></i> Show Result</button>' +

            '<button class="no-print btn btn-danger mx-auto js-vtd-live-evoting-remove-result"' +
            'id="js-vtd-live-evoting-remove-result-' + questionID + '"' +
            'data-question-resolution="' + resolution_number + '. ' + topic + ': ' +
            EVoteResults.resultData.result[topic][resolution_number]["resolution"] + '"' + hideResultButtonStyle +
            'data-question-id="' + questionID + '"><i class="fas fa-ban"></i> Remove Result</button>'
            + '</div></td>'
        }

        tableBody += '</tr>'
      }
    }
    resultsContainer.querySelector('#js-vtd-voting-results').querySelector('tbody').innerHTML = tableBody
    resultsContainer.querySelector('div.toggle-data').style.display = 'block'
    resultsContainer.querySelector('figure.highcharts-figure').style.display = 'block'
    resultsContainer.querySelector('#js-vtd-voting-result-last-updated').innerHTML = 'Last updated at ' + EVoteResults.resultData.last_updated

    EVoteResults.addEventListenersForResultButtons()
    $('[data-toggle="tooltip"]').tooltip()
    EVoteResults.hoverTable()
  },

  hoverTable:() =>{
    let table = resultsContainer.querySelector('#js-vtd-voting-results').querySelector('tbody')
    let all_table_rows = table.querySelectorAll("tr:not(.thead-dark)")
    if (all_table_rows){
      all_table_rows.forEach((table_row) => {
        ["mouseover", "mouseleave"].forEach(evt =>
          table_row.addEventListener(evt, function(e) {
            let row_id = e.currentTarget.dataset.row_id
            if (e.type == 'mouseover') {
              table.querySelectorAll('[data-row_id="' + row_id + '"]').forEach((select_row) => {
                select_row.classList.add('hover')
              })
            }
            else{
              table.querySelectorAll('[data-row_id="' + row_id + '"]').forEach(deselect_row => {
                deselect_row.classList.remove('hover')
              })
            }
          })
        )
      })
    }
  },

  showChart: () => {
    let title = 'Voting Results by Number of Shares'
    let y_axis_title = "Percentage by number of shares"
    let series = []

    if(resultsContainer.querySelector('#show-shareholders-results').checked) {
      title = 'Voting Results by Number of Shareholders'
      y_axis_title = "Percentage by number of shareholders"
    }

    for (let key in EVoteResults.chartSeries) {
      series.push({
        name: key,
        data: EVoteResults.chartSeries[key]
      })
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    })
    Highcharts.chart('chart-container', {
      chart: {
        type: 'column'
      },
      title: {
        text: title
      },
      xAxis: {
        categories: EVoteResults.chartCategories,
        title: {
          text: 'Resolutions'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: y_axis_title
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'percent'
        }
      },
      colors: ['#5a95d7', '#d65c5c','#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
      series: series,
      exporting: {
        enabled: false
      }
    });
  },

  toggleChart: () => {
    if (resultsContainer.querySelector('#chart-container').style.display == 'none') {
      resultsContainer.querySelector('#chart-container').style.display = 'block'
      resultsContainer.querySelector('#js-vtd-voting-result-toggle-chart-link').innerHTML = 'Hide Chart'
    } else {
      resultsContainer.querySelector('#chart-container').style.display = 'none'
      resultsContainer.querySelector('#js-vtd-voting-result-toggle-chart-link').innerHTML = 'Show Chart'
    }
  },

  addEventListenersForResultButtons: () => {
    EVoteResults.showResultButtons = resultsContainer.querySelectorAll('.js-vtd-live-evoting-show-result')
    EVoteResults.removeResultButtons = resultsContainer.querySelectorAll('.js-vtd-live-evoting-remove-result')

    EVoteResults.showResultButtons.forEach((showResultButton) => {
      showResultButton.addEventListener('click', function(e) {
        resultsContainer.querySelector('#show-modal-btn-ok').dataset.resultbtnid = e.currentTarget.id
        LiveEvoting.showConfirmationModal("#showConfirmModal", '#showConfirmModalLabel', 'Are you sure you want to show the voting result for: "' +
          e.currentTarget.getAttribute('data-question-resolution') + '"? This will take about 5 seconds to take effect.')
      })
    })
    resultsContainer.querySelector('#show-modal-btn-ok').addEventListener('click', function() {
      let btnID = resultsContainer.querySelector('#show-modal-btn-ok').dataset.resultbtnid
      if (resultsContainer.querySelector('#'+btnID).style.display !== 'none') {
        EVoteResults.requestToShowResult(btnID)
        $("#showConfirmModal").modal('hide')
      }
    })

    EVoteResults.removeResultButtons.forEach((removeResultButton) => {
      removeResultButton.addEventListener('click', function(e) {
        resultsContainer.querySelector('#hide-modal-btn-ok').dataset.resultbtnid = e.currentTarget.id
        LiveEvoting.showConfirmationModal("#hideConfirmModal", '#hideConfirmModalLabel', 'Are you sure you want to hide the voting result for: "' +
          e.currentTarget.getAttribute('data-question-resolution') + '"? This will take about 5 seconds to take effect.')
      })
    })
    resultsContainer.querySelector('#hide-modal-btn-ok').addEventListener('click', function() {
      let btnID = resultsContainer.querySelector('#hide-modal-btn-ok').dataset.resultbtnid
      if (resultsContainer.querySelector('#'+btnID).style.display !== 'none') {
        EVoteResults.requestToHideResult(btnID)
        $("#hideConfirmModal").modal('hide')
      }
    })
  },

  requestToShowResult: (btnID) => {
    let button = resultsContainer.querySelector('#' + btnID)
    if (!button) {
      return
    }

    let eventID = resultsContainer.getAttribute("data-event-id")
    let questionID = button.getAttribute("data-question-id")
    let loader = resultsContainer.querySelector('#js-vtd-result-loader-container-' + questionID)
    let removeButton = resultsContainer.querySelector('#js-vtd-live-evoting-remove-result-' + questionID)
    let dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/voters/live_evoting_result',
      data: {
        start: true,
        voting_question_id: questionID,
      }
    }

    loader.style.display = 'block'
    button.style.display = 'none'
    EVoteResults.showResultButtons.forEach((showResultButton) => {
      showResultButton.disabled = true
    })

    axios(dataObj)
      .then(response => {
        loader.style.display = 'none'
        if(response.status == 200 && response.data.status == 'ok') {
          resultsContainer.setAttribute("data-event-is-showing-result", 'true')
          removeButton.style.display = 'block'
        }else if(response.data.status == 'failed' && response.data.message && response.data.message.length > 0){
          button.style.display = 'block'
          EVoteResults.showResultButtons.forEach((showResultButton) => {
            showResultButton.disabled = false
          })
          LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', response.data.message)
        }else{
          button.style.display = 'block'
          EVoteResults.showResultButtons.forEach((showResultButton) => {
            showResultButton.disabled = false
          })
          LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', 'System error please try again.')
        }
      })
      .catch(error => console.log('catch',error))
  },

  requestToHideResult: (btnID) => {
    let button = resultsContainer.querySelector('#' + btnID)
    if (!button) {
      return
    }

    let eventID = resultsContainer.getAttribute("data-event-id")
    let questionID = button.getAttribute("data-question-id")
    let loader = resultsContainer.querySelector('#js-vtd-result-loader-container-' + questionID)
    let showButton = resultsContainer.querySelector('#js-vtd-live-evoting-show-result-' + questionID)
    let dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/voters/live_evoting_result',
      data: {
        end: true,
        voting_question_id: questionID,
      }
    }

    loader.style.display = 'block'
    button.style.display = 'none'
    EVoteResults.showResultButtons.forEach((showResultButton) => {
      showResultButton.disabled = true
    })

    axios(dataObj)
    .then(response => {
      loader.style.display = 'none'
      if(response.status == 200 && response.data.status == 'ok') {
        resultsContainer.setAttribute("data-event-is-showing-result", 'false')
        showButton.style.display = 'block'
        showButton.classList.add('btn-secondary')
        showButton.classList.remove('btn-primary')
        EVoteResults.showResultButtons.forEach((showResultButton) => {
          showResultButton.disabled = false
        })
      }else if(response.data.status == 'failed' && response.data.message && response.data.message.length > 0){
        button.style.display = 'block'
        LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', response.data.message)
      }else{
        button.style.display = 'block'
        LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', 'System error please try again.')
      }
    })
    .catch(error => console.log('catch',error))
  },

  updateResultsHeader: () => {
    if (EVoteResults.resultData.evote_ended) {
      if(EVoteResults.votingResultHeader && EVoteResults.votingResultHeader.innerHTML == 'Final Results') {
        return
      }

      if (EVoteResults.votingResultHeaderContainer) {
        EVoteResults.votingResultHeaderContainer.classList.remove('bg-warning')
        EVoteResults.votingResultHeaderContainer.classList.remove('text-dark')
        EVoteResults.votingResultHeaderContainer.classList.add('bg-success')
        EVoteResults.votingResultHeaderContainer.classList.add('text-white')
      }

      if (EVoteResults.votingResultHeader) {
        EVoteResults.votingResultHeader.innerHTML = 'Final Results'
      }
    } else {
      if(EVoteResults.votingResultHeader && EVoteResults.votingResultHeader.innerHTML == 'Preliminary Results') {
        return
      }

      if (EVoteResults.votingResultHeaderContainer) {
        EVoteResults.votingResultHeaderContainer.classList.remove('bg-success')
        EVoteResults.votingResultHeaderContainer.classList.remove('text-white')
        EVoteResults.votingResultHeaderContainer.classList.add('bg-warning')
        EVoteResults.votingResultHeaderContainer.classList.add('text-dark')
      }

      if (EVoteResults.votingResultHeader) {
        EVoteResults.votingResultHeader.innerHTML = 'Preliminary Results'
      }
    }
  },
}

LiveEvoting = {
  counter: 0,
  init: () => {
    LiveEvoting.startButton = liveEvotingContainer.querySelector('#js-vtd-live-voting-start')
    LiveEvoting.endButton = liveEvotingContainer.querySelector('#js-vtd-live-voting-end')
    LiveEvoting.resetButton = liveEvotingContainer.querySelector('#js-vtd-live-voting-reset')
    LiveEvoting.resetMetadata = liveEvotingContainer.querySelector('#js-vtd-aws-ivs-metadata-reset')
    LiveEvoting.durationInput = liveEvotingContainer.querySelector('#js-vtd-live-voting-countdown-duration')
    LiveEvoting.durationInputContainer = liveEvotingContainer.querySelector('.js-vtd-live-voting-countdown-input-container')
    LiveEvoting.countdownLabelTimer = liveEvotingContainer.querySelector('#js-vtd-live-voting-countdown-label-timer')
    LiveEvoting.countdownButtonsContainer = liveEvotingContainer.querySelector('.js-vtd-live-voting-countdown-buttons-container')
    LiveEvoting.loaderContainer = liveEvotingContainer.querySelector('.js-vtd-loader-container')

    LiveEvoting.startButton.addEventListener('click', function() {
      $("#startConfirmModal").modal('show')
    })
    liveEvotingContainer.querySelector('#start-modal-btn-ok').addEventListener('click', function() {
      LiveEvoting.startCountdownTimer()
      $("#startConfirmModal").modal('hide')
    })

    LiveEvoting.endButton.addEventListener('click', function() {
      $("#endConfirmModal").modal('show')
    })
    liveEvotingContainer.querySelector('#end-modal-btn-ok').addEventListener('click', function() {
      LiveEvoting.endCountdownTimer()
      $("#endConfirmModal").modal('hide')
    })

    LiveEvoting.resetButton.addEventListener('click', function() {
      $("#resetConfirmModal").modal('show')
    })
    liveEvotingContainer.querySelector('#reset-modal-btn-ok').addEventListener('click', function() {
      LiveEvoting.resetCountdownTimer()
      $("#resetConfirmModal").modal('hide')
    })

    LiveEvoting.resetMetadata.addEventListener('click', function(e) {
      e.preventDefault()
      $("#resetMetadataConfirmModal").modal('show')
    })
    liveEvotingContainer.querySelector('#reset-metadata-modal-btn-ok').addEventListener('click', function(e) {
      e.preventDefault()
      LiveEvoting.resetAWSIVSMetadata()
      $("#resetMetadataConfirmModal").modal('hide')
    })
  },

  startCountdownTimer: () => {
    let eventID = liveEvotingContainer.getAttribute("data-event-id")
    let dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/voters/live_evoting_countdown',
      data: {
        start: true,
        seconds_duration: LiveEvoting.durationInput.value
      }
    }

    LiveEvoting.showLoader()
    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data.status == 'ok' && response.data) {
          liveEvotingContainer.querySelector('#js-vtd-live-evote-end-datetime').innerHTML = response.data.live_evote_end_datetime
          EVoteResults.evoteEnded = false
        }else if(response.data.status == 'failed' && response.data.message && response.data.message.length > 0){
          LiveEvoting.hideLoader()
          LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', response.data.message)
        }else{
          LiveEvoting.hideLoader()
          LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', 'System error please try again.')
        }
      })
      .catch(error => console.log('catch',error))
  },

  endCountdownTimer: () => {
    let eventID = liveEvotingContainer.getAttribute("data-event-id")
    let dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/voters/live_evoting_countdown',
      data: {
        end: true
      },
      responseType: 'json',
      headers: {
        'Accept': 'application/json'
      }
    }

    LiveEvoting.showLoader()
    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data.status == 'ok' && response.data) {
          liveEvotingContainer.querySelector('#js-vtd-live-evote-end-datetime').innerHTML = response.data.live_evote_end_datetime
        }else if(response.data.status == 'fail' && response.data.message && response.data.message.length > 0){
          LiveEvoting.hideLoader()
          LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', response.data.message)
        }else{
          LiveEvoting.hideLoader()
          LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', 'System error please try again.')
        }
      })
      .catch(error => console.log('catch',error))
  },

  resetCountdownTimer: () => {
    let eventID = liveEvotingContainer.getAttribute("data-event-id")
    let dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/voters/live_evoting_countdown',
      data: {
        reset: true
      },
      responseType: 'json',
      headers: {
        'Accept': 'application/json'
      }
    }

    $('#loader-modal').modal('show')
    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data.status == 'ok' && response.data) {
          liveEvotingContainer.querySelector('#js-vtd-live-evote-end-datetime').innerHTML = response.data.live_evote_end_datetime
        }else if(response.data.status == 'fail' && response.data.message && response.data.message.length > 0){
          $('#loader-modal').modal('hide')
          LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', response.data.message)
        }else{
          $('#loader-modal').modal('hide')
          LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', 'System error please try again.')
        }
      })
      .catch(error => console.log('catch',error))
  },

  resetAWSIVSMetadata: () => {
    let eventID = liveEvotingContainer.getAttribute("data-event-id")
    let dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/reset_metadata',
      data: {
        reset: true
      },
      responseType: 'json',
      headers: {
        'Accept': 'application/json'
      }
    }

    $('#loader-modal').modal('show')
    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data.status == 'ok' && response.data) {
        }else if(response.data.status == 'fail' && response.data.message && response.data.message.length > 0){
          $('#loader-modal').modal('hide')
          LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', response.data.message)
        }else{
          $('#loader-modal').modal('hide')
          LiveEvoting.showConfirmationModal("#alertModal", '#alertModalLabel', 'System error please try again.')
        }
      })
      .catch(error => console.log('catch',error))
  },

  showLoader: () => {
    LiveEvoting.countdownButtonsContainer.style.display = 'none'
    LiveEvoting.countdownLabelTimer.style.display = 'none'
    LiveEvoting.durationInputContainer.style.display = 'none'
    LiveEvoting.loaderContainer.style.display = 'block'
  },

  hideLoader: () => {
    LiveEvoting.loaderContainer.style.display = 'none'
    LiveEvoting.countdownButtonsContainer.style.display = 'block'
    LiveEvoting.countdownLabelTimer.style.display = 'block'
    LiveEvoting.durationInputContainer.style.display = 'flex'
  },

  playerMetadataCallback: (webcastMetadata) => {
    if (!webcastMetadata) {
      return
    }

    WebcastVotingTimer.updateLiveEvoting(webcastMetadata)

    if (webcastMetadata.voting_result) {
      WebcastVotingResult.process(webcastMetadata.id, webcastMetadata.voting_result)
    }
  },

  votingStartedCallback: () => {
    LiveEvoting.hideLoader()
    LiveEvoting.durationInputContainer.style.display = 'none'
    LiveEvoting.countdownLabelTimer.style.display = 'none'


    if (!LiveEvoting.durationInput.disabled) {
      LiveEvoting.durationInput.disabled = true
    }
    if (!LiveEvoting.startButton.disabled) {
      LiveEvoting.startButton.disabled = true
    }
    if (LiveEvoting.endButton.disabled) {
      LiveEvoting.endButton.disabled = false
    }
    if (LiveEvoting.resetButton.parentElement.style.display != 'none') {
      LiveEvoting.resetButton.disabled = true
      LiveEvoting.resetButton.parentElement.style.display = 'none'
    }
  },

  votingEndedCallback: () => {
    LiveEvoting.hideLoader()
    LiveEvoting.durationInputContainer.style.display = 'flex'
    LiveEvoting.countdownLabelTimer.style.display = 'block'


    if (LiveEvoting.durationInput.disabled) {
      LiveEvoting.durationInput.disabled = false
    }
    if (LiveEvoting.startButton.disabled) {
      LiveEvoting.startButton.disabled = false
    }
    if (!LiveEvoting.endButton.disabled) {
      LiveEvoting.endButton.disabled = true
    }
    if (LiveEvoting.resetButton.parentElement.style.display == 'none') {
      LiveEvoting.resetButton.disabled = false
      LiveEvoting.resetButton.parentElement.style.display = 'block'
    }
  },

  votingTimerUpdatedCallback: () => {
    LiveEvoting.counter++

    if (LiveEvoting.counter >= 5) {
      LiveEvoting.counter = 0

      if(!EVoteResults.evoteEnded) {
        EVoteResults.getData()
      }
    }
  },

  showConfirmationModal: (modalID, modalLabelSelectorID, message) => {
    document.querySelector(modalLabelSelectorID).innerHTML = message
    $(modalID).modal('show')
  }
}

document.addEventListener("DOMContentLoaded", function(){
  if(bodyContainer && resultsContainer) {
    EVoteResults.init()
  }

  if(bodyContainer && liveEvotingContainer) {
    LiveEvoting.init()
  }

  if(bodyContainer && votingTimerContainer){
    let options = {
      votingStartedCallback: LiveEvoting.votingStartedCallback,
      votingEndedCallback: LiveEvoting.showLoader,
      votingTimerUpdatedCallback: LiveEvoting.votingTimerUpdatedCallback
    }
    WebcastVotingTimer.init(options)
  }

  if(bodyContainer && webcastPlayer) {
    WebcastPlayer.init(LiveEvoting.playerMetadataCallback)
    WebcastVotingResult.init()
  }
})
