import axios from 'axios'

var csrfToken;
if (document.querySelector("meta[name=csrf-token]")) {
  csrfToken = document.querySelector("meta[name=csrf-token]").content
}
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

var VotersIndex;
if (typeof VotersIndex === 'undefined') VotersIndex = {};

var totalSharesInput, totalSharesholdersInput, votersTotalSharesSelector, votersTotalSelector, btnSetNumberOfShares, totalSharesLabel, totalSharesholdersLabel

VotersIndex = {
  init: () => {
    VotersIndex.setupNumberOfShares()
    VotersIndex.setupBatchBtn()
  },

  setTotal: () => {
    let eventID = totalSharesholdersInput.getAttribute("data-event-id")
    let dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/voters/set_shareholders_and_shares_count',
      data: {
        event_number_of_shares: VotersIndex.formatInput(totalSharesInput.value),
        event_number_of_shareholders: VotersIndex.formatInput(totalSharesholdersInput.value)
      }
    }

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && !response.data.errors) {
          totalSharesInput.value = response.data.company_total_shares
          totalSharesholdersInput.value = response.data.company_total_shareholders
          totalSharesLabel.innerHTML = parseInt(totalSharesInput.value).toLocaleString()
          totalSharesholdersLabel.innerHTML = parseInt(totalSharesholdersInput.value).toLocaleString()
          VotersIndex.validateTotal()
        } else if(response.data.errors) {
          alert(response.data.errors)
        } else {
          alert("Sorry we encountered an error while saving your changes please try again later.")
        }
      })
      .catch(error => console.error('catch',error))
  },

  setupNumberOfShares: () =>{
    let editState = false,
    unsavedChange = []

    if (!btnSetNumberOfShares) return;

    btnSetNumberOfShares.addEventListener('click',function(e){
      if(editState){
        if(unsavedChange.includes(1)){
          if(confirm("Detected unsaved changes in number of shares, confirm to save all changes?")){
            VotersIndex.updateAllVoterShares()
          }else{
            VotersIndex.resetAllVoterShares()
          }
          unsavedChange = []
        }
        $(".checkbox_td").show()
        $(".td_input_field").css('min-width', '0')
        $(".edit-voter-shares").hide()
        $(".voter-shares").hide()
        $(".voter-shares").attr('readOnly', true)
        $(".voter-shares-label").show()
        editState = false
      }else{
        $(".checkbox_td").hide()
        $(".td_input_field").css('min-width', '150px')
        $(".edit-voter-shares").show()
        $(".voter-shares").removeAttr('readOnly')
        $(".voter-shares").show()
        $(".voter-shares-label").hide()
        editState = true
      }
    })

    document.querySelectorAll('.voter-shares').forEach((voterShare, index) => {
      voterShare.addEventListener('change',function(e){
        unsavedChange[index] = 1
      })

      voterShare.nextElementSibling.querySelector(".edit-voter-shares").addEventListener('click', () => {
        if(confirm("Confirm to save?")){
          VotersIndex.updateVoterShare(voterShare)
        }else{
          let currentValue = voterShare.getAttribute("data-current")
          voterShare.value = currentValue
        }
        unsavedChange[index] = 0
      })
    })
  },

  updateVoterShare: (voterShare) => {
    let newValue = VotersIndex.formatInput(voterShare.value)
    let eventID = totalSharesholdersInput.getAttribute("data-event-id")
    let dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/voters/set_shareholders_and_shares_count',
      data: {
        voter_id: voterShare.id.substring(6),
        voter_number_of_shares: newValue
      }
    }
    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data) {
          voterShare.value = response.data.voter_number_of_shares
          voterShare.setAttribute("data-current", response.data.voter_number_of_shares)
          votersTotalSharesSelector.innerHTML = response.data.voter_total_shares
          VotersIndex.validateTotal()
          if(response.data.errors && response.data.errors.length > 0) {
            alert(response.data.errors)
          } else {
            let msg = "Voter number of shares updated to " + response.data.voter_number_of_shares
            if (response.data.message && response.data.message.length > 0) msg += ". " + response.data.message
            let voterShareLabel = document.querySelector('#voter-' + voterShare.id.substring(6) + '-label')
            if (voterShareLabel) voterShareLabel.innerHTML = response.data.voter_number_of_shares
            alert(msg)
            if (response.data.message && response.data.message.length > 0) location.reload()
          }
        } else if(response.data && response.data.errors) {
          alert(response.data.errors)
        } else {
          alert("Sorry we encountered an error while saving your changes please try again later.")
        }
      })
      .catch(error => console.error('catch',error))
  },

  updateAllVoterShares: () => {
    document.querySelectorAll('.voter-shares').forEach((voterShare, index) => {
      VotersIndex.updateVoterShare(voterShare)
    })
  },

  resetAllVoterShares: () => {
    document.querySelectorAll('.voter-shares').forEach((voterShare) => {
      let currentValue = voterShare.getAttribute("data-current")
      voterShare.value = currentValue
    })
  },

  formatInput: (input) => {
    if (input) {
      return parseInt(input)
    } else {
      return 0
    }
  },

  validateTotal: () => {
    let eventTotalShares = 0
    let votersTotalShares = 0
    let eventTotalShareholders = 0
    let totalVoters = 0
    let errorSelector = document.querySelector('#voting-count-error')

    if (totalSharesInput.value) {
      eventTotalShares = parseInt(totalSharesInput.value)
    }

    if (votersTotalSharesSelector.innerHTML) {
      votersTotalShares = parseInt(votersTotalSharesSelector.innerHTML)
    }

    if (totalSharesholdersInput.value) {
      eventTotalShareholders = parseInt(totalSharesholdersInput.value)
    }

    if (votersTotalSelector.innerHTML) {
      totalVoters = parseInt(votersTotalSelector.innerHTML)
    }

    if (votersTotalShares > eventTotalShares || totalVoters > eventTotalShareholders) {
      errorSelector.style.display = 'block'
    } else {
      errorSelector.style.display = 'none'
    }

    if (votersTotalShares > eventTotalShares) {
      errorSelector.querySelector('.company-shares-error-message').style.display = 'list-item'
    } else {
      errorSelector.querySelector('.company-shares-error-message').style.display = 'none'
    }

    if (totalVoters > eventTotalShareholders) {
      errorSelector.querySelector('.company-shareholders-error-message').style.display = 'list-item'
    } else {
      errorSelector.querySelector('.company-shareholders-error-message').style.display = 'none'
    }
  },

  setupBatchBtn: () =>{
    // reset/uncheck all voter checkboxes and disable verify/reject whenever the datatable is redrawn
    $(".voter-row_checkbox").each(function () {
      this.checked = false
    })
    VotersIndex.batchBtnState()

    let event_id = $("#voter-batch_verify").data('event-id')
    $(".voter-row_checkbox").on('click', function (e) {
      let voters = VotersIndex.selectedVoters()
      let allVotersCount = $('input[name="voter_id"]').length
      if ($(this).attr("name") == "select_all") {
        if ($(this).is(":checked")) {
          $('input[name="voter_id"]').each(function () {
            this.checked = true
          });
        } else {
          $('input[name="voter_id"]').each(function () {
            this.checked = false
          });
        }
      } else{
        if (voters.length == allVotersCount) {
          $('input[name="select_all"]').prop("checked", true)
        } else {
          $('input[name="select_all"]').prop("checked", false)
        }
      }
      VotersIndex.batchBtnState()
    })

    let btnBatchVerify = document.getElementById("voter-batch_verify")
    if (!!btnBatchVerify) { // reset onclick-handler if btn exists
      btnBatchVerify.onclick = null
      btnBatchVerify.onclick = function(){
        VotersIndex.batchVerify(event_id)
      }
    }

    let btnBatchReject = document.getElementById("voter-batch_reject")
    if (!!btnBatchReject) { // reset onclick-handler if btn exists
      btnBatchReject.onclick = null
      btnBatchReject.onclick = function(){
        VotersIndex.batchReject(event_id)
      }
    }

    let btnBatchSendEmail = document.getElementById("voter-batch_send_webcast")
    if (!!btnBatchSendEmail) { // reset onclick-handler if btn exists
      btnBatchSendEmail.onclick = null
      btnBatchSendEmail.onclick = function(){
        VotersIndex.batchSendEmail(event_id)
      }
    }
  },

  batchBtnState: () => {
    let voters = VotersIndex.selectedVoters()
    if (voters.length > 0) {
      $("#voter-batch_verify").removeClass("disabled")
      $("#voter-batch_reject").removeClass("disabled")
      $("#voter-batch_verify").removeAttr("disabled")
      $("#voter-batch_reject").removeAttr("disabled")
    } else {
      $(".voter-batch_btn").addClass("disabled")
      $(".voter-batch_btn").attr("disabled", "disabled")
    }
  },

  selectedVoters: () => {
    let voters = [];
    let check_voters_status = [];
    $('[name="voter_id"]').each(function () {
      if ($(this).is(":checked")) {
        voters.push($(this).val())
        check_voters_status.push($(this).data('status'))
      }
    });
    check_voters_status = check_voters_status.filter(status =>
      status != "rejected" && status != "verified"
    )
    if (check_voters_status.length > 0) {
      $("#voter-batch_send_webcast").addClass("disabled");
      $("#voter-batch_send_webcast").attr("disabled", "disabled");
    }else{
      $("#voter-batch_send_webcast").removeClass("disabled");
      $("#voter-batch_send_webcast").removeAttr("disabled");
    }
    return voters
  },

  batchVerify: (event_id) => {
    let voters = VotersIndex.selectedVoters()
    if (voters.length > 0) {
      if (confirm("Are you sure you want to verify the selected " + voters.length + " voter(s)?")) {
        $('#loader-modal').modal('show')
        $.ajax({
          url: '/events/' + event_id + '/voters/batch_verify',
          type: "POST",
          data: {voters},
          success: function (msg) {}
        });
      }
    }
  },

  batchReject: (event_id) => {
    let voters = VotersIndex.selectedVoters()
    if (voters.length > 0) {
      if (confirm("Are you sure you want to reject the selected " + voters.length + " voter(s)?")) {
        $('#loader-modal').modal('show')
        $.ajax({
          url: '/events/' + event_id + '/voters/batch_reject',
          type: "POST",
          data: {voters},
          success: function (msg) {}
        });
      }
    }
  },

  batchSendEmail: (event_id) => {
    let voters_ids = VotersIndex.selectedVoters()
    if (voters_ids.length > 0) {
      if (confirm("If you proceed, this will send the webcast link to the selected " + voters_ids.length + " voter(s) by email. Typically this is sent 24 hours before the start of the event. Please confirm if you wish to proceed.")) {
        $('#loader-modal').modal('show')
        $.ajax({
          url: '/events/' + event_id + '/send_webcast_link',
          type: "POST",
          data: {voters_ids},
          success: function (msg) {}
        });
      }
    }
  }

}

document.addEventListener("turbolinks:load", () => {
  totalSharesInput = document.querySelector('#event-number_of_shareholder_shares')
  totalSharesLabel = document.querySelector('#event-number_of_shareholder_shares-label')
  totalSharesholdersInput = document.querySelector('#event-number_of_shareholders')
  totalSharesholdersLabel = document.querySelector('#event-number_of_shareholders-label')
  votersTotalSharesSelector = document.querySelector('#voter-number_of_shareholder_shares')
  votersTotalSelector = document.querySelector('#voter-number_of_shareholders')
  btnSetNumberOfShares = document.querySelector('#voter-set_number_of_shares')


  if ($('#voters-index-table').length > 0){
    var voters_index_table = $('#voters-index-table').DataTable({
      "responsive": true,
      "autoWidth": false,
      "processing": true,
      "serverSide": true,
      "ajax": {
        "url": $('#voters-index-table').data('source')
      },
      "pagingType": "full_numbers",
      "columns": [
        {"data": "check_box", orderable: false, className: "checkbox_td" },
        {"data": "no" },
        {"data": "identification_number"},
        {"data": "number_of_shareholder_shares"},
        {"data": "list_shareholder_account"},
        {"data": "number_of_shares"},
        {"data": "status"},
        {"data": "email_group"},
        {"data": "attending"},
        {"data": "voting_form"},
        {"data": "status_remarks"},
        {"data": "qr_code_for_login", visible: document.getElementById("voters-index-table").querySelector('th.js-qr-for-login').style.display != 'none' },
        {"data": "qr_code_as_ticket", visible: document.getElementById("voters-index-table").querySelector('th.js-qr-as-ticket').style.display != 'none' },
        {"data": "full_name", visible: false},
        {"data": "shareholder_account_name", visible: false},
      ],
      "columnDefs": [ {
        "searchable": false,
        "orderable": false,
        "targets": 1
      } ],
      order: [[ 2, 'asc' ]],
    });

    // after table draw do ...
    voters_index_table.on('draw.dt', function () {
      // table index
      var info = voters_index_table.page.info();
      voters_index_table.column(1, { search: 'applied', order: 'applied', page: 'applied' }).nodes().each(function (cell, i) {
        cell.children[0].innerHTML = i + 1 + info.start;
      });

      // init VotersIndex
      if(totalSharesInput &&
        votersTotalSharesSelector &&
        totalSharesholdersInput &&
        votersTotalSelector) {
        VotersIndex.init()
      }

    })
  }

})