import axios from 'axios'

var csrfToken;
if (document.querySelector("meta[name=csrf-token]")) {
  csrfToken = document.querySelector("meta[name=csrf-token]").content
}
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

var PageReviewForVerification;
if (typeof PageReviewForVerification === 'undefined') PageReviewForVerification = {};

const tableReviewForVerification = document.querySelector('#js-review-for-verification-table');

PageReviewForVerification = {
  init: () => {
    if (!!document.querySelector('#js-batch-verify-voters-and-registrations-from-review')) {
      // reset all checkboxes to unchecked
      $('input[name="select_all_matching_records"]').prop("checked", false);
      $('input[name="select_matching_voter"]').each(function () {
        this.checked = false;
      });
      $('input[name="select_matching_registration"]').each(function () {
        this.checked = false;
      });
      // add listeners to checkboxes
      PageReviewForVerification.addCheckBoxListeners();
      // setup batch-verify button
      PageReviewForVerification.batchVerifyButtonListener();
      PageReviewForVerification.updateBatchVerifyButtonState();
    } else {
      PageReviewForVerification.setupListenersOnManualButtons();
      PageReviewForVerification.voterNumberOfSharesListeners();
    }
  },

  setupListenersOnManualButtons: () => {
    $(".voter_verify_btn").on('click', function(e) {
        let event_id = $(this).data('event-id');
        let voter_id = $(this).val();
        let voter_details = this.attributes["name"].value;
        let remarks_id = 'voter_new_remarks-' + voter_id.toString();
        let new_remarks = document.getElementById(remarks_id).value;
        if (confirm("Are you sure you want to verify this Voter: " + voter_details + " ?")) {
          $('#loader-modal').modal('show')
          let voters = [];
          voters.push(voter_id);
          $.ajax({
            url: '/events/' + event_id + '/voters/batch_verify',
            type: "POST",
            data: { voters: voters, new_remarks: new_remarks },
            success: function (msg) {}
          });
        }
    })

    $(".voter_reject_btn").on('click', function(e) {
        let event_id = $(this).data('event-id');
        let voter_id = $(this).val();
        let voter_details = this.attributes["name"].value;
        let remarks_id = 'voter_new_remarks-' + voter_id.toString();
        let new_remarks = document.getElementById(remarks_id).value;
        if (confirm("Are you sure you want to reject this Voter: " + voter_details + " ?")) {
          $('#loader-modal').modal('show')
          let voters = [];
          voters.push(voter_id);
          $.ajax({
            url: '/events/' + event_id + '/voters/batch_reject',
            type: "POST",
            data: { voters: voters, new_remarks: new_remarks },
            success: function (msg) {}
          });
        }
    })

    $(".registration_approve_btn").on('click', function(e) {
        let event_id = $(this).data('event-id');
        let registration_id = $(this).val();
        let registration_details = this.attributes["name"].value;
        if (confirm("Are you sure you want to approve this Registration: " + registration_details + " ?")) {
          $('#loader-modal').modal('show')
          let registrants = [];
          registrants.push(registration_id);
          $.ajax({
            url: '/events/' + event_id + '/shareholder_accounts/batch_verify_matching_records',
            type: "POST",
            data: { selected_registration_ids: registrants },
            success: function (msg) {}
          });
        }
    })

    $(".registration_reject_btn").on('click', function(e) {
        let event_id = $(this).data('event-id');
        let registration_id = $(this).val();
        let registration_details = this.attributes["name"].value;
        if (confirm("Are you sure you want to reject this Registration: " + registration_details + " ?")) {
          $('#loader-modal').modal('show')
          let registrants = [];
          registrants.push(registration_id);
          $.ajax({
            url: '/events/' + event_id + '/registrations/reject_registrations',
            type: "POST",
            data: { registrants: registrants },
            success: function (msg) {}
          });
        }
    })
  },

  addCheckBoxListeners: () => {
    $(".matching_record_checkbox").on('click', function(e) {
      let checkboxesCount = $('input[name="select_matching_voter"]').length + $('input[name="select_matching_registration"]').length;
      if (e.currentTarget.name == "select_all_matching_records") {
        if (e.currentTarget.checked) {
          $('input[name="select_matching_voter"]').each(function () {
            this.checked = true;
          });
          $('input[name="select_matching_registration"]').each(function () {
            this.checked = true;
          });
        } else {
          $('input[name="select_matching_voter"]').each(function () {
            this.checked = false;
          });
          $('input[name="select_matching_registration"]').each(function () {
            this.checked = false;
          });
        }
      } else if ((e.currentTarget.name == "select_matching_voter") || (e.currentTarget.name == "select_matching_registration")) {
        let checkedVotersCount = $('input[name="select_matching_voter"]:checked').length;
        let checkedRegistrationsCount = $('input[name="select_matching_registration"]:checked').length;
        if ((checkedVotersCount + checkedRegistrationsCount) == checkboxesCount) {
          $('input[name="select_all_matching_records"]').prop("checked", true);
        } else {
          $('input[name="select_all_matching_records"]').prop("checked", false);
        }
      }
      PageReviewForVerification.updateBatchVerifyButtonState();
    })
  },

  updateBatchVerifyButtonState: () => {
    let checkedVotersCount = $('input[name="select_matching_voter"]:checked').length;
    let checkedRegistrationsCount = $('input[name="select_matching_registration"]:checked').length;
    if ((checkedVotersCount + checkedRegistrationsCount) > 0) {
      $("#js-batch-verify-voters-and-registrations-from-review").removeClass("disabled");
    } else {
      $("#js-batch-verify-voters-and-registrations-from-review").addClass("disabled");
    }
  },

  batchVerifyButtonListener: () => {
    document.querySelector('#js-batch-verify-voters-and-registrations-from-review').addEventListener('click', function(e) {
      var event_id = $(this).data('event-id');
      var selected_voter_ids = PageReviewForVerification.getMatchingVotersChecked();
      var selected_registration_ids = PageReviewForVerification.getMatchingRegistrationsChecked();
      var selected_count = selected_voter_ids.length + selected_registration_ids.length;
      if (selected_count > 0) {
        if (confirm("Are you sure you want to verify for the selected " + selected_count + " voter(s)/registration(s)?")) {
          $('#loader-modal').modal('show')
          $.ajax({
            url: '/events/' + event_id + '/shareholder_accounts/batch_verify_matching_records',
            type: "POST",
            data: { selected_voter_ids: selected_voter_ids, selected_registration_ids: selected_registration_ids },
            success: function (msg) {}
          });
        }
      }
    })
  },

  getMatchingVotersChecked: () => {
    var selected_voter_ids = [];
    $('[name="select_matching_voter"]').each(function () {
      if ($(this).is(":checked")) {
        selected_voter_ids.push($(this).val());
      }
    });
    return selected_voter_ids;
  },

  getMatchingRegistrationsChecked: () => {
    var selected_registration_ids = [];
    $('[name="select_matching_registration"]').each(function () {
      if ($(this).is(":checked")) {
        selected_registration_ids.push($(this).val());
      }
    });
    return selected_registration_ids;
  },

  voterNumberOfSharesListeners: () => {
    document.querySelectorAll('.js-edit-voter-number-of-shares a').forEach((element) => {
      element.addEventListener('click', function(e) {
        e.preventDefault()
        PageReviewForVerification.UpdateVoterNumberOfShares(e.currentTarget)
      })
    })

    document.querySelectorAll('.js-show-voter-number-of-shares a').forEach((element) => {
      element.addEventListener('click', function(e) {
        e.preventDefault()
        PageReviewForVerification.toggleVoterNumberOfSharesUI(e.currentTarget, 'edit')
      })
    })
  },

  toggleVoterNumberOfSharesUI: (element, state) => {
    if (state == 'edit') {
      element.closest('.td').querySelector('.js-show-voter-number-of-shares').style.display = 'none'
      element.closest('.td').querySelector('.js-edit-voter-number-of-shares').style.display = 'flex'
      element.closest('.td').querySelector('.js-loader-voter-number-of-shares').style.display = 'none'
      element.closest('.td').querySelector('.js-edit-voter-number-of-shares .js-voter-number-of-shares').readOnly = false
    } else if (state == 'show') {
      element.closest('.td').querySelector('.js-show-voter-number-of-shares').style.display = 'block'
      element.closest('.td').querySelector('.js-edit-voter-number-of-shares').style.display = 'none'
      element.closest('.td').querySelector('.js-loader-voter-number-of-shares').style.display = 'none'
      element.closest('.td').querySelector('.js-edit-voter-number-of-shares .js-voter-number-of-shares').readOnly = true
    } else {
      element.closest('.td').querySelector('.js-show-voter-number-of-shares').style.display = 'none'
      element.closest('.td').querySelector('.js-edit-voter-number-of-shares').style.display = 'none'
      element.closest('.td').querySelector('.js-loader-voter-number-of-shares').style.display = 'block'
    }
  },

  UpdateVoterNumberOfShares: (element) => {
    if (!confirm("Are you sure you want to update voter's number of shares?")) {
      return
    }

    PageReviewForVerification.toggleVoterNumberOfSharesUI(element, 'load')
    const tableColumn = element.closest('.td')
    const editContainer = tableColumn.querySelector('.js-edit-voter-number-of-shares')
    const showContainer = tableColumn.querySelector('.js-show-voter-number-of-shares')
    const voterShares = editContainer.querySelector('input.js-voter-number-of-shares').value
    const voterID = editContainer.dataset.voterId
    const eventID = document.querySelector('#js-review-voters-and-registrations').getAttribute("data-event-id")
    const dataObj = {
      method: 'POST',
      url: '/events/' + eventID + '/voters/set_shareholders_and_shares_count',
      data: {
        voter_id: voterID,
        voter_number_of_shares: voterShares
      }
    }
    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data && !response.data.errors) {
          editContainer.querySelector('input.js-voter-number-of-shares').value = response.data.voter_number_of_shares
          showContainer.querySelector('.js-voter-number-of-shares-label').innerHTML = response.data.voter_number_of_shares.toLocaleString()
          const matchingCheckContainer = showContainer.querySelector('.js-matching-status-voter-number-of-shares')
          if (parseInt(showContainer.dataset.shareholderShares) == parseInt(response.data.voter_number_of_shares)) {
            if(matchingCheckContainer){
              matchingCheckContainer.querySelector('.fa-check-circle').style.display = 'block'
              matchingCheckContainer.querySelector('.fa-times-circle').style.display = 'none'
            }
            tableColumn.parentNode.classList.add('table-success')
            tableColumn.parentNode.classList.remove('table-danger')

          } else {
            if(matchingCheckContainer){
              matchingCheckContainer.querySelector('.fa-check-circle').style.display = 'none'
              matchingCheckContainer.querySelector('.fa-times-circle').style.display = 'block'
            }
            tableColumn.parentNode.classList.add('table-danger')
            tableColumn.parentNode.classList.remove('table-success')
          }

          let msg = "Voter number of shares updated to " + response.data.voter_number_of_shares.toLocaleString()
          if (response.data.message && response.data.message.length > 0) msg += ". " + response.data.message
          alert(msg)
          if (response.data.message && response.data.message.length > 0) location.reload()
        } else if(response.data && response.data.errors) {
          alert(response.data.errors)
        } else {
          alert("Sorry we encountered an error while saving your changes please try again later.")
        }
        PageReviewForVerification.toggleVoterNumberOfSharesUI(element, 'show')
      })
      .catch(error => console.error('catch',error))

  },
}

document.addEventListener("turbolinks:load", () => {
  if(tableReviewForVerification) {
    PageReviewForVerification.init();
  }
})
