// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("company_custom/custom")
require("signature_pad")

import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")
require("formdata-polyfill")
require('datatables.net-bs4')
require('datatables.net-responsive-bs4')
require('datatables.net-fixedcolumns-bs4')
require('datatables.net-select-bs4')
require("packs/featherlight")
require("packs/mobile_region_code")
require("packs/forms")
require("packs/notice")
require("packs/sign_in_method")
require("packs/select")
require("packs/registrations")
require("packs/registration_form_layout")
require("packs/pigeonhole")
require("packs/webcast_QA_01")
require("packs/downloads")
require("packs/dashboard")
require("packs/registration_validate_email_and_mobile")
require("packs/summary_report")
require("packs/quiz")
require("packs/voting_form")
require("packs/voters_index")
require("packs/page_voters_voting_form_tracking")
require("packs/page_voters_live_voting_forms")
require("packs/page_voting_topics_dashboard")
require("packs/page_events_webcast")
require("packs/page_review_for_verification")
require("packs/page_shareholder_accounts_form")
require("packs/page_shareholder_accounts_index")
require("packs/page_voters_show")
require("packs/page_voting_topics_index")
require("packs/adminlte")
require("packs/page_events_form")
require("packs/page_registrations_form")
require("packs/page_webcast_result")
require("packs/page_registrations_scan_qr_code_ticket")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import 'intl-tel-input/build/css/intlTelInput.css'
import '@fortawesome/fontawesome-free/js/all'
import "@fontsource/roboto-condensed/"
import "@fontsource/roboto-condensed/700.css"
import "@fontsource/roboto-condensed/300.css"
import 'select2'
import 'select2/dist/css/select2.css'
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/700.css"
import '../stylesheets/application'

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()

  if ($("iframe")) {
    $("iframe").on('load', function() {
      if ($('iframe').attr('src') && $('iframe').attr('src').includes("/rails/mailers")) {
        $(this).height( $(this).contents().find("body").find('iframe').contents().find("body").height() + 150 );
      }
    });
  }
})