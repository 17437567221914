document.addEventListener("turbolinks:load", () => {
	//hide "Please select" or first <option> in all select
	$("select").not( ".include-blank" ).each(function(){
		if($(this).is(":visible")){
			$(this).children(":first-child").attr({
				"hidden": "hidden",
				"class": "d-none",
				"disabled": "disabled"
			});
		}
	});

	$(".select_filter").select2();
})

