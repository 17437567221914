document.addEventListener("turbolinks:load", () => {
  if($("body.webcast").length > 0 ){
    $('#nav-icon2, .overlays').click(function(){
      if ($('#nav-icon2').hasClass('open')) {
        $('#nav-icon2').removeClass('open');
        $('nav').removeClass("open");
        $('body').removeClass("menuOpen").css({'overflow' : 'auto'}); 
        $('.overlays').hide();
      } else {
        $('#nav-icon2').addClass('open');
        $('nav').addClass("open");
        $('body').addClass("menuOpen").css({'overflow' : 'hidden'});  
        $('.overlays').show();
      }
    });
  } 
})


