import axios from 'axios'

var VotingQuestionsExclusion;
if (typeof VotingQuestionsExclusion === 'undefined') VotingQuestionsExclusion = {};

const votingQuestionExcludedVotersModal = $('#votingQuestionExcludedVotersModal')

VotingQuestionsExclusion = {
  votingQuestionID: 0,
  init: () => {
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalLoader = votingQuestionExcludedVotersModal.find('.loader-container')
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalTable = votingQuestionExcludedVotersModal.find('#js-voting-questions-excluded-voters-container')
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalForm = votingQuestionExcludedVotersModal.find('#js-vts-add-voters-to-exclusion-container')
    VotingQuestionsExclusion.setupVotingQuestionExcludedVotersModal()
  },

  setupVotingQuestionExcludedVotersModal: () => {
    votingQuestionExcludedVotersModal.on('hide.bs.modal', function (e) {
      VotingQuestionsExclusion.resetVotingQuestionExcludedVotersModal()
    })

    document.querySelectorAll('.js-vqs-excluded-voters-button').forEach((showModalButton) => {
      showModalButton.addEventListener('click', function(e) {
        VotingQuestionsExclusion.showVotingQuestionExcludedVotersModal(e)
      })
    })

    document.querySelector('#js-vts-add-voters-to-exclusion-btn').addEventListener('click', function(e) {
      VotingQuestionsExclusion.showVotingQuestionExcludedVotersModalForm()
    })

    document.querySelector('#js-voting-questions-voters-cancel').addEventListener('click', function(e) {
      VotingQuestionsExclusion.resetVotingQuestionExcludedVotersModalForm()
    })

    document.querySelector('#js-voting-questions-voters-submit').addEventListener('click', function(e) {
      VotingQuestionsExclusion.submitVotingQuestionExclusion()
    })
  },

  resetVotingQuestionExcludedVotersModal: () => {
    VotingQuestionsExclusion.resetVotingQuestionExcludedVotersModalForm()
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalLoader.show()
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalTable.hide()
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalForm.hide()
  },

  showVotingQuestionExcludedVotersModal: (e) => {
    let button = e.currentTarget

    if (button) {
      VotingQuestionsExclusion.votingQuestionID = button.id.replace(/js-vqs-excluded-voters-button-/g, '')

      if (VotingQuestionsExclusion.votingQuestionID) {
        document.querySelector('#votingQuestionExcludedVotersModalLabel').innerHTML = 'Excluded voters for: ' + button.dataset.resolution
        VotingQuestionsExclusion.requestVotingQuestionExcluded(VotingQuestionsExclusion.votingQuestionID)
        votingQuestionExcludedVotersModal.modal('show')
      }
    }
  },

  requestVotingQuestionExcluded: (votingQuestionID) => {
    let dataObj = {
      method: 'GET',
      url: '/voting_questions/' + votingQuestionID + '/excluded_voters'
    }

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data) {
          VotingQuestionsExclusion.showVotingQuestionExcludedTableModal(response.data.excluded_voters)
          VotingQuestionsExclusion.votingQuestionExcludedVotersModalLoader.hide()
          VotingQuestionsExclusion.votingQuestionExcludedVotersModalTable.show()
        }else{
          votingQuestionExcludedVotersModal.modal('hide')
          alert('Sorry something went wrong, please try again later.')
        }
      })
      .catch(error => console.log('catch',error))
  },

  requestVotingQuestionIncluded: (votingQuestionID) => {
    let dataObj = {
      method: 'GET',
      url: '/voting_questions/' + votingQuestionID + '/included_voters'
    }

    VotingQuestionsExclusion.votingQuestionExcludedVotersModalTable.hide()
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalLoader.show()

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data) {
          VotingQuestionsExclusion.showVotersSelect(response.data.voters)
          VotingQuestionsExclusion.votingQuestionExcludedVotersModalLoader.hide()
          VotingQuestionsExclusion.votingQuestionExcludedVotersModalForm.show()
        }else{
          votingQuestionExcludedVotersModal.modal('hide')
          alert('Sorry something went wrong, please try again later.')
        }
      })
      .catch(error => console.log('catch',error))
  },

  showVotingQuestionExcludedTableModal: (excluded_voters) => {
    if (!excluded_voters) {
      return
    }

    let counter = 0
    let tableBody = ''
    excluded_voters.forEach((excluded_voter) => {
      counter += 1
      tableBody += '<tr>'
      tableBody += '<td>' + counter + '</td>'
      tableBody += '<td>' + excluded_voter.identification_number + '</td>'
      tableBody += '<td>' + excluded_voter.full_name + '</td>'
      tableBody += '<td class="text-right">' + excluded_voter.number_of_shares + '</td>'
      tableBody += '<td>' + excluded_voter.reason + '</td>'
      tableBody += '<td><button type="button" data-voter-name="'+ excluded_voter.full_name +'" id="js-vts-exclude-voter-from-question-' + excluded_voter.id + '" class="btn btn-danger btn-sm js-vts-exclude-voter-from-question">&times;</button></td>'
      tableBody += '</tr>'
    })

    document.querySelector('table.js-voting-questions-excluded-voters-table').querySelector('tbody').innerHTML = tableBody

    document.querySelectorAll('.js-vts-exclude-voter-from-question').forEach((showModalButton) => {
      showModalButton.addEventListener('click', function(e) {
        VotingQuestionsExclusion.submitVotingQuestionInclusion(e)
      })
    })
  },

  showVotersSelect: (voters) => {
    if (!voters) {
      return
    }

    $('#js-voting-questions-voters-select').empty().trigger('change');
    $('#js-voting-questions-voters-select').select2({
      data: voters,
      placeholder: "Select a voter",
      multiple: true,
      allowClear: true
    })
  },

  showVotingQuestionExcludedVotersModalForm: () => {
    if (VotingQuestionsExclusion.votingQuestionID) {
      VotingQuestionsExclusion.requestVotingQuestionIncluded(VotingQuestionsExclusion.votingQuestionID)
    }
  },

  resetVotingQuestionExcludedVotersModalForm: () => {
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalLoader.hide()
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalTable.show()
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalForm.hide()
    document.querySelector('#js-voting-questions-voters-textarea').value = ''
    $('#js-voting-questions-voters-select').val(null).trigger('change')

    let exclude_voters_dom = $('#js-voting-questions-voters-select')
    let exclude_voters_reason_dom = $('#js-voting-questions-voters-textarea')
    exclude_voters_dom.parent('.form-group').find('.error-message').hide()
    exclude_voters_dom.parent('.form-group').find('label').removeClass('text-danger')
    exclude_voters_dom.parent('.form-group').find('.select2-selection').removeClass('border-danger')
    exclude_voters_reason_dom.parent('.form-group').find('.error-message').hide()
    exclude_voters_reason_dom.parent('.form-group').find('label').removeClass('text-danger')
    exclude_voters_reason_dom.parent('.form-group').find('textarea').removeClass('border-danger')
  },

  submitVotingQuestionExclusion: () => {
    let exclude_voters_dom = $('#js-voting-questions-voters-select')
    let exclude_voters_reason_dom = $('#js-voting-questions-voters-textarea')
    let exclude_voters = exclude_voters_dom.val()
    let exclude_voters_reason = exclude_voters_reason_dom.val()

    // validate
    if (exclude_voters.length == 0) {
      exclude_voters_dom.parent('.form-group').find('.error-message').show()
      exclude_voters_dom.parent('.form-group').find('label').addClass('text-danger')
      exclude_voters_dom.parent('.form-group').find('.select2-selection').addClass('border-danger')
      return
    } else {
      exclude_voters_dom.parent('.form-group').find('.error-message').hide()
      exclude_voters_dom.parent('.form-group').find('label').removeClass('text-danger')
      exclude_voters_dom.parent('.form-group').find('.select2-selection').removeClass('border-danger')
    }
    if (exclude_voters_reason.trim().length == 0) {
      exclude_voters_reason_dom.parent('.form-group').find('.error-message').show()
      exclude_voters_reason_dom.parent('.form-group').find('label').addClass('text-danger')
      exclude_voters_reason_dom.parent('.form-group').find('textarea').addClass('border-danger')
      return
    } else {
      exclude_voters_reason_dom.parent('.form-group').find('.error-message').hide()
      exclude_voters_reason_dom.parent('.form-group').find('label').removeClass('text-danger')
      exclude_voters_reason_dom.parent('.form-group').find('textarea').removeClass('border-danger')
    }

    // confirm
    let text = "Are you sure you want to save the changes?";
    if (confirm(text) != true) {
      return
    }

    VotingQuestionsExclusion.votingQuestionExcludedVotersModalForm.hide()
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalLoader.show()

    let dataObj = {
      method: 'POST',
      url: '/voting_questions/' + VotingQuestionsExclusion.votingQuestionID + '/exclude_voters',
      data: {
        exclude_voters: exclude_voters,
        exclude_voters_reason: exclude_voters_reason
      }
    }

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data) {
          VotingQuestionsExclusion.showVotingQuestionExcludedTableModal(response.data.excluded_voters)
          VotingQuestionsExclusion.resetVotingQuestionExcludedVotersModalForm()
        }else{
          votingQuestionExcludedVotersModal.modal('hide')
          alert('Sorry something went wrong, please try again later.')
        }
      })
      .catch(error => console.log('catch',error))
  },

  submitVotingQuestionInclusion: (e) => {
    let text = "Are you sure you want to exclude "+ e.currentTarget.dataset.voterName +"?";
    if (confirm(text) != true) {
      return
    }

    if(!e.currentTarget) {
      return
    }

    let voterID = e.currentTarget.id.replace(/js-vts-exclude-voter-from-question-/g, '')
    let dataObj = {
      method: 'POST',
      url: '/voting_questions/' + VotingQuestionsExclusion.votingQuestionID + '/include_voters',
      data: {
        voter_id: voterID
      }
    }

    VotingQuestionsExclusion.votingQuestionExcludedVotersModalLoader.show()
    VotingQuestionsExclusion.votingQuestionExcludedVotersModalTable.hide()

    axios(dataObj)
      .then(response => {
        if(response.status == 200 && response.data) {
          VotingQuestionsExclusion.votingQuestionExcludedVotersModalLoader.hide()
          VotingQuestionsExclusion.votingQuestionExcludedVotersModalTable.show()
          VotingQuestionsExclusion.showVotingQuestionExcludedTableModal(response.data.excluded_voters)
        }else{
          votingQuestionExcludedVotersModal.modal('hide')
          alert('Sorry something went wrong, please try again later.')
        }
      })
      .catch(error => console.log('catch',error))
  },

}

document.addEventListener("turbolinks:load", () => {
  if(votingQuestionExcludedVotersModal && votingQuestionExcludedVotersModal.length > 0) {
    VotingQuestionsExclusion.init()
  }
})
