var WebcastPlayer;
if (typeof WebcastPlayer === 'undefined') WebcastPlayer = {};

const playerID = 'si-player'

WebcastPlayer = {
  eventAttributes: null,
  player: null,
  playbackURL: '',
  eventID: '',
  init: (metadataCallback) => {
    WebcastPlayer.eventAttributes = document.querySelector('#event-attr')
    WebcastPlayer.playbackURL = WebcastPlayer.eventAttributes.getAttribute("data-link")

    if (!WebcastPlayer.playbackURL || WebcastPlayer.playbackURL.length == 0) {
      return
    }

    WebcastPlayer.eventID = WebcastPlayer.eventAttributes.getAttribute('data-id')
    WebcastPlayer.currentTimeContainer = document.querySelector('#js-ew-current-datetime')

    WebcastPlayer.metadataCallback = metadataCallback

    WebcastPlayer.play()
  },

  play: () => {
    registerIVSTech(videojs)
    registerIVSQualityPlugin(videojs)
    WebcastPlayer.player = videojs(playerID, {
      techOrder: ["AmazonIVS"],
      controlBar: {
        playToggle: {
          replay: false
        },
        pictureInPictureToggle: false
      }
    })
    WebcastPlayer.player.ready(WebcastPlayer.playStream);
  },

  playStream: () => {
    if (!WebcastPlayer.playbackURL) {
      return
    }

    $.ajax({
      url: WebcastPlayer.playbackURL,
      type: 'GET',
      timeout: 9000,
      success: function () {
        WebcastPlayer.setupPlayer()
      },
      error: function () {
        setTimeout(WebcastPlayer.playStream(), 10000);
      },
      fail: function () {
        setTimeout(WebcastPlayer.playStream(), 10000);
      },
    })
  },

  setupPlayer: () => {
    // This executes after video.js is initialized and ready
    window.videoJSPlayer = WebcastPlayer.player

    // Get reference to Amazon IVS player
    const ivsPlayer = WebcastPlayer.player.getIVSPlayer()

    // Logs low latency setting and latency value 5s after playback starts
    const PlayerState = WebcastPlayer.player.getIVSEvents().PlayerState
    ivsPlayer.addEventListener(PlayerState.PLAYING, () => {
      // console.log("Player State - PLAYING")
      // setTimeout(() => {
      //   console.log(
      //     `This stream is ${
      //       ivsPlayer.isLiveLowLatency() ? "" : "not "
      //     }playing in ultra low latency mode`
      //   )
      //   console.log(`Stream Latency: ${ivsPlayer.getLiveLatency()}s`)
      // }, 5000)
    })

    // Log errors
    const PlayerEventType = WebcastPlayer.player.getIVSEvents().PlayerEventType
    ivsPlayer.addEventListener(PlayerEventType.ERROR, (type, source) => {
      console.warn("Player Event - ERROR: ", type, source)
      // retry to load
      WebcastPlayer.playStream()
    })

    // Log and display timed metadata
    WebcastPlayer.monitorPlayerMetadata(ivsPlayer, PlayerEventType)

    // Enables manual quality selection plugin
    WebcastPlayer.player.enableIVSQualityPlugin()

    // Set volume and play default stream
    WebcastPlayer.player.volume(0.5)
    WebcastPlayer.player.src(WebcastPlayer.playbackURL)
  },

  monitorPlayerMetadata: (player, PlayerEventType) => {
    player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, function (cue) {
      const metadataText = cue.text
      const position = player.getPosition().toFixed(2)
      console.log(
        `Player Event - TEXT_METADATA_CUE: "${metadataText}". Observed ${position}s after playback started.`
      )

      if (metadataText && metadataText.length > 0) {
        let metadata = JSON.parse(metadataText)
        // console.log(metadata)

        if (metadata.event_id != WebcastPlayer.eventID){
          return
        }

        WebcastPlayer.metadataCallback(metadata)
      }
    });
  },
}

export default WebcastPlayer;