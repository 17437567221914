var PageShareholderAccountsIndex;
if (typeof PageShareholderAccountsIndex === 'undefined') PageShareholderAccountsIndex = {};

const shareholderAccountsTable = document.querySelector('#js-shareholder-accounts-table')

PageShareholderAccountsIndex = {
  init: () => {
    PageShareholderAccountsIndex.batchCreateRegistrationListener()

    // untick all checkboxes
    $('input[name="select_all"]').prop("checked", false);
    $('input[name="shareholder_account_id"]').each(function () {
      if(!this.disabled) this.checked = false;
    });

    $( "#js-shareholder-accounts-table" ).on( "click", "thead input", function( e ) {
      PageShareholderAccountsIndex.checkBoxListener(e.currentTarget)
    });

    $( "#js-shareholder-accounts-table tr" ).each(function() {
      $( this ).on( "click", "input", function( e ) {
        PageShareholderAccountsIndex.checkBoxListener(e.currentTarget)
      });
    });
  },

  checkBoxListener: (target) => {
    let checkboxCount = document.querySelectorAll('input[name="shareholder_account_id"]:not([disabled])').length;

    if (target.name == "select_all") {
      if (target.checked) {
        $('input[name="shareholder_account_id"]').each(function () {
          if(!this.disabled) this.checked = true;
        });
      } else {
        $('input[name="shareholder_account_id"]').each(function () {
          if(!this.disabled) this.checked = false;
        });
      }
    } else if (target.name == "shareholder_account_id") {
      let checkedCount = $('input[name="shareholder_account_id"]:checked').length;
      if (checkedCount == checkboxCount) {
        $('input[name="select_all"]').prop("checked", true);
      } else {
        $('input[name="select_all"]').prop("checked", false);
      }
    }

    PageShareholderAccountsIndex.batchBtnState()
  },

  batchBtnState: () => {
    var $countChecked = $('input[name="shareholder_account_id"]:checked').length;
    if ($countChecked > 0) {
      $("#js-batch-create-registration").removeClass("disabled");
    } else {
      $(".batch_btn").addClass("disabled");
    }
  },

  batchCreateRegistrationListener: () => {
    document.querySelector('#js-batch-create-registration').addEventListener('click', function(e) {
      var event_id = $(this).data('event-id');
      var shareholder_account_ids = PageShareholderAccountsIndex.shareholderAccountsChecked();
      if (shareholder_account_ids.length > 0) {
        if (confirm("Are you sure you want to create registrations for the selected " + shareholder_account_ids.length + " shareholder(s)? ")) {
          $('#loader-modal').modal('show')
          $.ajax({
            url: '/events/' + event_id + '/shareholder_accounts/batch_create_registrations',
            type: "POST",
            data: {shareholder_account_ids},
            success: function (msg) {}
          });
        }
      }
    })
  },

  shareholderAccountsChecked: () => {
    var shareholderAccounts = [];
    $('[name="shareholder_account_id"]').each(function () {
      if ($(this).is(":checked")) {
        shareholderAccounts.push($(this).val());
      }
    });
    return shareholderAccounts;
  }
}

document.addEventListener("turbolinks:load", () => {
  if(shareholderAccountsTable) {
    PageShareholderAccountsIndex.init()
  }
})